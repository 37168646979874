import React, { Component } from 'react';
import $ from "jquery";
import "../../videojsfiletest.css";
import 'video.js/dist/video-js.css';
import { loadAttendeeMessages, loadModeratorMessages, saveMessageToDatabaseforModerator } from '../functions/features/chat/chat-functions';
import SendImg from '../images/send.png';
import logout from '../images/medexIcon/logout.png';
import participants from '../images/participants.png';
import closebtn from '../images/cross-2.png';
import raisehandicon from '../images/lower hand.png';
import CustomNotification from '../helpers/notification/notification';
import { analyticsFunction } from '../functions/helpers/analytics-function';
import Header from '../header/header';
import dbFirebaseApp from '../functions/features/db-firebase-app';
import PortraitModeWarning from '../PortraitModeWarning';
const roomName = 'moderatorWebinar';
export default class ModeratorWebinar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isRaisedHandNotification: false,
            raiseHandMessage: ''
        }

        this.logOut = this.logOut.bind(this);
    }

    onUnload = e => {
        // the method that will be used for both add and remove event
        e.preventDefault();
        e.returnValue = '';
        console.log('on unload called')
    }

    componentDidMount() {
        if( localStorage.getItem('userRole')==='moderator' && localStorage.getItem('uid') !== ''){
            console.warn('welcome')
        } else{
            window.location.href = '/'
        }
        loadAttendeeMessages('webinarMEDEX');
        loadModeratorMessages('moderatorRoomMEDEX');

        $(function () {
            var arrow = $('.chat-head img');

            arrow.on('click', function () {
                var src = arrow.attr('src');

                $('.chat-body').slideToggle('fast');
                if (src === 'https://maxcdn.icons8.com/windows10/PNG/16/Arrows/angle_down-16.png') {
                    arrow.attr('src', 'https://maxcdn.icons8.com/windows10/PNG/16/Arrows/angle_up-16.png');
                }
                else {
                    arrow.attr('src', 'https://maxcdn.icons8.com/windows10/PNG/16/Arrows/angle_down-16.png');
                }
            });
        });
        analyticsFunction(roomName)
    }

    getUserDetails() {
        const db = dbFirebaseApp.firestore();
        const userCollection = db.collection('MetaILDLiveUser');
        let self = this;
        userCollection.where('sceneName', '==', 'webinarHall').orderBy('isRaisedHand', 'desc')
            .onSnapshot(function (querySnapshot) {
                querySnapshot.docChanges().forEach(function (change) {
                    console.warn('change data is ::', change.doc.data())
                    if (change.type === "added" && change.type !== "modified") {
                        console.log("New city: ", change.doc.data());
                        let myData = change.doc.data();
                        myData.id = change.doc.id;
                        let userName = change.doc.data().name;
                        let raiseHandStatus = change.doc.data().isRaisedHand;
                        console.warn('username is ', userName)
                        let divElement = document.createElement('div')
                        let imgElement = document.createElement('img')
                        let childDiv = document.createElement('div')
                        divElement.setAttribute('class', 'single-message flexparti')
                        imgElement.setAttribute('src', raisehandicon);
                        imgElement.setAttribute('id', 'raisehandImg' + change.doc.data().uid);
                        divElement.setAttribute('id', 'div' + change.doc.data().uid)
                        childDiv.setAttribute('class', 'name')
                        imgElement.setAttribute('class', 'raisehandwidth')
                        childDiv.innerHTML = userName;
                        divElement.appendChild(childDiv)
                        divElement.appendChild(imgElement);
                        imgElement.style.display = 'none';
                        document.getElementById('mainDiv').appendChild(divElement)
                        if (raiseHandStatus === 'true') {
                            if (document.getElementById('raisehandImg' + change.doc.data().uid)) {
                                document.getElementById('raisehandImg' + change.doc.data().uid).style.display = 'block';
                            }
                            let userMessage = change.doc.data().name + " has raised hand";
                            self.setState({ raiseHandMessage: userMessage, isRaisedHandNotification: true });
                            setTimeout(() => {
                                self.setState({ isRaisedHandNotification: false });
                            }, 5000);
                            console.warn('username is ', userName)
                        }

                    }
                    if (change.type === "modified") {
                        console.log("Modified city: ", change.doc.data());
                        let userName = change.doc.data().name;
                        let raiseHandStatus = change.doc.data().isRaisedHand;
                        if (raiseHandStatus === 'true') {
                            if (document.getElementById('div' + change.doc.data().uid)) {
                                let removingEle = document.getElementById('div' + change.doc.data().uid);
                                removingEle.parentNode.removeChild(removingEle);
                                document.getElementById('mainDiv').insertBefore(removingEle, document.getElementById('mainDiv').firstChild);
                            }
                            if (document.getElementById('raisehandImg' + change.doc.data().uid)) {
                                document.getElementById('raisehandImg' + change.doc.data().uid).style.display = 'block';
                            }
                            let userMessage = change.doc.data().name + " has raised hand";
                            self.setState({ raiseHandMessage: userMessage, isRaisedHandNotification: true });
                            setTimeout(() => {
                                self.setState({ isRaisedHandNotification: false });
                            }, 3000);
                            console.warn('username is ', userName)
                        } else if (raiseHandStatus === 'false') {
                            let userMessage = change.doc.data().name + " has lowered hand";
                            self.setState({ raiseHandMessage: userMessage, isRaisedHandNotification: true });
                            setTimeout(() => {
                                self.setState({ isRaisedHandNotification: false });
                            }, 3000);
                            if (document.getElementById('raisehandImg' + change.doc.data().uid)) {
                                document.getElementById('raisehandImg' + change.doc.data().uid).style.display = 'none';
                            }
                            if (document.getElementById('div' + change.doc.data().uid)) {
                                let removingEle = document.getElementById('div' + change.doc.data().uid);
                                removingEle.parentNode.removeChild(removingEle);
                                document.getElementById('mainDiv').appendChild(removingEle);
                            }
                        } else {

                            if (document.getElementById('raisehandImg' + change.doc.data().uid)) {
                                document.getElementById('raisehandImg' + change.doc.data().uid).style.display = 'none';
                            }
                        }
                    }
                    if (change.type === "removed" && change.type !== "modified") {
                        console.log("Removed city: ", change.doc.data());
                        if (document.getElementById('div' + change.doc.data().uid)) {
                            let removingEle = document.getElementById('div' + change.doc.data().uid);
                            removingEle.parentNode.removeChild(removingEle);
                        }
                    }
                });

            });

    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.onUnload);

    }


    logOut() {
        localStorage.clear()
        window.location.href = "/"
    }


    sendMessage = (e) => {
        e.preventDefault();
        const msg = document.getElementById('message-txt').value;
        if (msg !== '') {
            saveMessageToDatabaseforModerator(msg, 'moderatorRoomMEDEX');
        } else {
            console.warn('Button should be disabled now');
        }
    }
    openchat = () => {
        document.getElementById("moderator").classList.add("bottomtotop");
        document.getElementById("moderator1").classList.add("bottomtotop");
        document.getElementById("message-form").classList.add("bottomtotop");
        document.getElementById("CloseChat").classList.remove("hidebtn");
        document.getElementById("OpenChat").classList.add("hidebtn");

    }
    closechat = () => {
        document.getElementById("moderator").classList.remove("bottomtotop");
        document.getElementById("moderator1").classList.remove("bottomtotop");
        document.getElementById("message-form").classList.remove("bottomtotop");
        document.getElementById("OpenChat").classList.remove("hidebtn");
        document.getElementById("CloseChat").classList.add("hidebtn");

    }
    openparticipant = () => {
        document.getElementById("partcipant").classList.toggle("bottomtotopparticipant");


    }
    render() {
        return (
            <div>
                <PortraitModeWarning />
                <Header headerType="moderator" />

                {this.state.isRaisedHandNotification &&
                    <CustomNotification msg={this.state.raiseHandMessage} />
                }
                <div className="moderatorposition" >
                    <div className="openchatbtnposition" style={{ right: '1rem' }} >
                        <button className="chatroombtn " onClick={this.openchat} id="OpenChat">Open Chat</button>
                        <button className="chatroombtn hidebtn " onClick={this.closechat} id="CloseChat">Close Chat</button>

                    </div>
                    <div className="presentationmoderator" id="moderator" style={{ right: '1rem' }} >
                        <div className="chat-head">
                            <h2>Attendee Questions</h2>
                        </div>
                        <div className="chat-bodymes scrolltype" id="myScrollingChat">
                            <div className="flexform  " id="messages">

                            </div>

                        </div>
                    </div>
                    <div className="presentationmoderator" id="moderator1" style={{ right: '1rem',display:"none" }}>
                        <div className="chat-head">
                            <h2>Speaker</h2>
                        </div>
                        <div className="chat-body scrolltype heigthchatbody" id="myScrollingChat2">
                            <div className="flexform " id="messages2">

                            </div>
                            <form id="message-form" onSubmit={this.sendMessage} className="chat-textmoderator" >
                                <input type="text" className="message-editbox" id="message-txt" placeholder="Message..." autoComplete="off" />
                                <button type="submit" className="sendarrow">
                                    <img className="send-arrow-img" src={SendImg} alt="Send" />
                                </button>
                            </form>
                        </div>
                    </div>

                </div>



                <div className="participantposition" style={{ display: 'none' }} >

                    <div className="presentationmoderator" id="partcipant" style={{ left: '1rem' }} >
                        <div className="chat-headparticipants">
                            <div>
                                <h2>Participants</h2>
                                <img src={closebtn} onClick={this.openparticipant} alt='' />
                            </div>
                        </div>
                        <div className="chat-bodymes scrolltype" id="myScrollingParticipantList" style={{ height: '245px' }}>
                            <div className="flexform  " id="mainDiv" >
                                <div className="single-message flexparti" >
                                    <div className="name">Participant-1</div>
                                    <img src={raisehandicon} className="raisehandwidth" alt=''></img>
                                </div>
                            </div>

                        </div>
                    </div>


                </div>
                <div className="participantsiconpositionmod" onClick={this.openparticipant} style={{display: 'none'}} >
                    <img src={participants} alt="logout" className=" widthicon marginbottomimg" style={{ display: 'flex' }} data-tooltip="tooltip" data-placement="right" title="Participants" />
                    <div className="icontext textshadow">Participants</div>
                </div>
                <div className="logouticonpositionmod" onClick={() => this.logOut()} >
                    <img src={logout} alt="logout" className=" widthicon marginbottomimg" style={{ display: 'flex' }} data-tooltip="tooltip" data-placement="right" title="Logout" />
                    <div className="icontext textshadow">Logout</div>
                </div>
               
            </div >
        )
    }
}
