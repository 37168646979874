import React, { Component } from 'react'
import Footer from '../footer/footer-page'
import '../lobby/lobby-page.css'
import Header from '../header/header';
import '../functions/helpers/c-zoom-in-out'
import { isMobileDevice } from '../functions/helpers/extra-functions';
import { updateLastSceneEndTime } from '../functions/helpers/analytics-function';
import PortraitModeWarning from '../PortraitModeWarning';
const roomName = 'medex-faculty'
const radioList = [
    'https://eu8.fastcast4u.com/proxy/clyedupq/?mp=/1', // suno
    'https://stream.radiojar.com/s1mygd1frxquv', // al alberia
    'https://stream.radiojar.com/rzcfw4cbsxquv', // 93.4 radio soma
    'https://radio.skynewsarabia.com/stream/radio/skynewsarabia', // sky news arabia
    'https://stream.radiojar.com/5wpf9e4erxquv', // al khaleejiya
]
let myCameraFov = false;
export default class MedexFaculty extends Component {


    constructor(props) {
        super(props);
        this.state = {
            selectedbutton: ''
        }
        this.radioPlayerClick = this.radioPlayerClick.bind(this);
        this.goBackToLobby = this.goBackToLobby.bind(this);
    }

    radioPlayerClick(radiosrc) {
        let radioButton = document.getElementById("radiobuttons")
        radioButton.setAttribute('src', radiosrc)
        radioButton.play()
    }

    stopRadio = () => {
        let radioButton = document.getElementById("radiobuttons")
        radioButton.pause()
        radioButton.currentTime = 0;
    }

    goBackToLobby() {
        this.props.goToLobby();
    }


    componentDidMount() {
        localStorage.setItem('Location', 'entertainment-zone')
        window.addEventListener("message", this.projectButtonClick, false);
        setTimeout(() => {

            let iframeEl = document.getElementById('medexFaculty');
            if (iframeEl) {
                const userInfo = {
                    name: localStorage.getItem('name'),
                    mobileno: localStorage.getItem('mobile'),
                    email: localStorage.getItem('email'),
                    expo: 'medex'
                }
                iframeEl.contentWindow.postMessage(userInfo, '*');
            }
        }, 4000);
        updateLastSceneEndTime(roomName);
    }

    projectButtonClick = (e) => {

        console.log('called on entertainment arrow')
        if (e.data.isImageClicked) {
            console.log('called on lobby arrow')
            if (e.data.mslKey === 'expo.virtualpropexpo2020.com/lobby') {
                window.location.href = '/entrance'
            }
        }

        if (e.data.isLinkClicked) {
            console.warn('Entertainment button click', e.data.url);
            if (e.data.url === 'radiostation1') {
                this.radioPlayerClick(radioList[0]);
            } else if (e.data.url === 'radiostation2') {
                this.radioPlayerClick(radioList[1]);
            } else if (e.data.url === 'radiostation3') {
                this.radioPlayerClick(radioList[2]);
            } else if (e.data.url === 'radiostation4') {
                this.radioPlayerClick(radioList[3]);
            } else if (e.data.url === 'radiostation5') {
                this.radioPlayerClick(radioList[4]);
            } else if (e.data.url === 'stopradiostation') {
                this.stopRadio();
            }
        }
    }

    onTouchStart = (event) => {
        if (isMobileDevice() && myCameraFov === false) {
            if (document.querySelector('#entertainmentCamera')) {
                document.querySelector('#entertainmentCamera').setAttribute('fov', '55');
                setTimeout(() => {
                    document.querySelector('#entertainmentCamera').setAttribute('fov', '60');
                }, 100);
                myCameraFov = true;
            }
        }
    }

    render() {
        return (
            <div>
                <PortraitModeWarning />
                {/* <video
                    style={{
                        display: "none",
                        position: "fixed",
                        width: "100vw",
                        height: "100vh",
                        right: "0",
                        bottom: "0",
                        objectFit: "fill",
                        background: "black",
                        zIndex: 1600,
                    }}
                    id="mainVideoEle"
                    src={"https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/medex-2022/medexVideo-c.mp4"}
                    type="video/mp4"
                    className="mainvideoEle"
                    playsInline
                    //   loop
                    //   muted
                    //   autoPlay
                    controlsList="nodownload"
                    onContextMenu={(e) => {
                        e.preventDefault();
                        return false;
                    }}
                /> */}
                <Header headerType="user" />
                
                <iframe id="medexFaculty" className="iframe-position" title="VR background"
                    allowvr="yes" scrolling="no" src="https://angles-expo.firebaseapp.com/dekho/61caf91d203874001227bb47/0/novr/none">
                </iframe>
                <Footer />
            </div>
        )
    }
}
