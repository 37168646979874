import React, { Component } from 'react'
import VideoInDialog from '../display-gallary/video-in-dialog';
import Footer from '../footer/footer-page'
import { analyticsFunction, updateLastSceneEndTime } from '../functions/helpers/analytics-function';
import Header from '../header/header'
import PortraitModeWarning from '../PortraitModeWarning';
const expoName = 'medex2022';
const roomName = 'archiveGallery';
const archiveGalleryIframe = 'https://angles-expo.firebaseapp.com/dekho/65a137947762a20008425660/0/novr/none';
export default class ArchiveGallary extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            videoUrl: '',
            isDialogOpen: false
        })
    }

    componentDidMount() {
        
        if( localStorage.getItem('userRole')==='user' && localStorage.getItem('uid') !== ''){
            console.warn('welcome')
        } else{
            window.location.href = '/'
        }

        localStorage.setItem('Location', 'archive-gallery')
        document
                .getElementById("archiveGallery")
                .addEventListener("ended", this.endVideo, false);

            let videoPlayer = document.getElementById("archiveGallery");
            videoPlayer.style.display = "block";
            videoPlayer.volume = 1.0;
            videoPlayer.play();
        window.addEventListener("message", this.projectButtonClick, false);

        // updateLastSceneEndTime(roomName);
        analyticsFunction(roomName);

        setTimeout(() => {
            let iframeEl = document.getElementById('archiveGalleryProject');
            if (iframeEl) {
                const userInfo = {
                    name: localStorage.getItem('name'),
                    mobileno: localStorage.getItem('mobile'),
                    email: localStorage.getItem('email'),
                    expo: expoName
                }
                iframeEl.contentWindow.postMessage(userInfo, '*');
            }
        }, 4000);

    }


    endVideo = (e) => {
        let videoPlayer = document.getElementById("archiveGallery");
        videoPlayer.pause();
        videoPlayer.style.display = "none";
    };

    componentWillUnmount() {
        window.removeEventListener("message", this.projectButtonClick, false);
    }



    projectButtonClick = (e) => {
        // console.log('called on lobby arrow', e.data);
        if (e.data.isImageClicked) {

            if (e.data.mslKey === 'expo.virtualpropexpo2020.com/lobby') {
                window.location.href = '/entrance'
                // window.location.href ="/archive";


            // } else if (e.data.mslKey === 'expo.virtualpropexpo2020.com/archive1') {
            //     window.location.href = '/archive1'
            // } else if (e.data.mslKey === 'expo.virtualpropexpo2020.com/archive2') {
            //     window.location.href = '/archive2'
            // } else if (e.data.mslKey === 'expo.virtualpropexpo2020.com/archive3') {
            //     window.location.href = '/archive3'
            // } else if (e.data.mslKey === 'expo.virtualpropexpo2020.com/archive4') {
            //     window.location.href = '/archive4'
            // } else if (e.data.mslKey === 'expo.virtualpropexpo2020.com/archive5') {
            //     window.location.href = '/archive5'
            // } else if (e.data.mslKey === 'expo.virtualpropexpo2020.com/archive6') {
            //     window.location.href = '/archive6'
            // } else if (e.data.mslKey === 'expo.virtualpropexpo2020.com/archive7') {
            //     window.location.href = '/archive7'
            // } else if (e.data.mslKey === 'expo.virtualpropexpo2020.com/archive8') {
            //     window.location.href = '/archive8'
            // } else if (e.data.mslKey === 'expo.virtualpropexpo2020.com/archive9') {
            //     window.location.href = '/archive9'
            // } else if (e.data.mslKey === 'expo.virtualpropexpo2020.com/archive10') {
            //     window.location.href = '/archive10'
            // } else if (e.data.mslKey === 'expo.virtualpropexpo2020.com/archive11') {
            //     window.location.href = '/archive11'
            // } else if (e.data.mslKey === 'expo.virtualpropexpo2020.com/archive12') {
            //     window.location.href = '/archive12'
            }
        }
        if (e.data.isLinkClicked) {
            if (e.data.url) {
                if (!e.data.url.includes('youtube.com')) {
                    this.setState({
                        videoUrl: e.data.url,
                        isDialogOpen: true,
                    })

                } else {
                    window.open(e.data.url, '_blank');
                }
            }
        }
    }

    onClose = () => {
        this.setState({
            isDialogOpen: false
        })
    }


    render() {
        return (
            <div>
                <PortraitModeWarning />
                <video
                    style={{
                        display: "none",
                        position: "fixed",
                        width: "100vw",
                        height: "100vh",
                        right: "0",
                        bottom: "0",
                        objectFit: "fill",
                        background: "black",
                        zIndex: 1600,
                    }}
                    id="archiveGallery"
                    src={"https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/medex-2022/ondemandsession.mp4"}
                    type="video/mp4"
                    className="mainvideoEle"
                    playsInline
                    //   loop
                      muted
                      autoPlay
                    controlsList="nodownload"
                    onContextMenu={(e) => {
                        e.preventDefault();
                        return false;
                    }}
                />
                <Header headerType="user" />
                <iframe id="archiveGalleryProject" className="iframe-position" title="VR background"
                    allowvr="yes" scrolling="no" src={archiveGalleryIframe}>
                </iframe>
                {this.state.videoUrl && (
                    <VideoInDialog url={this.state.videoUrl} isOpen={this.state.isDialogOpen} onClose={this.onClose} />
                )}
                <Footer />
            </div>
        )
    }
}
