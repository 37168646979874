
import firebase from 'firebase';
let loginFirebaseApp;
SetupFirebase();

/**
* Firebase Initialization Function
* This must be called before any firebase query
*/
function SetupFirebase() {
    var firebaseConfig = {
        apiKey: "AIzaSyBhDiDYyLJ_xLb3mIs54mu4E08DMD2p9XM",
        authDomain: "vr-expo.firebaseapp.com",
        databaseURL: "https://vr-expo.firebaseio.com",
        projectId: "vr-expo",
        storageBucket: "vr-expo.appspot.com",
        messagingSenderId: "797131676470",
        appId: "1:797131676470:web:66d8dcec7ab3419d8e7197",
        measurementId: "G-6DBKV0FRP0"
      };
    // Initialize Firebase
    loginFirebaseApp = firebase.initializeApp(firebaseConfig);
}

export default loginFirebaseApp;
