import React, { Component } from 'react'
import Footer from '../footer/footer-page'
import Header from '../header/header'
import VideoInDialog from './video-in-dialog';
import './display-gallery-page.css'
import { updateLastSceneEndTime } from '../functions/helpers/analytics-function';
import ChatPanelCountry from '../chatpanel/ChatPanelCountry';
import PortraitModeWarning from '../PortraitModeWarning';
const expoName = 'medex2022';
const roomName = 'country4'
const displayGalleryIframeSrc = 'https://angles-expo.firebaseapp.com/dekho/61cbff0b203874001227c13d/0/novr/none'

export default class DisplayGallaryPage4 extends Component {


    constructor(props) {
        super(props)
        this.state = ({
            videoUrl: '',
            isDialogOpen: false
        })
    }

    componentDidMount() {
        if (localStorage.getItem('userRole') === 'user' && localStorage.getItem('uid') !== '') {
            console.warn('welcome')
        } else {
            window.location.href = '/'
        }
        localStorage.setItem('Location', 'display-gallery')
        window.addEventListener("message", this.projectButtonClick, false);
        setTimeout(() => {
            let iframeEl = document.getElementById('displayGalleryIframe');
            if (iframeEl) {
                const userInfo = {
                    name: localStorage.getItem('name'),
                    mobileno: localStorage.getItem('mobile'),
                    email: localStorage.getItem('email'),
                    expo: expoName
                }
                iframeEl.contentWindow.postMessage(userInfo, '*');
            }
        }, 4000);
        updateLastSceneEndTime(roomName);
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.projectButtonClick, false);
    }

    onClose = () => {
        this.setState({
            isDialogOpen: false
        })
    }

    projectButtonClick = (e) => {
        console.log('display gallery click', e.data);
        if (e.data.isImageClicked) {

            if (e.data.mslKey === 'expo.virtualpropexpo2020.com/lobby') {
                window.location.href = '/entrance'
            } else if (e.data.mslKey === 'expo.virtualpropexpo2020.com/country') {
                window.location.href = '/country'
            }
        }

        if (e.data.isLinkClicked) {
            if (e.data.url.includes('https://wa.me') || e.data.url.includes(
                'https://api.whatsapp.com')) {
                const form = document.createElement("form");
                form.setAttribute('method', "post");
                form.setAttribute('target', '_blank');
                form.setAttribute('action', e.data.url);
                document.body.append(form);
                form.submit()
            } else {
                let myLink = e.data.url
                if (e.data.url) {
                    if (myLink.includes('youtube.com') || myLink.includes('brightcove')) {

                        this.setState({
                            videoUrl: e.data.url,
                            isDialogOpen: true,
                        })

                    } else {
                        const form = document.createElement("form");
                        form.setAttribute('method', "get");
                        form.setAttribute('target', '_blank');
                        form.setAttribute('action', e.data.url);
                        document.body.append(form);
                        form.submit()
                    }

                }
            }
        }


    }

    closePopup = () => {
        document.getElementById('mainVideo').setAttribute('src', '');
        document.getElementById('centerPopup').style.display = 'none';
        document.getElementById('popupOverlay').style.display = 'none';
    }
    render() {

        return (
            <div>
                <PortraitModeWarning />
                <Header headerType="user" />
                <div className="overlay" id="popupOverlay" style={{ display: 'none' }}></div>
                <div className="center-video-popup" id="centerPopup" style={{ display: 'none' }}>
                    <div className="close-popup" onClick={() => this.closePopup()}>x</div>
                    <iframe id="mainVideo" width="560" height="315" src="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <iframe id="displayGalleryIframe" className="iframe-position" title="VR background"
                    allowvr="yes" scrolling="no" src={displayGalleryIframeSrc}>
                </iframe>
                <VideoInDialog url={this.state.videoUrl} isOpen={this.state.isDialogOpen} onClose={this.onClose} />
                <ChatPanelCountry roomName="medexCountry4" />
                <Footer />
            </div>
        )
    }
}
