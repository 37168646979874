import React, { Component } from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import RouterContainer from './pages/router-container/router-container'

const history = createBrowserHistory()
export default class App extends Component {
  render () {
    return (
      <div>
        <Router history={history}>
          <RouterContainer history={history} />
        </Router>
      </div>
    )
  }
}
