import React, { useEffect, useRef, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import { DialogContent, DialogTitle, duration } from "@material-ui/core";
import logoleft from '../images/med.png';
import logoright from '../images/logo.png';
import cross from '../images/medexIcon/cross.png';
import './videodialog.css'
import ReactPlayer from "react-player";
import { addVideoWatchTime } from "../functions/helpers/analytics-function";

function VideoInDialog({ isOpen, onClose, url }) {

  const [watchedTime, setWatchedTime] = useState([]);
  const [videoDuration, setVideoDuration] = useState(0);
  const [overallTime, setOverallTime] = useState(0);
  const [pauseTime, setPauseTime] = useState(0);
  const [pauseClickCounter, setPauseClickCounter] = useState(0);
  const overallDuration = useRef(null);
  const pauseDuration = useRef(null);

  const handleVideoClose = () => {
    // Set variable to intials
    
    let tempModule = url.split("2024-updated/")[1];
    let moduleName = tempModule.split("-c.mp4")[0];
    console.log("moduleName", moduleName);

    /* For creating Range of video watching time */
    let timeRange = [];
    let rangeValue = "";
    let firstIndex = 0;
    watchedTime.map((time, index) => {
      if (index === 0) {
        rangeValue = `${watchedTime[firstIndex]} - ${watchedTime[index]}`
      } else {
        if (parseInt(watchedTime[index - 1]) + 1 === watchedTime[index]) {
          rangeValue = `${watchedTime[firstIndex]} - ${watchedTime[index]}`
        } else {
          firstIndex = index;
          timeRange.push(rangeValue);
        }
      }
      if (watchedTime.length - 1 === index) {
        timeRange.push(rangeValue);
        let otherDetails = {
          overallSpentTime: overallTime,
          overallPauseTime: pauseTime,
          pauseCount: pauseClickCounter,
          actualSpentTime: watchedTime.length,
          watchedFrames: watchedTime,
          timeRange: timeRange
        }
        clearInterval(overallDuration.current);
        clearInterval(pauseDuration.current);
        addVideoWatchTime(moduleName, videoDuration, watchedTime.length, otherDetails);
        
        setVideoDuration(0);
        setWatchedTime([]);
        setOverallTime(0);
        setPauseTime(0);
        setPauseClickCounter(0);
        onClose();
      }
    })
  }

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth="lg" style={{ zIndex: 1230000 }}>
      <div>
      </div>
      <DialogTitle id="customized-dialog-title" style={{ paddingBottom: '5px' }}>
        <img src={cross} className="closevideobtn" onClick={handleVideoClose} alt='' />

        <div className="flex__logo">
          <img style={{ width: '120px' }} src={logoleft} alt='' />
          <img style={{ width: '135px' }} src={logoright} alt='' />
        </div>
      </DialogTitle>
      <DialogContent style={{ background: "#121212", display: "flex", justifyContent: "center" }}>
        <React.Fragment>
          {url.endsWith("mp4") ?
            <ReactPlayer
              id="player"
              width={"70%"}
              height={"auto"}
              url={url}
              muted={false}
              controls={true}
              onDuration={(duration) => {
                console.log("duration", parseInt(duration));
                setVideoDuration(parseInt(duration));
              }}
              onPlay={() => {
                overallDuration.current = setInterval(() => {
                  setOverallTime((prev) => prev + 1);
                }, 1000);

                clearInterval(pauseDuration.current);
              }}
              onProgress={(progress) => {
                // console.log("progress", progress);
                let time = Math.floor(progress.playedSeconds)
                const tempTime = [...watchedTime];
                if (tempTime.indexOf(time) === -1) {
                  tempTime.push(time);
                  setWatchedTime(tempTime);
                }
              }}
              onPause={() => {
                pauseDuration.current = setInterval(() => {
                  setPauseTime((prev) => prev + 1);
                }, 1000);
                // watchedDuration
                console.log("watchedDuration", watchedTime.length);
                // timeSpent
                console.log("timeSpent", watchedTime);

                setPauseClickCounter((prev) => prev + 1);
              }}
            />
            :
            <iframe
              title="video"
              src={url}
              frameBorder="0"
              className="videowidth"
            />}
        </React.Fragment>
      </DialogContent>
    </Dialog>
  );
}

export default VideoInDialog;
