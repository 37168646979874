import React from "react";
import "./loading-page.css";
import anglesimg from "../images/loading.gif";
import Header from "../header/header";
let i = 0;
const LoadingBg = "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/medex-2022/enterEventbtnBg.png"
class LoadingPageBtn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isButtonShow: false,
      location: "",
    };
  }

  componentDidMount() {
    i = 0;
    if (this.props.location !== "Entrance") {

      setTimeout(() => {
        this.moveProgressBar();
      }, 1000);
    }
    this.setState({
      isButtonShow: this.props.btnStatus,
      location: this.props.location ? this.props.location : "",
    });
  }

  moveProgressBar = () => {
    if (i == 0) {
      i = 1;
      var elem = document.getElementById("myBar");
      if (elem) {
        var width = 1;
        var id = setInterval(frame, 15);
        function frame() {
          if (width >= 100) {
            clearInterval(id);
            i = 0;
          } else {
            width++;
            elem.style.width = width + "%";
          }
        }
      }
    }
  };

  clickTOPlay = () => {
    this.props.updateStateForLoader(false);
  };
  goToLwebinar = () => {
    window.location.href="/lwebinarHall"
  };

  render() {
    return (
      <div>
        <div id="loader-wrapper" style={this.state.location === "Entrance" ? {
          backgroundImage: `url(${LoadingBg})`, backgroundPosition: "center",
          backgroundSize: "cover"
        } : {}}>
          <Header />
          <div className="loader-position" style={this.state.location === "Entrance" ? {
            left: "52.5%",
            top: "55%"
          } : {}} >
            {this.state.location !== "Entrance" && (
              <>
                <img src={anglesimg} alt="" />
                <div id="myProgress">
                  <div id="myBar"></div>
                </div>
              </>
            )}
            {this.state.location !== "Entrance" && this.props.btnStatus && (
              <p
                style={{ marginTop: "10px", fontSize: 14, textAlign: "center" }}
                className="loadingtext"
              >
                Click on "Start Webinar" to enter in webinar hall
              </p>
            )}
            {localStorage.getItem("Location") !== "entrance"&& (
              <button onClick={this.clickTOPlay} className="clickbutton">
                Start Webinar
              </button>
            )}
            {this.state.location !== "Entrance" && this.props.btnStatus && this.props.lighterBtn &&  (
              <button onClick={this.goToLwebinar} className="clickbutton light-btn-mobile">
                If you are facing connection issues,<br/> please 'PRESS HERE' as an alternative
              </button>
            )}
            {!this.props.btnStatus && this.props.lighterBtn &&  (
              <button onClick={this.goToLwebinar} className="clickbutton light-btn-mobile">
               If you are facing connection issues,<br/> please 'PRESS HERE' as an alternative
              </button>
            )}
            {this.state.location === "Entrance"  && (
              <button onClick={this.clickTOPlay} className="clickbutton">
                Enter Event
              </button>
            )}
            {this.state.location === "Entrance" && this.props.btnStatus && this.props.lighterBtn && (
              <button onClick={this.goToLwebinar} className="clickbutton light-btn-mobile">
               If you are facing connection issues, <br/> please 'PRESS HERE' as an alternative
               </button>
                       
            )}
          </div>

        <p className="notice notice-mobile" style={this.state.location === "Entrance"?{color:"#fff"}:{color:"#144E82"}} >If you are using a 3G or a lower connection, kindly change to a stable <b>WIFI</b> or <b>Cable</b> connection for better connectivity</p>
        </div>
        
      </div>
    );
  }
}
export default LoadingPageBtn;
