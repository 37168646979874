import React, { Component } from 'react';
import landscapeOnlyWarningImg from "../pages/images/rotate-smartphone.png";
import "../pages/loading/notAuthorized.css"

export default class PortraitModeWarning extends Component {
    render() {
        return (
            <div className="overlayPotraitMode">
                <div id="portraitModeOnly">
                    <img alt="landscape only" src={landscapeOnlyWarningImg} />
                    <p>This website is only viewable in landscape mode</p>
                </div>
            </div>
        )
    }
}
