import React, { Component } from "react";
import Footer from "../footer/footer-page";
import dbFirebaseApp from "../functions/features/db-firebase-app";
import { analyticsFunction } from "../functions/helpers/analytics-function";
import Header from "../header/header";
import PortraitModeWarning from "../PortraitModeWarning";

// const entranceVideo =
  // "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/medex-2022/medexVideo-c.mp4";
const exteriorIframeSrc =
  "https://angles-expo.firebaseapp.com/dekho/61caadb5203874001227b2fb/0/novr/none";
const expoName = "medex2022";
const roomName = "exterior";
const sceneName = "exterior";
export default class Exterior extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isvideoEnded: false,
      isEventEntryVideoEnd: false,
    };
  }

  componentDidMount() {
    if (
      localStorage.getItem("userRole") === "user" &&
      localStorage.getItem("uid") !== ""
    ) {
      console.warn("welcome");
    } else {
      window.location.href = "/";
    }

    localStorage.setItem("Location", "Exterior");
    window.addEventListener("message", this.projectButtonClick, false);
    setTimeout(() => {
      let iframeEl = document.getElementById("exteriorIframe");
      if (iframeEl) {
        const userInfo = {
          name: localStorage.getItem("name"),
          mobileno: localStorage.getItem("mobile"),
          email: localStorage.getItem("email"),
          expo: expoName,
        };
        iframeEl.contentWindow.postMessage(userInfo, "*");
      }
    }, 4000);
    analyticsFunction(roomName);
  }

  componentWillUnmount() {
    try {
      window.speechSynthesis.cancel();
    } catch (e) {
      console.error(e);
    }
  }

  updateCountdown = () => {
    var video = document.getElementById("2dVideo");
    var timeSpan = document.querySelector("#countdown span");
    let hours;
    let totalSeconds = video.duration - video.currentTime;
    let minutes;
    let seconds;
    hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    minutes = Math.floor(totalSeconds / 60);
    seconds = totalSeconds % 60;
    timeSpan.innerText =
      minutes.toString().padStart(2, "0") +
      ":" +
      seconds.toFixed().toString().padStart(2, "0");
    console.warn(
      hours.toString().padStart(2, "0") +
        ":" +
        minutes.toString().padStart(2, "0") +
        ":" +
        seconds.toFixed().toString().padStart(2, "0")
    );
  };

  playVideo = () => {
    if (
      document.getElementById("2dVideo") &&
      document.getElementById("Pause2dVideo") &&
      document.getElementById("Play2dVideo") &&
      document.getElementById("startExperienceButton")
    ) {
      document.getElementById("2dVideo").play();
      document.getElementById("2dVideo").volume = 1;
      document.getElementById("Pause2dVideo").style.display = "block";
      document.getElementById("Play2dVideo").style.display = "none";
      document.getElementById("countdown").style.display = "block";
      setTimeout(() => {
        document.getElementById("startExperienceButton").style.display =
          "block";
      }, 500);
    }
  };

  pauseVideo = () => {
    if (
      document.getElementById("2dVideo") &&
      document.getElementById("Pause2dVideo") &&
      document.getElementById("Play2dVideo")
    ) {
      document.getElementById("2dVideo").pause();

      document.getElementById("2dVideo").volume = 0;
      document.getElementById("Play2dVideo").style.display = "block";
      document.getElementById("Pause2dVideo").style.display = "none";
      document.getElementById("countdown").style.display = "none";
    }
  };

  addNewEntry = () => {
    if (localStorage.getItem("entryAdded") !== "false") {
      let Name;
      if (localStorage.getItem("name")) {
        Name = localStorage.getItem("name");
      } else {
        Name = "";
      }
      let email;
      if (localStorage.getItem("email")) {
        email = localStorage.getItem("email");
      } else {
        email = "";
      }
      let uid;
      if (localStorage.getItem("uid")) {
        uid = localStorage.getItem("uid");
      } else {
        uid = "";
      }
      const db = dbFirebaseApp.firestore();
      db.collection("MetaILDLiveUser")
        .add({
          name: Name,
          sceneName: sceneName,
          uid: uid,
          isRaisedHand: "false",
          email: email,
          startTime: new Date().getTime(),
          expo: "medex",
        })
        .then(function (docRef) {
          console.log("Document written with ID: ", docRef.id);
          console.warn("entry added");
          localStorage.setItem("docRef", docRef.id);
          localStorage.setItem("entryAdded", false);
        })
        .catch(function (error) {
          console.error("Error adding document: ", error);
        });
    }
  };

  projectButtonClick = (e) => {
    console.log("exterior click", e.data);
    if (e.data.isImageClicked) {
      if (e.data.mslKey === "expo.virtualpropexpo2020.com/lobby") {
        window.location.href = "/entrance";
      }
    }
  };

  startExperiance = () => {
      document.getElementById("VideoOverlay").style.display = "none";
      document.getElementById("video-popup").style.display = "none";
  };

  render() {
    return (
      <div>
        <PortraitModeWarning />
        <div>
          <Header headerType="user" />
          <div className="overlayVideo" id="VideoOverlay">
            <div
              id="countdown"
              style={{
                display: "none",
                position: "fixed",
                top: "30px",
                width: "70px",
                textAlign: "center",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: "white",
                padding: "3px 10px",
                border: "2px solid white",
                borderRadius: "5px",
              }}
            >
              {" "}
              <span></span>{" "}
            </div>
          </div>
          <div id="video-popup">
            <button
              id="startExperienceButton"
              style={{ display: "block" }}
              className="startExperianceBtn heightExperiance"
              onClick={this.startExperiance}
            >
              Enter Event
            </button>
          </div>
          {/* <video
            style={{ display: "none", position: "fixed", width: "100vw", height: "100vh", right : "0", bottom : "0", objectFit: "contain", background : "black", zIndex : 1600}}
            id="mainVideoEle"
            src={entranceVideo}
            type="video/mp4"
            className="mainvideoEle"
            playsInline
            //   loop
            //   muted
            //   autoPlay
            controlsList="nodownload"
            onContextMenu={(e) => {
              e.preventDefault();
              return false;
            }}
          /> */}
          <iframe
            id="exteriorIframe"
            className="iframe-position"
            title="VR background"
            allowvr="yes"
            scrolling="no"
            src={exteriorIframeSrc}
          ></iframe>
          <Footer />
        </div>
        {/* } */}
      </div>
    );
  }
}
