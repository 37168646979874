
import firebase from 'firebase';

let authFirebaseApp;
SetupFirebase();

/**
* Firebase Initialization Function
* This must be called before any firebase query
*/
function SetupFirebase() {
    var firebaseConfig = {
        apiKey: "AIzaSyD6GU8Hdus5lsW5sqogRN5j_Y6o_a2n3qo",
        authDomain: "meta-ild-auth.firebaseapp.com",
        projectId: "meta-ild-auth",
        storageBucket: "meta-ild-auth.appspot.com",
        messagingSenderId: "1071544023803",
        appId: "1:1071544023803:web:975f7981dc5d3ec0574e5e",
        measurementId: "G-YFHRSBELPZ"
    };
    // Initialize Firebase
    authFirebaseApp = firebase.initializeApp(firebaseConfig, 'auth');
}

export default authFirebaseApp;
