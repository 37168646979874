/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react'
import Footer from '../footer/footer-page'
import videojs from "video.js";
// import './webinar-hall-page.css';
import Header from '../header/header';
import PortraitModeWarning from '../PortraitModeWarning';
const liveFormat = "application/x-mpegURL";

export default class webinarHall2D extends Component {
    componentDidMount() {
        videojs('my-video').src({
            type: liveFormat,
            src: 'https://d1cuqcohvmxzrq.cloudfront.net/stream/index.m3u8',
        });
    }
    render() {
        return (
            <div >
                <PortraitModeWarning />
                <Header headerType='user' />
                <div className='fullScreen'>

                    <video id="my-video" className="video-js video" style={{ marginTop: '0' }} controls preload="auto" playsInline crossOrigin="anonymous" data-setup="{}">
                        <source crossorigin="anonymous" src="https://d1cuqcohvmxzrq.cloudfront.net/stream/index.m3u8"
                            type="application/x-mpegURL" />
                        <p className="vjs-no-js">
                            To view this video please enable JavaScript, and consider upgrading to a
                            web browser that
                            <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
                        </p>
                    </video>


                </div>
                <Footer />
            </div>
        )
    }
}
