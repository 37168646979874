import { createBrowserHistory } from 'history'
import React, { Component } from 'react'
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'
import ArchiveGallary from '../archive-gallary/archive-gallary-page'
// import ArchiveGallary1 from '../archive-gallary/archive-gallary-page1'
// import ArchiveGallary2 from '../archive-gallary/archive-gallary-page2'
// import ArchiveGallary3 from '../archive-gallary/archive-gallary-page3'
// import ArchiveGallary4 from '../archive-gallary/archive-gallary-page4'
// import ArchiveGallary5 from '../archive-gallary/archive-gallary-page5'
// import ArchiveGallary6 from '../archive-gallary/archive-gallary-page6'
// import ArchiveGallary7 from '../archive-gallary/archive-gallary-page7'
// import ArchiveGallary8 from '../archive-gallary/archive-gallary-page8'
// import ArchiveGallary9 from '../archive-gallary/archive-gallary-page9'
// import ArchiveGallary10 from '../archive-gallary/archive-gallary-page10'
// import ArchiveGallary11 from '../archive-gallary/archive-gallary-page11'
import BreakTimeZone from '../break-time-zone/break-time-zone-page'
import BreakoutPage from '../breakout/breakout-page'
import DisplayGallaryPage from '../display-gallary/display-gallery-page'
import DisplayGallaryPage1 from '../display-gallary/display-gallery-page1'
import DisplayGallaryPage2 from '../display-gallary/display-gallery-page2'
import DisplayGallaryPage3 from '../display-gallary/display-gallery-page3'
import DisplayGallaryPage4 from '../display-gallary/display-gallery-page4'
import DisplayGallaryPage5 from '../display-gallary/display-gallery-page5'
import DisplayGallaryPage6 from '../display-gallary/display-gallery-page6'
import DisplayGallaryPage7 from '../display-gallary/display-gallery-page7'
import DisplayGallaryPage8 from '../display-gallary/display-gallery-page8'
import DisplayPage from '../display-gallary/display-page'
import Entrance from '../entrance/entrance'
import MedexFaculty from '../entrance/medex-faculty'
import Exterior from '../exterior/exterior'
import LoginPage from '../login/login-page'
import RootComponent from '../login/root-component'
import ModeratorWebinar from '../moderator/moderatorWebinar'
import webinarHall2D from '../webinar-hall/webinar-hall-2d'
import webinar2dHall from '../webinar-hall/webinar2d-hall'
import lighter2dWebinar from '../webinar-hall/lighter-2dwebinar'
// import ArchiveGallary12 from '../archive-gallary/archive-gallary-page12'

export default class RouterContainer extends Component {
    render() {
        return (
            <div>
                <Router history={createBrowserHistory}>
                    <Switch>
                        <Route exact path="/" component={LoginPage} />
                        <Route exact path="/main/:uid" component={RootComponent} />
                        <Route exact path="/exterior" component={Exterior} />
                        <Route exact path="/entrance" component={Entrance} />
                        <Route exact path="/archive" component={ArchiveGallary} />
                        {/* <Route exact path="/archive1" component={ArchiveGallary1} />
                        <Route exact path="/archive2" component={ArchiveGallary2} />
                        <Route exact path="/archive3" component={ArchiveGallary3} />
                        <Route exact path="/archive4" component={ArchiveGallary4} />
                        <Route exact path="/archive5" component={ArchiveGallary5} />
                        <Route exact path="/archive6" component={ArchiveGallary6} />
                        <Route exact path="/archive7" component={ArchiveGallary7} />
                        <Route exact path="/archive8" component={ArchiveGallary8} />
                        <Route exact path="/archive9" component={ArchiveGallary9} />
                        <Route exact path="/archive10" component={ArchiveGallary10} />
                        <Route exact path="/archive11" component={ArchiveGallary11} />
                        <Route exact path="/archive12" component={ArchiveGallary12} /> */}
                        <Route exact path="/medex-faculty" component={MedexFaculty} />
                        <Route exact path="/breakout-page" component={BreakoutPage} />
                        <Route exact path="/entertainment-zone" component={BreakTimeZone} />
                        <Route exact path="/display" component={DisplayPage} />
                        <Route exact path="/country" component={DisplayGallaryPage} />
                        <Route exact path="/country1" component={DisplayGallaryPage1} />
                        <Route exact path="/country2" component={DisplayGallaryPage2} />
                        <Route exact path="/country3" component={DisplayGallaryPage3} />
                        <Route exact path="/country4" component={DisplayGallaryPage4} />
                        <Route exact path="/country5" component={DisplayGallaryPage5} />
                        <Route exact path="/country6" component={DisplayGallaryPage6} />
                        <Route exact path="/country7" component={DisplayGallaryPage7} />
                        <Route exact path="/country8" component={DisplayGallaryPage8} />
                        <Route exact path="/webinar-hall" component={webinar2dHall} />
                        <Route exact path="/lwebinarHall" component={lighter2dWebinar} />
                        <Route exact path="/webinar-hall-2d" component={webinarHall2D} />
                        <Route exact path="/moderator-webinar" component={ModeratorWebinar} />
                    </Switch>
                </Router>
            </div>
        )
    }
}
