import React, { Component } from "react";
import Footer from "../footer/footer-page";
import Header from "../header/header";
import startCameraImg from "../images/medexIcon/camera-med.png";
import stopCameraImg from "../images/medexIcon/off-cam-med.png";
import CaptureImg from "../images/medexIcon/capture-med.png";
import loginFirebaseApp from "../functions/features/login-firebase-app";
import firebaseOther from "../functions/features/firebase-app";
import webinarLabel from "../images/WEBINAR HALL-LABEL.png";
import breakoutlabel from "../images/BREAKOUT ROOMS-LABEL.png";
import upArrow from "../images/up_arrow.png";
import frameImg from "../images/FRAME.png";
import pauseBtn from "../images/pause.png";
import exitLabel from "../images/exit-label.png";
import videoPlay from "../images/medexIcon/play-med.png";
import playPauseLabel from "../images/medexIcon/play-pause.png";
import click from "../images/click.png";
import right from "../images/medexIcon/swipe right.png";
import left from "../images/medexIcon/swipe left.png";
import leftImg from "../images/leftGifImg.png";
import rightImg from "../images/RightGifImg.png";
import "aframe";
import "aframe-gif-shader";
import "aframe-html-shader";
import dbFirebaseApp from "../functions/features/db-firebase-app";
import analyticsFirebase from "../functions/features/analytics-firebase-app";
import playImg from "../images/medexIcon/play-med.png";
import pauseImg from "../images/medexIcon/pause-med.png";
import closeBtn from "../images/medexIcon/cross.png";
import videoPosterImg from "../images/emma-video-poster.png";
import whiteImg from "../images/white.png";
import {
  analyticsFunction,
  updateLastSceneEndTime,
} from "../functions/helpers/analytics-function";
import LoadingPageBtn from "../loading/loading-page-btn";
import PortraitModeWarning from "../PortraitModeWarning";
const entranceSrc =
  "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/medex-2022/reception.jpg";
const entranceVideo =
  "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/medex-2022/medexVideo-c.mp4";

const tourVideo = "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/medex-2022/medexemma.mp4"

const centerVideoLink =
  "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/medex-2022/medexlobby.mp4";
const leftGifLink =
  "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/medex-2022/speakers.gif";
const leftSideGifLink =
  "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/medex-2022/sidebanner1.gif";
const leftSideRightGifLink =
  "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/medex-2022/side+b2.gif";
const startCamera =
  "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/medex-2022/acrosshf.gif";
let canvasDrawRequest = null;
const roomName = "entrance";
const expoName = "medex2022";
var snapshots = [];
var slideIndex = 0;

let speech = new SpeechSynthesisUtterance();
speech.lang = "en-US";
speech.volume = "1";
speech.rate = "0.8";
speech.pitch = "0.9";

let galleryImages = [];
let capturedSelfieImage = "";
let k = 0;
let isVideoPlay = false;
export default class Entrance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCameraStarted: false,
      isloading: true,
      btnStatus: false,
      isLighterBtn: false,
      isBtnLive: false,
      isAccessBtnLive: false,
    };
    this.videoRef = React.createRef();
    this.inputStreamRef = React.createRef();
  }

  componentDidMount() {
    if (
      localStorage.getItem("userRole") === "user" &&
      localStorage.getItem("uid") !== ""
    ) {
      console.warn("welcome");

      if (localStorage.getItem("isEntranceVideoPlayed") !== "watched") {
        document
          .getElementById("mainVideoEle")
          .addEventListener("ended", this.endVideo, false);

        document
          .getElementById("mainVideoEle").addEventListener('canplaythrough', (event) => {
            this.setState({
              btnStatus: true,
            });
          });
      }
    } else {
      window.location.href = "/";
    }
    try {
      const self = this;
      window.AFRAME.registerComponent("sky-entrance-component", {
        init: function () {
          let el = this.el;
          el.addEventListener("loaded", (e) => {
            console.log("sky showed");
            if (localStorage.getItem("isEntranceVideoPlayed") === "watched") {
              self.setState({
                isloading: false,
              });
            }
          });
        },
      });

      analyticsFunction(roomName);
      
      localStorage.setItem("Location", "entrance");
      let slideImagePromise = new Promise((resolve, reject) => {
        firebaseOther
          .firestore()
          .collection("Medex2022")
          .doc("SelfieZone")
          .get()
          .then((doc) => {
            if (doc.exists) galleryImages = doc.data().medex2022GalleryImages;
            console.log("gallery images", galleryImages);
            resolve(galleryImages);
          })
          .catch(function (error) {
            console.log(error);
            // Handle Errors here.

            reject(error);
            // ...
          });
      });
      slideImagePromise
        .then((result) => {
          console.warn("Successful", result);
          galleryImages = result;
          this.showSlideImage();
        })
        .catch((error) => {
          console.log("error caught", error);
          console.error(error);
        });
      updateLastSceneEndTime(roomName);

      setTimeout(() => {
        document.getElementById("leftaimg").setAttribute("visible", "false");
        document.getElementById("rightaimg").setAttribute("visible", "false");
      }, 20000);
    } catch (e) {
      console.error(e);
    }

    this.getUserDetails()
  }


  componentWillUnmount() {
    try {
      window.speechSynthesis.cancel();
    } catch (e) {
      console.error(e);
    }
  }

  playVideo1 = () => {
    if (
      document.getElementById("2dVideo2") &&
      document.getElementById("Pause2dVideo2") &&
      document.getElementById("Play2dVideo2")
    ) {
      document.getElementById("2dVideo2").play();
      document.getElementById("2dVideo2").volume = 1;
      document.getElementById("Pause2dVideo2").style.display = "block";
      document.getElementById("Play2dVideo2").style.display = "none";
      localStorage.setItem("isVideoWatched", "true");
    }
  };

  pauseVideo1 = () => {
    if (
      document.getElementById("2dVideo2") &&
      document.getElementById("Pause2dVideo2") &&
      document.getElementById("Play2dVideo2")
    ) {
      document.getElementById("2dVideo2").pause();

      document.getElementById("2dVideo2").volume = 0;
      document.getElementById("Play2dVideo2").style.display = "block";
      document.getElementById("Pause2dVideo2").style.display = "none";
    }
  };

  closeVideoPopup = () => {
    localStorage.setItem("isVideoWatched", "true");
    document.getElementById("VideoOverlay2").style.display = "none";
    document.getElementById("video-popup2").style.display = "none";
    document.getElementById("closeBtn2").style.display = "none";
    document.getElementById("2dVideo2").setAttribute("src", "");
  };

  showSlideImage = () => {
    console.warn("images is :: ", galleryImages);
    for (let i = 0; i < galleryImages.length; i++) {
      let imageElement = document.createElement("img");
      imageElement.setAttribute("crossOrigin", "anonymous");
      imageElement.setAttribute("id", "img" + i);
      imageElement.setAttribute("src", galleryImages[i]);
      document.getElementById("aAssetEntrance").appendChild(imageElement);
    }
    this.updateSrcAimage();
  };

  getUserDetails() {
      let self = this;
      const db = dbFirebaseApp.firestore();
      const userCollection = db.collection('MedexLive');
      userCollection.where('expo', '==', expoName)
          .onSnapshot(function (querySnapshot) {
              querySnapshot.docChanges().forEach(function (change) {
                  if (change.type === "added" && change.type !== "modified") {
                      console.log("New city: ", change.doc.data());
                      if(change.doc.data().isLive === 'true'){
                          self.setState({isBtnLive:true})
                      }
                      else if(change.doc.data().isLive === 'false'){
                          self.setState({isBtnLive:false})
                      }
                      if(change.doc.data().isAccessLive === 'true'){
                          self.setState({isAccessBtnLive:true})
                      }
                      else if(change.doc.data().isAccessLive === 'false'){
                          self.setState({isAccessBtnLive:false})
                      }
                      if (change.doc.data().lighterBtn === 'true') {
                        self.setState({ isLighterBtn: true })
                      } else if (change.doc.data().lighterBtn === 'false') {
                        self.setState({ isLighterBtn: false })
                      }

                  }
                  if (change.type === "modified") {
                      console.log("Modified city: ", change.doc.data());
                          console.log("New city: ", change.doc.data());
                          if(change.doc.data().isLive === 'true'){
                              self.setState({isBtnLive:true})
                          }
                          else if(change.doc.data().isLive === 'false'){
                              self.setState({isBtnLive:false})
                          }

                          if(change.doc.data().isAccessLive === 'true'){
                            self.setState({isAccessBtnLive:true})
                        }
                        else if(change.doc.data().isAccessLive === 'false'){
                            self.setState({isAccessBtnLive:false})
                        }
                        if (change.doc.data().lighterBtn === 'true') {
                          self.setState({ isLighterBtn: true })
                        } else if (change.doc.data().lighterBtn === 'false') {
                          self.setState({ isLighterBtn: false })
                        }
                  }
              });

          });

  }

  showSlides = () => {
    var slides = document.getElementsByClassName("mySlides");
    for (let i = 0; i < slides.length; i++) {
      var images = document.getElementById("image" + i);
    }
    console.warn("image src is ::", images.src);
    if (slides) {
      for (let i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      slideIndex++;
      if (slideIndex > slides.length) {
        slideIndex = 1;
      }
      for (let i = 0; i < slides.length; i++) {
        slides[i].className = slides[i].className.replace(" active", "");
      }
      if (slides[slideIndex - 1]) {
        slides[slideIndex - 1].style.display = "block";
        slides[slideIndex - 1].className += " active";
        this.drawCanvasforSlideShow(
          document.getElementById("image" + (slideIndex - 1))
        );
      }

      setTimeout(this.showSlides, 2000); // Change image every 2 seconds
    }
  };

  updateSrcAimage = () => {
    let slideShowElement = document.getElementById("slide-show");
    if (slideShowElement) {
      slideShowElement.setAttribute("src", "#img" + k);
      k++;
      if (k === galleryImages.length) {
        k = 0;
      }
      // }
      setTimeout(() => {
        this.updateSrcAimage();
      }, 2000);
    }
    let slideShowElement2 = document.getElementById("slide-show2");
    if (slideShowElement2) {
      slideShowElement2.setAttribute("src", "#img" + k);
    }
  };

  async startCamera() {
    try {
      const CAMERA_CONSTRAINTS = {
        // audio: true,
        video: true,
      };
      this.inputStreamRef.current = await navigator.mediaDevices.getUserMedia(
        CAMERA_CONSTRAINTS
      );

      this.videoRef.current.srcObject = this.inputStreamRef.current;

      await this.videoRef.current.play();

      this.setState({ isCameraStarted: true });
      document.getElementById("slide-show").setAttribute("visible", false);
      document.getElementById("selfieDisplay").setAttribute("visible", true);
      document.getElementById("captureBtn").setAttribute("visible", true);
      document
        .getElementById("captureBtn")
        .setAttribute("position", "8 3.05 -2.85");
      document.getElementById("stopcameraBtn").setAttribute("visible", true);
      document
        .getElementById("stopcameraBtn")
        .setAttribute("position", "7.5 3.05 -3.9");
      document.getElementById("startCameraBtn").setAttribute("visible", false);
      document
        .getElementById("startCameraBtn")
        .setAttribute("position", "0 200 0");
      if (this.isMobileDevice()) {
        console.warn("mobile device");
        this.drawCanvasForMobile();
      } else {
        this.drawCanvas();
      }
    } catch (e) {
      console.warn("Camera start error", e);
    }
  }

  drawCanvas = () => {
    var canvas = document.getElementById("selfieCanvas");
    var video = document.getElementById("videoselfie");
    if (canvas && video) {
      canvas.width = 1488;
      canvas.height = 872;

      var ctx = canvas.getContext("2d");
      ctx.fillStyle = "#ffffff";
      ctx.fillRect(0, 0, 1488, 872);
      ctx.drawImage(video, 104, 0, 1280, 720);
      let myImage = new Image();
      myImage.src = frameImg;
      ctx.drawImage(myImage, 0, 0, 1488, 872);
    }
    canvasDrawRequest = requestAnimationFrame(this.drawCanvas);
  };

  drawCanvasForMobile = () => {
    var canvas = document.getElementById("selfieCanvas");
    var video = document.getElementById("videoselfie");
    if (canvas && video) {
      canvas.width = 1488;
      canvas.height = 872;
      var ctx = canvas.getContext("2d");
      ctx.fillStyle = "#ffffff";
      ctx.fillRect(0, 0, 1488, 972);
      ctx.drawImage(video, 444, 0, 600, 872);
      let myImage = new Image();
      myImage.src = frameImg;
      ctx.drawImage(myImage, 0, 0, 1488, 872);
    }
    canvasDrawRequest = requestAnimationFrame(this.drawCanvasForMobile);
  };

  drawCanvasforSlideShow = (image) => {
    console.warn("image in function is ", image);
    var canvas = document.getElementById("slide-Canvas");
    if (canvas) {
      canvas.width = 500;
      canvas.height = 300;
      var ctx = canvas.getContext("2d");

      let myImage = new Image();
      myImage.src = image.src;
      myImage.crossOrigin = "";
      ctx.drawImage(myImage, 0, 0, 500, 300);
    }
  };

  stopCamera() {
    console.warn("Stop Camera");
    if (this.inputStreamRef.current !== null) {
      this.inputStreamRef.current.getTracks().forEach((track) => {
        track.stop();
      });
    }
    this.setState({ isCameraStarted: false });
    document.getElementById("selfieDisplay").setAttribute("visible", false);
    document.getElementById("captureBtn").setAttribute("visible", false);
    document.getElementById("captureBtn").setAttribute("position", "0 200 0");
    document.getElementById("stopcameraBtn").setAttribute("visible", false);
    document
      .getElementById("stopcameraBtn")
      .setAttribute("position", "0 200 0");
    document.getElementById("startCameraBtn").setAttribute("visible", true);
    document
      .getElementById("startCameraBtn")
      .setAttribute("position", "7.5 3 -3.5");
    document.getElementById("slide-show").setAttribute("visible", true);
  }

  captureCamera() {
    console.warn("capture camera");
    var video = document.getElementById("videoselfie");
    var output = document.getElementById("output");
    var canvas = document.createElement("canvas");
    canvas.setAttribute("id", "shootCanvas");
    canvas.width = 1488;
    canvas.height = 872;
    var ctx = canvas.getContext("2d");
    ctx.fillStyle = "#ffffff";
    ctx.fillRect(0, 0, 1488, 972);
    ctx.drawImage(video, 104, 0, 1280, 720);
    let myImage = new Image();
    myImage.src = frameImg;

    const self = this;
    const guid = () => {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
      }
      return String(
        s4() +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        s4() +
        s4()
      );
    };
    setTimeout(() => {
      ctx.drawImage(myImage, 0, 0, 1488, 872);
      snapshots.unshift(canvas);
      output.innerHTML = "";
      for (var i = 0; i < 4; i++) {
        output.appendChild(snapshots[0]);
      }
    }, 10);

    setTimeout(() => {
      canvas.toBlob(function (blob) {
        console.log(blob);

        let myPromise = new Promise((resolve, reject) => {
          const myGuid = guid();
          const storageUrl = loginFirebaseApp.storage(
            "gs://vr-expo.appspot.com/"
          );
          const storageRef = storageUrl.ref();

          const uploadTask = storageRef
            .child("angels")
            .child("medex2022")
            .child(myGuid)
            .put(blob);
          uploadTask.on(
            "state_changed",
            (snapShot) => { },
            (err) => {
              console.log(err);
              reject(err);
            },
            () => {
              loginFirebaseApp
                .storage("gs://vr-expo.appspot.com/")
                .ref()
                .child("angels")
                .child("medex2022")
                .child(myGuid)
                .getDownloadURL()
                .then((fireBaseUrl) => {
                  resolve(fireBaseUrl);
                })
                .catch((err) => {
                  console.log("error caught", err);
                });
            }
          );
        });
        myPromise
          .then((url) => {
            console.log(url);

            capturedSelfieImage = url;
            galleryImages = [...galleryImages, capturedSelfieImage];

            console.log("gallary images", galleryImages);
            console.log("captured selfie images", capturedSelfieImage);
            firebaseOther
              .firestore()
              .collection("Medex2022")
              .doc("SelfieZone")
              .update({
                medex2022GalleryImages: galleryImages,
              });

            self.stopCamera();
            setTimeout(() => {
              self.showSlideImage();
            }, 1500);
          })
          .catch((err) => {
            console.log("error caught", err);
          });
      }, "image/png");
    }, 1000);

    setTimeout(() => {
      var downloadLink = document.getElementById("downloader");
      downloadLink.click();
    }, 10);
  }

  captureCameraForMobile() {
    console.warn("capture camera");
    var video = document.getElementById("videoselfie");
    var output = document.getElementById("output");
    var canvas = document.createElement("canvas");
    canvas.setAttribute("id", "shootCanvas");
    canvas.width = 1488;
    canvas.height = 872;
    var ctx = canvas.getContext("2d");
    let whiteImage = new Image();
    whiteImage.src = whiteImg;
    ctx.drawImage(whiteImage, 0, 0, 1488, 872);
    ctx.drawImage(video, 444, 0, 600, 872);
    let myImage = new Image();
    myImage.src = frameImg;

    const self = this;
    const guid = () => {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
      }
      return String(
        s4() +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        "-" +
        s4() +
        s4() +
        s4()
      );
    };
    setTimeout(() => {
      ctx.drawImage(myImage, 0, 0, 1488, 872);
      snapshots.unshift(canvas);
      output.innerHTML = "";
      for (var i = 0; i < 4; i++) {
        output.appendChild(snapshots[0]);
      }
    }, 10);

    setTimeout(() => {
      canvas.toBlob(function (blob) {
        console.log(blob);

        let myPromise = new Promise((resolve, reject) => {
          const myGuid = guid();
          const storageUrl = loginFirebaseApp.storage(
            "gs://vr-expo.appspot.com/"
          );
          const storageRef = storageUrl.ref();

          const uploadTask = storageRef
            .child("angels")
            .child("medex2022")
            .child(myGuid)
            .put(blob);
          uploadTask.on(
            "state_changed",
            (snapShot) => { },
            (err) => {
              console.log(err);
              reject(err);
            },
            () => {
              loginFirebaseApp
                .storage("gs://vr-expo.appspot.com/")
                .ref()
                .child("angels")
                .child("medex2022")
                .child(myGuid)
                .getDownloadURL()
                .then((fireBaseUrl) => {
                  resolve(fireBaseUrl);
                })
                .catch((err) => {
                  console.log("error caught", err);
                });
            }
          );
        });
        myPromise
          .then((url) => {
            console.log(url);

            capturedSelfieImage = url;
            galleryImages = [...galleryImages, capturedSelfieImage];

            console.log("gallary images", galleryImages);
            console.log("captured selfie images", capturedSelfieImage);
            firebaseOther
              .firestore()
              .collection("Medex2022")
              .doc("SelfieZone")
              .update({
                medex2022GalleryImages: galleryImages,
              });

            self.stopCamera();
            setTimeout(() => {
              self.showSlideImage();
            }, 1500);
          })
          .catch((err) => {
            console.log("error caught", err);
          });

        // this line should be here
      }, "image/png");
    }, 1000);

    setTimeout(() => {
      var downloadLink = document.getElementById("downloader");
      downloadLink.click();
    }, 10);
  }

  togglePlayPauseVideo = () => {
    if (isVideoPlay) {
      this.pauseVideo();
      isVideoPlay = false;
    } else {
      this.playVideo();
    }
  };

  playVideo = () => {
    document.getElementById("entranceVideoCenter").play();
    document.getElementById("playBtn").setAttribute("visible", "false");
    document.getElementById("playBtn").setAttribute("position", "0 20 0");
    isVideoPlay = true;
  };

  pauseVideo = () => {
    document.getElementById("entranceVideoCenter").pause();
    document.getElementById("playBtn").setAttribute("visible", "true");
    document
      .getElementById("playBtn")
      .setAttribute("position", "-0.3 3.2 -4.95");
  };

  downloadImage = () => {
    document.getElementById("downloader").download = "image.png";
    document.getElementById("downloader").href = document
      .getElementById("shootCanvas")
      .toDataURL("image/png")
      .replace(/^data:image\/[^;]/, "data:application/octet-stream");
  };
  goToBreakOut = () => {
    window.location.href = "/breakout-page";
  };

  goToWebinar = () => {
    window.location.href = "/webinar-hall";
  };

  goToDisplayGallery = () => {
    window.location.href = "/display";
  };

  goToCountry = () => {
    window.location.href = "/country";
  };

  goToArchive = () => {
    window.location.href = "/archive";
  };

  goToExterior = () => {
    this.userLogout()
  };

  goToEntertainment = () => {
    window.location.href = "/entertainment-zone";
  };

  goToMedexFaculty = () => {
    window.location.href = "/medex-faculty";
  };

  goToAgenda = () => {
    document.getElementById("location1").style.display = "block";
  };

  goToT2d = () => {
    window.open("https://pro.boehringer-ingelheim.com/across-hf/ ", "_blank");
  };

  goToHf = () => {
    window.open("https://pro.boehringer-ingelheim.com/across-hf/ ", "_blank");
  };

  isMobileDevice = () => {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true;
    } else {
      return false;
    }
  };

  changeStateForLoader = (value) => {
    this.setState({ isloading: value });
    window.speechSynthesis.cancel();
    if (localStorage.getItem("isEntranceVideoPlayed") !== "watched") {
      let videoPlayer = document.getElementById("mainVideoEle");
      videoPlayer.style.display = "block";
      videoPlayer.volume = 1.0;
      videoPlayer.play();
      document.getElementById("skipButton").style.display="block"
      localStorage.setItem("isEntranceVideoPlayed", "watched")
      speech.text = `Hello ${localStorage.getItem(
        "designation"
      )}, ${localStorage.getItem(
        "name"
      )} and welcome to MEDEX 2022 Virtual Summit - Heart Failure Chapter. Hope you have a pleasant experience here.`;
      window.speechSynthesis.speak(speech);

      speech.onend = function (event) {
        window.speechSynthesis.cancel();
      };
    }
  };

  userLogout = () => {


    console.warn('update last scene end time')
    const db = analyticsFirebase.firestore();
    db.collection("MEDEX2022LiveUsers").doc(localStorage.getItem('analyticsDocRef')).update({
      endTime: new Date().getTime(),
    })
      .then(function (docRef) {
        console.log("Document written with ID: ", localStorage.getItem('analyticsDocRef'));

        localStorage.clear()
        window.location.href = "/"
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      });

  }

  endVideo = (e) => {
    let videoPlayer = document.getElementById("mainVideoEle");
    videoPlayer.pause();
    window.speechSynthesis.cancel();
    videoPlayer.style.display = "none";
    document.getElementById("skipButton").style.display="none"

  };

  render() {
    return (
      <div>
        <PortraitModeWarning />
        {this.state.isloading && (
          <LoadingPageBtn
            updateStateForLoader={this.changeStateForLoader}
            btnStatus={this.state.btnStatus}
            lighterBtn={this.state.isLighterBtn}
            location={localStorage.getItem("isEntranceVideoPlayed") === "watched" ? "multiple" : "Entrance"}
          />
        )}
        <Header headerType="user" />

        {localStorage.getItem("isVideoWatched") !== "true" && (

          <div>
            <div className="overlayVideo" id="VideoOverlay2">
              {/* <div id="countdown1" style={{ display: 'none', position: 'fixed', top: '30px', width: '70px', textAlign: 'center', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', padding: '3px 10px', border: '2px solid white', borderRadius: '5px' }}> <span></span> </div> */}
            </div>

            <div id="video-popup2">
              <button
                id="closeBtn2"
                className="closebtnposition"
                onClick={this.closeVideoPopup}
                style={{ outline: "none" }}
              >
                <img src={closeBtn} alt="play" className="closebtnwidth"></img>
              </button>
              <p className="videoTitle">Guided Tour by Emma</p>
              <video
                id="2dVideo2"
                className="videopopup"
                playsInline
                crossOrigin="anonymous"
                src={tourVideo}
                poster={videoPosterImg}
                preload="auto"
              ></video>
              <button
                id="Play2dVideo2"
                onClick={this.playVideo1}
                style={{ outline: "none" }}
              >
                <img src={playImg} alt="play" className="playbtnwidth"></img>
                <p className="playvideotext">Play Guided Tour</p>
              </button>
              <button
                id="Pause2dVideo2"
                style={{ display: "none", outline: "none" }}
                onClick={this.pauseVideo1}
              >
                <img src={pauseImg} alt="play" className="playbtnwidth"></img>
                <p className="playvideotext">Pause Guided Tour</p>
              </button>
              {this.state.isBtnLive && localStorage.getItem('Location') !== 'webinar-hall' &&
                    <button id="quickAccessBtn" onClick={this.goToWebinar} className="btn-glow" style={{borderRadius : '15px'}}>Quick Access to Live Webinar</button>}
              {this.state.isAccessBtnLive  && localStorage.getItem('Location') !== 'archive-gallery' &&
                    <button id="quickAccessBtn" onClick={this.goToArchive} className="btn-glow" style={{borderRadius : '15px'}}>Quick Access to Latest Recorded Session</button>}
            </div>
          </div>
        )}
        <video
          style={{
            display: "none",
            position: "fixed",
            width: "100vw",
            height: "100vh",
            right: "0",
            bottom: "0",
            objectFit: "fill",
            background: "black",
            zIndex: 1600,
          }}
          id="mainVideoEle"
          src={entranceVideo}
          type="video/mp4"
          className="mainvideoEle"
          playsInline
          preload="metadata"
          //   loop
          //   muted
          //   autoPlay
          controlsList="nodownload"
          onContextMenu={(e) => {
            e.preventDefault();
            return false;
          }}
        />
        <button
            className="skipButton"
            id="skipButton"
            style={{ display: "none" }}
            onClick={this.endVideo}
          >
            Skip
          </button>
        <video
          id="videoselfie"
          ref={this.videoRef}
          autoPlay
          playsInline
          muted
          style={{ position: "fixed", top: "0px", left: "0px" }}
        ></video>
        <div
          id="selfieImage"
          style={{
            width: "100%",
            height: "100%",
            position: "fixed",
            left: "0",
            top: "0",
            zIndex: "-1",
            overflow: "hidden",
          }}
        >
          <canvas
            id="selfieCanvas"
            style={{ width: "100%", height: "100%" }}
          ></canvas>
        </div>
        <a
          href="#"
          id="downloader"
          onClick={this.downloadImage}
          download="image.png"
        ></a>
        <br />
        <div id="output"></div>
        <a-scene
          loading-screen="enabled: false"
          vr-mode-ui="enabled: false"
          style={{ zIndex: 2 }}
        >
          <a-assets id="aAssetEntrance">
            <img
              id="sky"
              crossOrigin="anonymous"
              src={entranceSrc}
              alt="background"
            />
            <img id="startCamera" src={startCameraImg} alt="upload Image" />
            <img id="stopCamera" src={stopCameraImg} alt="stop camera" />
            <img id="captureImage" src={CaptureImg} alt="capture Image" />
            <img id="arrow" src={upArrow} alt="arrow" />
            <img
              id="transparent"
              src="https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/transparent.png"
              alt="image"
            />
            <img id="webinar-label" src={webinarLabel} alt="webinarLabel" />
            <img id="breakout-label" src={breakoutlabel} alt="breakoutLabel" />
            <img id="play-btn" src={videoPlay} alt="playBtn" />
            <img id="pause-btn" src={pauseBtn} alt="pauseBtn" />
            <img id="play-label" src={playPauseLabel} alt="play-label" />
            <img id="exit-label" src={exitLabel} alt="pause-label" />
            <img id="left-gif" src={null} src={leftGifLink}  alt="gif" />
            <img id="left-side-gif" src={leftSideGifLink} alt="gif" />
            <img id="left-sideRight-gif" src={leftSideRightGifLink} alt="gif" />
            <img id="left-gif-text" src={click} alt="click" />
            <img id="swiperight-gif-text" src={right} alt="click" />
            <img id="swipeleft-gif-text" src={left} alt="click" />
            <img id="rightgif" src={rightImg} alt="rightimg" />
            <img id="leftgif" src={leftImg} alt="leftimg" />
            <img id="cameraGif" src={startCamera} alt="leftimg" />
            <video
              id="entranceVideoCenter"
              webkit-playsinline="true"
              playsInline
              loop
              preload="auto"
              src={centerVideoLink}
              alt="entrancevideo"
            />
          </a-assets>
          <a-camera
            c-zoom-in-out="defaultFov: 80"
            look-controls="magicWindowTrackingEnabled: false; reverseTouchDrag: true; disableVerticalMotion:true;"
            id="camera"
            fov="80"
            wasd-controls="enabled:false"
          >
            <a-entity
              cursor="rayOrigin: mouse; fuse: false; disableDragClick: true"
              raycaster="objects: .raycaster-solid; recursive: false;"
            />
          </a-camera>

          <a-sky src="#sky" rotation="0 0 0" sky-entrance-component></a-sky>
          <a-image
            visible="false"
            id="selfieDisplay"
            position="8.5 0.8 -3.7028325964833"
            rotation="0 -70 0"
            scale="3.5 2.6 3"
            material="shader: html; target: #selfieImage; fps: 10"
          ></a-image>
          <a-image
            id="slide-show"
            position="8.3 0.8 -3.7028325964833"
            rotation="0 -70 0"
            scale="3.5 2.6 3"
          ></a-image>

           {/* <a-image
            src="#transparent"
            id="arrowbtn3"
            class="raycaster-solid clickable"
            opacity="0"
            scale="1.8 1.5 1.5"
            onClick={this.goToEntertainment}
            rotation="0 0 0"
            position="-11 -1.357 -8.808"
          ></a-image> */}
          <a-image
            src="#transparent"
            id="arrowbtn2"
            class="raycaster-solid clickable"
            opacity="0"
            scale="1.5 1 1.5"
            onClick={this.goToArchive}
            rotation="0 0 0"
            position="-5.422 -1.301 -10.000"
          ></a-image>
         
          {/* <a-image
            src="#transparent"
            id="arrowbtn"
            class="raycaster-solid clickable"
            opacity="0"
            scale="1.5 1 1.5"
            onClick={this.goToWebinar}
            rotation="0 0 0"
            position="-0.837 -0.985 -10.000"
          ></a-image> */}
{/*            
          <a-image
            src="#transparent"
            id="arrowbtn4"
            class="raycaster-solid clickable"
            opacity="0"
            scale="1.5 1 1.5"
            onClick={this.goToCountry}
            rotation="0 0 0"
            position="4.082 -1.160 -10.000"
          ></a-image> */}
          {/* <a-image
            src="#transparent"
            id="arrowbtn4"
            class="raycaster-solid clickable"
            opacity="0"
            scale="1.8 1.5 1.5"
            onClick={this.goToDisplayGallery}
            rotation="0 0 0"
            position="9.4 -1.341 -10.000"
          ></a-image> */}
          <a-image
            src="#exit-label"
            id="exit-label1"
            class="raycaster-solid clickable"
            scale="3 1.2 1.2"
            rotation="0 180  0"
            position="0.5 3.3 12"
          ></a-image>
          {/* <a-image
            src="#arrow"
            id="arrowbtn3"
            class="raycaster-solid clickable"
            scale="1.2 1.2 1.2"
            onClick={this.goToExterior}
            rotation="60 0  0"
            position="0.5 -1.5 12"
          ></a-image> */}

          <a-entity
            id="gif"
            class="raycaster-solid"
            geometry={`primitive:plane;width:4.05;height:4.8;`}
            rotation="0 60 0"
            position="-15 1.35 -6.6"
            material="shader:gif;src:url(https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/medex-2022/speakers.gif);"
          />
          <a-entity
            id="sidegif"
            class="raycaster-solid"
            geometry={`primitive:plane;width:4.2;height:5.8;`}
            rotation="0 90 0"
            position="-16.5 3.8 5"
            material="shader:gif;src:url(https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/medex-2022/sidebanner1.gif);"
          />
          <a-entity
            id="sideRightgif"
            class="raycaster-solid"
            geometry={`primitive:plane;width:8.7;height:12.2;`}
            rotation="0 90 0"
            position="-35 6.35 -0.8"
            material="shader:gif;src:url(https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/medex-2022/side+b2.gif);"
          />
          <a-entity
            id="leftgif"
            class="raycaster-solid"
            geometry={`primitive:plane;width:2.5;height:1.4;`}
            position="-3.7 3 -3.5"
            rotation="0 25 -1"
            material="shader:gif;src:url(https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/medex-2022/banner2.gif);"
          />
          <a-entity
            id="rightgif"
            class="raycaster-solid"
            geometry={`primitive:plane;width:2.25;height:1.3;`}
            position="3.05 2.9 -3.8"
            rotation="0 -25 0"
            material="shader:gif;src:url(https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/medex-2022/banner1.gif);"
          />
          <a-image
            id="leftaimg"
            src="#leftgif"
            position="-3.7 3 -3.5"
            rotation="0 25 -1"
            width="2.5"
            height="1.4"
          ></a-image>
          <a-image
            id="rightaimg"
            src="#rightgif"
            position="3.05 2.9 -3.8"
            rotation="0 -25 0"
            width="2.25"
            height="1.3"
          ></a-image>
          <a-entity
            id="arrowGif"
            class="raycaster-solid"
            geometry={`primitive:plane;width:1;height:0.5;`}
            rotation="0 0 0"
            position="2 0.5 -3"
            material="shader:gif;src:url(https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/angels-events/medex-arrow.gif);alphaTest:0.5"
          />
          <a-entity
            id="arrowGif2"
            class="raycaster-solid"
            geometry={`primitive:plane;width:1;height:0.5;`}
            rotation="0 0 180"
            position="-2.4 0.5 -3"
            material="shader:gif;src:url(https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/angels-events/medex-arrow.gif);alphaTest:0.5"
          />
          <a-entity
            id="cameraGif"
            class="raycaster-solid clickable"
            geometry={`primitive:plane;width:1.3;height:1;`}
            position="7.5 1.65 1.2"
            rotation="0 -90 0"
            material="shader:gif;src:url(https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/medex-2022/acrosshf.gif);alphaTest:0.5;"
            onClick={() => this.goToHf()}
          />
          <a-image
            src="#swiperight-gif-text"
            id="swiperight-gif-text1"
            class="raycaster-solid clickable"
            scale="1 0.15 1"
            rotation="0 0 0"
            position="2 0.3 -3"
          ></a-image>
          <a-image
            src="#swipeleft-gif-text"
            id="swipeleft-gif-text1"
            class="raycaster-solid clickable"
            scale="1 0.15 1"
            rotation="0 0 0"
            position="-2.4 0.3 -3"
          ></a-image>
          <a-image
            src="#transparent"
            id="left-gif-click1"
            class="raycaster-solid clickable"
            onClick={this.goToMedexFaculty}
            scale="1 1 1"
            opacity="0"
            height="1.4"
            width="3.9"
            rotation="0 60 0"
            position="-15 -1.9 -6.6"
          ></a-image>
          <a-image
            src="#transparent"
            id="t2d"
            class="raycaster-solid clickable"
            onClick={this.goToT2d}
            scale="1 1 1"
            opacity="0"
            height="0.5"
            width="1"
            position="7.5 2.85 1.2"
            rotation="0 -90 0"
          ></a-image>
          <a-image
            src="#transparent"
            id="hf"
            class="raycaster-solid clickable"
            onClick={this.goToHf}
            scale="1 1 1"
            opacity="0"
            height="0.5"
            width="1"
            position="7.5 1.65 1.2"
            rotation="0 -90 0"
          ></a-image>
          <a-image
            src="#startCamera"
            id="startCameraBtn"
            position="7.5 3 -3.5"
            rotation="0 -90 0"
            scale="0.8 0.8 0.8"
            class="raycaster-solid clickable"
            onClick={() => this.startCamera()}
          ></a-image>
          <a-image
            src="#stopCamera"
            id="stopcameraBtn"
            position="6.9 -0.45 -4"
            visible="false"
            rotation="0 -90 0"
            scale="0.8 0.8 0.8"
            class="raycaster-solid clickable"
            onClick={() => this.stopCamera()}
          ></a-image>
          {this.isMobileDevice() ? (
            <a-image
              src="#captureImage"
              id="captureBtn"
              position="8 -0.55 -2.95"
              visible="false"
              rotation="0 -90 0"
              scale="0.8 0.8 0.8"
              class="raycaster-solid clickable"
              onClick={() => this.captureCameraForMobile()}
            ></a-image>
          ) : (
            <a-image
              src="#captureImage"
              id="captureBtn"
              position="8 -0.55 -2.95"
              visible="false"
              rotation="0 -90 0"
              scale="0.8 0.8 0.8"
              class="raycaster-solid clickable"
              onClick={() => this.captureCamera()}
            ></a-image>
          )}
          <a-image
            src="#play-btn"
            id="playBtn"
            scale="0.35 0.35 0.4"
            position="-0.3 3.1 -4.95"
            class="raycaster-solid clickable"
            onClick={() => this.playVideo()}
          ></a-image>
          <a-image
            src="#pause-btn"
            id="pauseBtn"
            scale="0.35 0.35 0.4"
            visible="false"
            position="0 20 0"
            class="raycaster-solid clickable"
            onClick={() => this.pauseVideo()}
          ></a-image>
          <a-image
            src="#play-label"
            id="playLabel1"
            scale="2 0.25 0.5"
            position="-0.45 4.8 -6.5"
            class="raycaster-solid clickable"
          ></a-image>
          <a-video
            src="#entranceVideoCenter"
            id="videoEntrance"
            scale="2.65 1.6 1"
            position="-0.37 3.15 -5"
            class="raycaster-solid clickable"
            onClick={() => this.togglePlayPauseVideo()}
          ></a-video>
        </a-scene>
        <Footer />
      </div>
    );
  }
}
