import React, { Component } from 'react';
import './notification.css';
import InfoIcon from '@material-ui/icons/Info';

export default class CustomNotification extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {

    }


    render() {
        return (
            <div>

                <div className="notificationposition">
                    <div className="rectangle marginbottomrectangle">
                        <div className="notification-text">
                            <InfoIcon style={{ marginRight: '10px' }} />             <span>{this.props.msg}</span>
                        </div>
                    </div>
                    <div className="rectangle" style={{ display: (this.props.second === true) ? 'block' : 'none' }}>
                        <div className="notification-text">
                            <InfoIcon />             <span>{this.props.msg2}</span>
                        </div>
                    </div>
                </div>

            </div>

        )
    }
}
