import React, { Component } from 'react'
import Footer from '../footer/footer-page'
import Header from '../header/header';
import '../functions/helpers/c-zoom-in-out'
import { isMobileDevice } from '../functions/helpers/extra-functions';
import { updateLastSceneEndTime } from '../functions/helpers/analytics-function';
import PortraitModeWarning from '../PortraitModeWarning';
const roomName = 'entertainmentZone'
const radioListNew = [
    'https://icecast.argovia.ch/vrock', // Virgin Rock
    'https://radio.skynewsarabia.com/stream/radio/skynewsarabia', // sky news arabia
    'https://stream.radiojar.com/5wpf9e4erxquv', // Al Khaleejiya
    'https://l3.itworkscdn.net/smcradiolive/smcradiolive/icecast.audio', // Radio Asia
    'https://stream.radiojar.com/rzcfw4cbsxquv', // Pravasi Bharati News
    'https://funasia.streamguys1.com/live9', // Big FM
]
let myCameraFov = false;
export default class BreakTimeZone extends Component {


    constructor(props) {
        super(props);
        this.state = {
            selectedbutton: '',
            isVideoEnd : false,
            pageRef : false,
        }
        this.radioPlayerClick = this.radioPlayerClick.bind(this);
        this.goBackToLobby = this.goBackToLobby.bind(this);
    }

    radioPlayerClick(radiosrc) {
        let radioButton = document.getElementById("radiobuttons")
        radioButton.setAttribute('src', radiosrc)
        radioButton.play()
    }

    stopRadio = () => {
        let radioButton = document.getElementById("radiobuttons")
        radioButton.pause()
        radioButton.currentTime = 0;
    }

    goBackToLobby() {
        this.props.goToLobby();
    }


    componentDidMount() {
        if (localStorage.getItem('userRole') === 'user' && localStorage.getItem('uid') !== '') {
            console.warn('welcome')
        } else {
            window.location.href = '/'
        }
        localStorage.setItem('Location', 'entertainment-zone')
        document
                .getElementById("breakTimeZone")
                .addEventListener("ended", this.endVideo, false);

            let videoPlayer = document.getElementById("breakTimeZone");
            videoPlayer.style.display = "block";
            videoPlayer.volume = 1.0;
            videoPlayer.play();
        window.addEventListener("message", this.projectButtonClick, false);
        setTimeout(() => {

            let iframeEl = document.getElementById('newEntertainmentPageBackground');
            if (iframeEl) {
                const userInfo = {
                    name: localStorage.getItem('name'),
                    mobileno: localStorage.getItem('mobile'),
                    email: localStorage.getItem('email'),
                    expo: 'medex'
                }
                iframeEl.contentWindow.postMessage(userInfo, '*');
            }
        }, 4000);
        updateLastSceneEndTime(roomName)
    }
    endVideo = (e) => {
        let videoPlayer = document.getElementById("breakTimeZone");
        videoPlayer.pause();
        videoPlayer.style.display = "none";
    };

    componentWillUnmount() {
        window.removeEventListener("message", this.projectButtonClick, false);
    }

    projectButtonClick = (e) => {

        console.log('called on entertainment arrow')
        if (e.data.isImageClicked) {
            console.log('called on lobby arrow')
            if (e.data.mslKey === 'expo.virtualpropexpo2020.com/lobby') {
                window.location.href = '/entrance'
            }
        }

        if (e.data.isLinkClicked) {
            console.warn('Entertainment button click', e.data.url);
            if (e.data.url === 'radiostation1') {
                this.radioPlayerClick(radioListNew[0]);
            } else if (e.data.url === 'radiostation2') {
                this.radioPlayerClick(radioListNew[1]);
            } else if (e.data.url === 'radiostation3') {
                this.radioPlayerClick(radioListNew[2]);
            } else if (e.data.url === 'radiostation4') {
                this.radioPlayerClick(radioListNew[3]);
            } else if (e.data.url === 'radiostation5') {
                this.radioPlayerClick(radioListNew[4]);
            } else if (e.data.url === 'radiostation6') {
                this.radioPlayerClick(radioListNew[5]);
            } else if (e.data.url === 'stopradiostation') {
                this.stopRadio();
            }
        }
    }

    onTouchStart = (event) => {
        if (isMobileDevice() && myCameraFov === false) {
            if (document.querySelector('#entertainmentCamera')) {
                document.querySelector('#entertainmentCamera').setAttribute('fov', '55');
                setTimeout(() => {
                    document.querySelector('#entertainmentCamera').setAttribute('fov', '60');
                }, 100);
                myCameraFov = true;
            }
        }
    }

    render() {
        return (
            <div>
                <PortraitModeWarning />
                <video
                    style={{
                        display: "none",
                        position: "fixed",
                        width: "100vw",
                        height: "100vh",
                        right: "0",
                        bottom: "0",
                        objectFit: "fill",
                        background: "black",
                        zIndex: 1600,
                    }}
                    id="breakTimeZone"
                    src={"https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/medex-2022/breakTimeZone.mp4"}
                    type="video/mp4"
                    className="mainvideoEle"
                    playsInline
                    //   loop
                      muted
                      autoPlay
                    controlsList="nodownload"
                    onContextMenu={(e) => {
                        e.preventDefault();
                        return false;
                    }}
                />
                <Header headerType="user" />
                <audio style={{ visibility: 'hidden' }} controls src="" id="radiobuttons" />

                <iframe id="newEntertainmentPageBackground" className="iframe-position" title="VR background"
                    allowvr="yes" scrolling="no" src="https://angles-expo.firebaseapp.com/dekho/61caf907203874001227bb31/0/novr/none">
                </iframe>
                <Footer />
            </div>
        )
    }
}
