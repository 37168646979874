import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../helpers/footer/footer.css';
import './agenda-style.css';
import lobbyImg from '../images/medexIcon/RECEPTION-LOBBY-MED.png';
import feedbackImg from '../images/medexIcon/feedback.png';
import webinarImg from '../images/medexIcon/WEBINAR-MED.png';
import ArchiveImg from '../images/medexIcon/archive.png';
import displaygallaryImg from '../images/medexIcon/country-room.png';
import entertainmentImg from '../images/medexIcon/entertain-med.png';
import agendaImg from '../images/medexIcon/agenda.png';
import displayGallery from '../images/medexIcon/Display Gallery.png'
import facultyIcon from '../images/medexIcon/Faculty icon.png'
import logout from '../images/medexIcon/logout.png';
import nurseAgendaImg from '../images/agenda-chart-2.jpg'
import tutorialimg1 from '../images/desktopangels1.jpg';
import tutorialimg2 from '../images/mobangels2.jpg';
import playImg from '../images/medexIcon/play-med.png';
import pauseImg from '../images/medexIcon/pause-med.png';
import closeBtn from '../images/medexIcon/cross.png'
import videoPosterImg from '../images/emma-video-poster.png';
import menuImg from '../images/menu.png';
import closeImg from '../images/cross.png';
import breackPopupImg from '../images/Break Time Zone.png';
import ferasBaderImg from '../images/agenda/feras.png'
import magdyImg from '../images/agenda/magdy.png'
import shelleyImg from '../images/agenda/shelley.png'
import mariamAlQaseerImg from '../images/agenda/mariam.png'
import haniSabbourImg from '../images/agenda/sabbour.png'
import mehmetImg from '../images/agenda/mehmet.png'
import ahmedShawkyImg from '../images/agenda/shawky.png'
import michaelBohmImg from '../images/agenda/bohm.png'
import nikolausMarxImg from '../images/agenda/marx.png'
import abuHantashImg from '../images/agenda/hantash.png'
import sarahJarvisImg from '../images/agenda/sarah.png'
import bulentAltonImg from '../images/agenda/bulent.png'
import walidAlHabeebImg from '../images/agenda/walid.png';
import hadiSkouriImg from '../images/agenda/skouri.png'
import andrewCoatsImg from '../images/agenda/Andrew Coats.png';
import aliceChengImg from '../images/agenda/Alice Cheng.png';
import javedButlerImg from '../images/agenda/javed butler.png';
import chantelImg from '../images/agenda/Alice Cheng.png';
import perHenrikGroopImg from '../images/agenda/Per-henrik Groop.png';
import stefanAnkerImg from '../images/agenda/Stefan Anker.png';
import mcGuireImg from '../images/agenda/darren-k-mcguire.png';
import wannerImg from '../images/agenda/warner.png';
import peterLinImg from '../images/agenda/Peter Lin.png';
import ponikowskiImg from '../images/agenda/Piotr_Ponikowski_.png';
import dbFirebaseApp from '../functions/features/db-firebase-app';
import emma from '../images/medexIcon/emma.png'
import analyticsFirebase from '../functions/features/analytics-firebase-app';
import { Tooltip, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles'
const entranceVideo = 'https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/medex-2022/medexemma.mp4'
const expoName = 'medex2022'


const CustomTooltip = withStyles({
    tooltip: {
        color: "#EB1F4E",
        backgroundColor: "white",
        fontSize: "1em",
        border: "4px solid #EB1F4E",
        textAlign: "center",
        fontWeight: "bold",
        borderRadius: "8px",
        zIndex: "100",
        fontFamily: ["Poppins", "sans-serif"]
    },
    arrow: {
        "&:before": {
            border: "5px solid #EB1F4E",
            backgroundColor: "white"
        },
    }
})(Tooltip);

export default class Footer extends Component {

    state = {
        isBtnLive: false,
        isAccessBtnLive: true,
        isVideoStart: false
    }

    componentDidMount() {
        this.getUserDetails()
    }


    getUserDetails() {
        let self = this;
        const db = dbFirebaseApp.firestore();
        const userCollection = db.collection('MedexLive');
        // let ExistingUserQuery = new Promise((resolve, reject) => {
        // let profilePicArray = [];
        userCollection.where('expo', '==', expoName)
            .onSnapshot(function (querySnapshot) {
                querySnapshot.docChanges().forEach(function (change) {
                    if (change.type === "added" && change.type !== "modified") {
                        console.log("New city: ", change.doc.data());
                        if (change.doc.data().isLive === 'true') {
                            self.setState({ isBtnLive: true })
                        }
                        else if (change.doc.data().isLive === 'false') {
                            self.setState({ isBtnLive: false })
                        }

                        if (change.doc.data().isAccessLive === 'true') {
                            self.setState({ isAccessBtnLive: true })
                        }
                        else if (change.doc.data().isAccessLive === 'false') {
                            self.setState({ isAccessBtnLive: false })
                        }

                    }
                    if (change.type === "modified") {
                        console.log("Modified city: ", change.doc.data());
                        // if (change.type === "added" && change.type !== "modified") {
                        console.log("New city: ", change.doc.data());
                        if (change.doc.data().isLive === 'true') {
                            self.setState({ isBtnLive: true })
                        }
                        else if (change.doc.data().isLive === 'false') {
                            self.setState({ isBtnLive: false })
                        }

                        if (change.doc.data().isAccessLive === 'true') {
                            self.setState({ isAccessBtnLive: true })
                        }
                        else if (change.doc.data().isAccessLive === 'false') {
                            self.setState({ isAccessBtnLive: false })
                        }

                        // }
                    }

                    // console.log(doc.id, " => ", doc.data());

                });

                // resolve(profilePicArray);

            });

    }

    isMobileDevice = () => {
        if (navigator.userAgent.match(/Android/i) ||
            navigator.userAgent.match(/webOS/i) ||
            navigator.userAgent.match(/iPhone/i) ||
            navigator.userAgent.match(/iPad/i) ||
            navigator.userAgent.match(/iPod/i) ||
            navigator.userAgent.match(/BlackBerry/i) ||
            navigator.userAgent.match(/Windows Phone/i)) {
            return true;
        } else {
            return false;
        }
    }

    goToLobby = () => {
        let str = window.location.href;
        if (str.substring(str.lastIndexOf('/') + 1) !== 'entrance') {
            window.location.href = '/entrance'
        }
    }

    goToWebinar = () => {
        let str = window.location.href;
        if (str.substring(str.lastIndexOf('/') + 1) !== 'webinar-hall') {
            window.location.href = '/webinar-hall'
        }
    }

    goToBreakout = () => {
        let str = window.location.href;
        if (str.substring(str.lastIndexOf('/') + 1) !== 'breakout-page') {
            window.location.href = '/breakout-page'
        }
    }

    goToArchiveGallary = () => {
        let str = window.location.href;
        if (str.substring(str.lastIndexOf('/') + 1) !== 'archive') {
            window.location.href = '/archive'
        }
    }
    goToArchive = () => {
        let str = window.location.href;
        if (str.substring(str.lastIndexOf('/') + 1) !== 'archive') {
            window.location.href = '/archive'
        }
    }

    goToCountryRooms = () => {
        let str = window.location.href;
        if (str.substring(str.lastIndexOf('/') + 1) !== 'country') {
            window.location.href = '/country'
        }
    }

    goToBreakTimeZone = () => {
        let str = window.location.href;
        if (str.substring(str.lastIndexOf('/') + 1) !== 'entertainment-zone') {
            window.location.href = '/entertainment-zone'
        }
    }
    openDesPopup = () => {
        document.getElementById("breaktimezone").style.display = "block"
    }
    mouseLeave = () => {
        document.getElementById("breaktimezone").style.display = "none"
    }

    goToDisplayGallarySection = () => {
        let str = window.location.href;
        if (str.substring(str.lastIndexOf('/') + 1) !== 'display') {
            window.location.href = '/display'
        }
    }

    goToMedexFaculty = () => {
        let str = window.location.href;
        if (str.substring(str.lastIndexOf('/') + 1) !== 'medex-faculty') {
            window.location.href = '/medex-faculty'
        }
    }

    goToAgenda = () => {
        document.getElementById('location1').style.display = 'block'
    }

    goToInfo = () => {
        document.getElementById('info1').style.display = 'block'
    }

    goToFeedback = () => {
        window.open('https://boehringeringelheim.eu.qualtrics.com/jfe/form/SV_6EagGCEAT29mqpf?&event_ID=EM-0000113143&country_code=IMETA&event_date=2/1/2022&event_name=MEDEX_2022', '_blank')
    }

    userLogout = () => {


        console.warn('update last scene end time')
        const db = analyticsFirebase.firestore();
        db.collection("MEDEX2022LiveUsers").doc(localStorage.getItem('analyticsDocRef')).update({
            endTime: new Date().getTime(),
        })
            .then(function (docRef) {
                console.log("Document written with ID: ", localStorage.getItem('analyticsDocRef'));
                // currentDoc = docRef;

                localStorage.clear()
                window.location.href = "/"
            })
            .catch(function (error) {
                console.error("Error adding document: ", error);
            });
    }
    nurseAgenda = () => {
        document.getElementById('nurseAgenda').style.display = 'block'
        document.getElementById('agendaNurseBtn').setAttribute('class', 'btnBlueGreen active');
        document.getElementById('agendaNeurologistBtn').setAttribute('class', 'btnBlueGreen margin');
        document.getElementById('neurologistAgenda').style.display = 'none'
    }

    neurologistAgenda = () => {
        document.getElementById('neurologistAgenda').style.display = 'block'
        document.getElementById('agendaNeurologistBtn').setAttribute('class', 'btnBlueGreen margin active');
        document.getElementById('agendaNurseBtn').setAttribute('class', 'btnBlueGreen');
        document.getElementById('nurseAgenda').style.display = 'none'
    }

    closePopup = () => {
        document.getElementById('location1').style.display = 'none'
    }

    infoButtonclose = () => {
        document.getElementById('info1').style.display = 'none'
    }

    changeAgendaDate = (activeTabNum) => {
        console.warn('Should open tab', activeTabNum);
        for (let i = 1; i <= 12; i++) {
            document.getElementById('tab-' + i).style.display = 'none';

            if (document.getElementById('tab-' + i).classList.contains('tab-current')) {
                document.getElementById('tab-' + i).classList.remove('tab-current');
            }

            if (document.getElementById('btn-' + i).classList.contains('tab-active')) {
                document.getElementById('btn-' + i).classList.remove('tab-active');
            }
        }
        document.getElementById('tab-' + activeTabNum).style.display = 'block';
        document.getElementById('tab-' + activeTabNum).classList.add('tab-current');
        document.getElementById('btn-' + activeTabNum).classList.add('tab-active');
    }

    startVideo = () => {
        this.setState({ isVideoStart: true })
    }

    closeVideo = () => {
        this.setState({ isVideoStart: false })
    }


    playVideo1 = () => {
        if (document.getElementById('2dVideo1') &&
            document.getElementById('Pause2dVideo1') &&
            document.getElementById('Play2dVideo1')) {
            document.getElementById('2dVideo1').play();
            document.getElementById('2dVideo1').volume = 1;
            document.getElementById('Pause2dVideo1').style.display = 'block';
            document.getElementById('Play2dVideo1').style.display = 'none';
            localStorage.setItem('isVideoWatched', 'true')

        }
    }

    pauseVideo1 = () => {
        if (document.getElementById('2dVideo1') &&
            document.getElementById('Pause2dVideo1') &&
            document.getElementById('Play2dVideo1')) {
            document.getElementById('2dVideo1').pause();

            document.getElementById('2dVideo1').volume = 0;
            document.getElementById('Play2dVideo1').style.display = 'block'
            document.getElementById('Pause2dVideo1').style.display = 'none'
        }
    }
    openbottommenu = () => {
        document.getElementById("bottommenu").classList.remove("bottomaniamtion");
        document.getElementById("Mobmenu").style.display = "none";
        document.getElementById("Mobmenuclose").style.display = "flex";
        if(document.getElementById("quickAccessBtn2")){
            document.getElementById("quickAccessBtn2").style.bottom= "108px"
        }
        if(document.getElementById("quickAccessBtn")){
            document.getElementById("quickAccessBtn").style.bottom= "108px"
        }
        if ((document.getElementById("myScrollingChat"))) {

            document.getElementById("myScrollingChat").classList.remove("ishiddenchat");
        }
        if ((document.getElementById("Chat"))) {

            document.getElementById("Chat").classList.add("chaticonposition2");
        }

    }
    closebottommenu = () => {
        document.getElementById("bottommenu").classList.add("bottomaniamtion");
        document.getElementById("Mobmenu").style.display = "flex";
        document.getElementById("Mobmenuclose").style.display = "none";
        if(document.getElementById("quickAccessBtn2")){
            document.getElementById("quickAccessBtn2").style.bottom= "0px"
        }
        if(document.getElementById("quickAccessBtn")){
            document.getElementById("quickAccessBtn").style.bottom= "0px"
        }
        if ((document.getElementById("Chat"))) {

            document.getElementById("Chat").classList.remove("chaticonposition2");
        }
        
    }



    render() {
        return (
            <div>
                {this.state.isVideoStart &&
                    <div>
                        <div className="overlayVideo" id="VideoOverlay1" >
                            {/* <div id="countdown1" style={{ display: 'none', position: 'fixed', top: '30px', width: '70px', textAlign: 'center', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', padding: '3px 10px', border: '2px solid white', borderRadius: '5px' }}> <span></span> </div> */}

                        </div>


                        <div id="video-popup1">
                            <button id="closeBtn" className="closebtnposition" onClick={this.closeVideo} style={{ outline: 'none' }}>
                                <img src={closeBtn} alt="play" className="closebtnwidth"></img>
                            </button>
                            <p className="videoTitle">Guided Tour by Emma</p>
                            <video id="2dVideo1" className="videopopup" playsInline crossOrigin="anonymous" src={entranceVideo} poster={videoPosterImg} preload="auto" ></video>
                            <button id="Play2dVideo1" onClick={this.playVideo1} style={{ outline: 'none' }}>
                                <img src={playImg} alt="play" className="playbtnwidth"></img>
                                <p className="playvideotext">Play Guided Tour</p>
                            </button>
                            <button id="Pause2dVideo1" style={{ display: 'none', outline: 'none' }} onClick={this.pauseVideo1} >
                                <img src={pauseImg} alt="play" className="playbtnwidth"></img>
                                <p className="playvideotext">Pause Guided Tour</p>

                            </button>


                        </div>
                    </div>}
                {this.state.isBtnLive && localStorage.getItem('Location') !== 'webinar-hall' &&
                    <button id="quickAccessBtn" onClick={this.goToWebinar} className="btn-hover-shine btn-glow">Quick Access to Live Webinar</button>}
                {this.state.isAccessBtnLive  && localStorage.getItem('Location') !== 'archive-gallery' &&
                    <button id="quickAccessBtn2" onClick={this.goToArchive} className="btn-hover-shine btn-glow">Quick Access to Latest Recorded Session </button>}
                {/* //  } */}
                {/* AGENDA POPUP */}
                <div className="modal" id="location1" style={{ display: 'none' }} >
                    <div className="modal-dialog modal-dialog-centered modal-xl" >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title green-text" id="staticBackdropLabel">Agenda</h5>

                                <button type="button" className="close black-color" data-dismiss="modal" onClick={this.closePopup}>&times;</button>
                            </div>

                            <div className="agendaImage" id='nurseAgenda' style={{ display: 'none' }}>
                                <img src={nurseAgendaImg} style={{ width: '100%' }} alt="" />

                            </div>

                            <section id="agenda">
                                <div className="extra-container" style={{ display: 'none' }}>
                                    <div className="schedule-list__tittle">
                                        <h1>Conference Schedule</h1>
                                    </div>
                                </div>
                                <div class="container">
                                    <div class="schedule-tab">
                                        <a class="schedule-tab__item " id="btn-1" data-tab="tab-1" href="#tab-1" onClick={() => this.changeAgendaDate(1)}>
                                            <h2>Day 01</h2>
                                            <p>21/01/2022</p>
                                        </a>
                                        <a class="schedule-tab__item " id="btn-2" data-tab="tab-2" href="#tab-2" onClick={() => this.changeAgendaDate(2)}>
                                            <h2>Day 02</h2>
                                            <p>28/01/2022</p>
                                        </a>
                                        <a class="schedule-tab__item" id="btn-3" data-tab="tab-3" href="#tab-3" onClick={() => this.changeAgendaDate(3)}>
                                            <h2>Day 03</h2>
                                            <p>04/02/2022</p>
                                        </a>
                                        <a class="schedule-tab__item" id="btn-4" data-tab="tab-4" href="#tab-4" onClick={() => this.changeAgendaDate(4)}>
                                            <h2>Day 04</h2>
                                            <p>11/02/2022</p>
                                        </a>
                                        <a class="schedule-tab__item" id="btn-5" data-tab="tab-5" href="#tab-5" onClick={() => this.changeAgendaDate(5)}>
                                            <h2>Day 05</h2>
                                            <p>18/02/2022</p>
                                        </a>
                                        <a class="schedule-tab__item tab-active" id="btn-6" data-tab="tab-6" href="#tab-6" onClick={() => this.changeAgendaDate(6)}>
                                            <h2>Day 06</h2>
                                            <p>24/02/2022</p>
                                        </a>
                                        <a class="schedule-tab__item" id="btn-7" data-tab="tab-7" href="#tab-7" onClick={() => this.changeAgendaDate(7)}>
                                            <h2>Day 07</h2>
                                            <p>04/03/2022</p>
                                        </a>
                                        <a class="schedule-tab__item" id="btn-8" data-tab="tab-8" href="#tab-8" onClick={() => this.changeAgendaDate(8)}>
                                            <h2>Day 08</h2>
                                            <p>11/03/2022</p>
                                        </a>
                                        <a class="schedule-tab__item" id="btn-9" data-tab="tab-9" href="#tab-9" onClick={() => this.changeAgendaDate(9)}>
                                            <h2>Day 09</h2>
                                            <p>16/03/2022</p>
                                        </a>
                                        <a class="schedule-tab__item" id="btn-10" data-tab="tab-10" href="#tab-10" onClick={() => this.changeAgendaDate(10)}>
                                            <h2>Day 10</h2>
                                            <p>17/03/2022</p>
                                        </a>
                                        <a class="schedule-tab__item" id="btn-11" data-tab="tab-11" href="#tab-11" onClick={() => this.changeAgendaDate(11)}>
                                            <h2>Day 11</h2>
                                            <p>23/03/2022</p>
                                        </a>
                                        <a class="schedule-tab__item" id="btn-12" data-tab="tab-12" href="#tab-12" onClick={() => this.changeAgendaDate(12)}>
                                            <h2>Day 12</h2>
                                            <p>31/03/2022</p>
                                        </a>
                                    </div>

                                    <div id="tab-1" class="schedule-table">


                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>08:00PM – 08:10PM</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-7">
                                                <div class="schedule-table__event">
                                                    <h3>Welcome remarks </h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        {/* <img src={andrewCoatsImg} alt="Andrew Coats" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Andrew Coats</p>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>08:10PM – 08:40PM</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-7">
                                                <div class="schedule-table__event">
                                                    <h3>HFrEF; Defining the global Challenges</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={stefanAnkerImg} alt="Stefan Anker" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Stefan Anker </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>08:40PM – 09:10PM </h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-7">
                                                <div class="schedule-table__event">
                                                    <h3>HFpEF; Defining the global Challenges</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={javedButlerImg} alt="Javed Butler" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Javed Butler </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-4">
                                                <div class="schedule-table__time">
                                                    <h3>09:10PM – 09:40PM</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-8">
                                                <div class="schedule-table__event">
                                                    <h3>HF, The Burden in META Region</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={walidAlHabeebImg} alt="Waleed AlHabeeb" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Waleed AlHabeeb</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-4">
                                                <div class="schedule-table__time">
                                                    <h3>09:40PM – 10:10PM</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-8">
                                                <div class="schedule-table__event">
                                                    <h3>Q&A</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">

                                                        <div class="schedule-table__speaker__name">
                                                            <p>All Faculty</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                    <div id="tab-2" class="schedule-table">


                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>08:00PM – 08:10PM</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-7">
                                                <div class="schedule-table__event">
                                                    <h3>Welcome remarks </h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        {/* <img src={javedButlerImg} alt="Javed Butler" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Javed Butler</p>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>08:10PM – 09:40PM</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-7">
                                                <div class="schedule-table__event">
                                                    <h3>Meet the Experts- Prevention of HF in High-Risk groups, how can we intervene
                                                        earlier?</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={shelleyImg} alt="Shelley Zieroth" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Shelley Zieroth</p>
                                                        </div>
                                                    </div>
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={aliceChengImg} alt="Alice Cheng" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Alice Cheng</p>
                                                        </div>
                                                    </div>
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={wannerImg} alt="Christoph Wanner" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Christoph Wanner</p>
                                                        </div>
                                                    </div>
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={haniSabbourImg} alt="Hani Sabbour" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Hani Sabbour</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="tab-3" class="schedule-table">


                                        <div class="row ">
                                            <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>08:00PM – 08:10PM</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-7">
                                                <div class="schedule-table__event">
                                                    <h3>Welcome remarks</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        {/* <img src={javedButlerImg} alt="Javed Butler" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Javed Butler</p>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>08:10PM – 08:40PM</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-7">
                                                <div class="schedule-table__event">
                                                    <h3>HFrEF Vs. HFmrEF Vs. HFpEF - Solving the puzzle</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={ponikowskiImg} alt="Piotr Ponikowski" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Piotr Ponikowski</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>08:40PM – 09:10PM</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-7">
                                                <div class="schedule-table__event">
                                                    <h3>Excellence in diagnosis of HF</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={mcGuireImg} alt="Darren McGuire" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Darren McGuire</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>09:10PM – 09:40PM</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-7">
                                                <div class="schedule-table__event">
                                                    <h3>Optimization of Diagnostic means in META</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={hadiSkouriImg} alt="Hadi Skouri" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Hadi Skouri</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>09:40PM – 10:10PM</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-7">
                                                <div class="schedule-table__event">
                                                    <h3>Q&A</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <div class="schedule-table__speaker__name">
                                                            <p>All Faculty</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div id="tab-4" class="schedule-table">


                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>08:00PM – 08:10PM</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-7">
                                                <div class="schedule-table__event">
                                                    <h3>Welcome remarks</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        {/* <img src={mcGuireImg} alt="Darren Maguire" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Darren McGuire</p>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>08:10PM –10:10PM</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-7">
                                                <div class="schedule-table__event">
                                                    <h3>Meet the Experts-Guidelines for management are evolving rapidly, approach
                                                        the change in
                                                        clinical practice</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={chantelImg} alt="Alice Cheng" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Alice Cheng</p>
                                                        </div>
                                                    </div>
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={perHenrikGroopImg} alt="Groop Per-Henrik" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Groop Per-Henrik</p>
                                                        </div>
                                                    </div>
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={javedButlerImg} alt="Javed Butler" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Javed Butler</p>
                                                        </div>
                                                    </div>
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={ferasBaderImg} alt="Feras Bader" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Feras Bader</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div id="tab-5" class="schedule-table">


                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>08:00PM – 08:10PM</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-7">
                                                <div class="schedule-table__event">
                                                    <h3>Welcome remarks</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        {/* <img src={zannadImg} alt="Faiez Zannad" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Faiez Zannad</p>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>08:10PM – 08:40PM</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-7">
                                                <div class="schedule-table__event">
                                                    <h3>SGLT2 Inhibitors in HFrEF - Overview</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={mcGuireImg} alt="Darren McGuire" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Darren McGuire</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>08:40PM – 09:10PM</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-7">
                                                <div class="schedule-table__event">
                                                    <h3>Treatment of HFpEF- New hope</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={javedButlerImg} alt="Javed Butler" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Javed Butler</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-4">
                                                <div class="schedule-table__time">
                                                    <h3>09:10PM – 09:40PM</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-8">
                                                <div class="schedule-table__event">
                                                    <h3>Management of Heart Failure in low resources setup</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={magdyImg} alt='Magdy Abdelhamid'></img>
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Magdy Abdelhamid </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-4">
                                                <div class="schedule-table__time">
                                                    <h3>09:40PM – 10:10PM</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-8">
                                                <div class="schedule-table__event">
                                                    <h3>Q&A</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">

                                                        <div class="schedule-table__speaker__name">
                                                            <p>All Faculty </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div id="tab-6" class="schedule-table tab-current">


                                        <div class="row ">
                                            <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>08:00PM – 08:10PM </h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-7">
                                                <div class="schedule-table__event">
                                                    <h3>Welcome remarks</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        {/* <img src={melanieImg} alt="Melanie Davis" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Melanie Davies
                                    </p>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-4">
                                                <div class="schedule-table__time">
                                                    <h3>08:10PM – 09:40PM</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-8">
                                                <div class="schedule-table__event">
                                                    <h3>Meet the Experts- New horizon in management of HF- The multidisciplinary
                                                        Approach</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={javedButlerImg} alt='Javed Butler'></img>
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Javed Butler </p>
                                                        </div>
                                                    </div>
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={peterLinImg} alt='Peter Lin'></img>
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Peter Lin </p>
                                                        </div>
                                                    </div>
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={wannerImg} alt='Christoph Wanner'></img>
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Christoph Wanner </p>
                                                        </div>
                                                    </div>
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={ahmedShawkyImg} alt='Ahmed Shawky'></img>
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Ahmed Shawky </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div id="tab-7" class="schedule-table">


                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>08:00PM – 08:10PM</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-7">
                                                <div class="schedule-table__event">
                                                    <h3>Welcome remarks</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        {/* <img src={sanjayKaulImg} alt="Sanjay Kaul" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Sanjay Kaul</p>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>08:10PM – 08:40PM</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-7">
                                                <div class="schedule-table__event">
                                                    <h3>EMPEROR Reduced in Depth - sub cohort analyses</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={nikolausMarxImg} alt="Nikolaus Marx" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Nikolaus Marx </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>08:40PM – 09:10PM</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-7">
                                                <div class="schedule-table__event">
                                                    <h3>EMPEROR Preserved in Depth - clinical utility</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={shelleyImg} alt="Shelley Zieroth" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Shelley Zieroth</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>09:10PM – 09:40PM</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-7">
                                                <div class="schedule-table__event">
                                                    <h3>EMPULSE study, Initiation in hospital - urgent need</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={abuHantashImg} alt="Hadi Abu-Hantash" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Hadi Abu-Hantash</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-4">
                                                <div class="schedule-table__time">
                                                    <h3>09:40PM – 10:10PM</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-8">
                                                <div class="schedule-table__event">
                                                    <h3>Q&A</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <div class="schedule-table__speaker__name">
                                                            <p>All Faculty </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div id="tab-8" class="schedule-table">

                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>08:00PM – 08:10PM</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-7">
                                                <div class="schedule-table__event">
                                                    <h3>Welcome remarks</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        {/* <img src={stefanAnkerImg} alt="Stefan Anker" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Stefan Anker</p>

                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>08:10PM – 09:40PM</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-7">
                                                <div class="schedule-table__event">
                                                    <h3>Meet the Experts- Early intervention to Prevent Heart Failure complications
                                                        in High-risk
                                                        patients</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={shelleyImg} alt="Shelley Zieroth" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Shelley Zieroth</p>
                                                        </div>
                                                    </div>
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={aliceChengImg} alt="Alice Cheng" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Alice Cheng </p>
                                                        </div>
                                                    </div>
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={sarahJarvisImg} alt="Sarah Jarvis" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Sarah Jarvis</p>
                                                        </div>
                                                    </div>
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={bulentAltonImg} alt="Bulent Altun" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Bulent Altun</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div id="tab-9" class="schedule-table">
                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>08:00PM – 08:10PM</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-7">
                                                <div class="schedule-table__event">
                                                    <h3>Welcome remarks</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        {/* <img src={stefanAnkerImg} alt="Stefan Anker" />
                                                            <div class="schedule-table__speaker__name">
                                                                <p>Stefan Anker</p>

                                                            </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="row ">
                                            <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>08:10PM – 10:10PM</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-7">
                                                <div class="schedule-table__event">
                                                    <h3>Session 1: Establishing Center of Excellence in Heart Failure - Virtual
                                                        Preceptorship</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Broadcast from center of Excellence in US</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="tab-10" class="schedule-table">
                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>08:00PM – 08:10PM</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-7">
                                                <div class="schedule-table__event">
                                                    <h3>Welcome remarks</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        {/* <img src={stefanAnkerImg} alt="Stefan Anker" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Stefan Anker</p>

                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>08:10PM – 10:10PM</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-7">
                                                <div class="schedule-table__event">
                                                    <h3>Session 2 - Establishing Heart Failure Clinic and center of Excellence --
                                                        Virtual Preceptorship</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">

                                                        <div class="schedule-table__speaker__name">
                                                            <p>Broadcast from center of Excellence in US</p>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="tab-11" class="schedule-table">
                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>08:00PM – 08:10PM</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-7">
                                                <div class="schedule-table__event">
                                                    <h3>Welcome remarks</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        {/* <img src={stefanAnkerImg} alt="Stefan Anker" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Stefan Anker</p>

                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>08:10PM – 08:40PM</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-7">
                                                <div class="schedule-table__event">
                                                    <h3>SGLT2 inhibitors - Cardio Renal Link & outcomes</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={mcGuireImg} alt="Darren McGuire" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Darren McGuire</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>008:40PM – 09:10PM</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-7">
                                                <div class="schedule-table__event">
                                                    <h3>The foundational approach - How should we start?</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={javedButlerImg} alt="Steven Kahn " />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Javed Butler</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>09:10PM – 09:40PM </h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-7">
                                                <div class="schedule-table__event">
                                                    <h3>Managing Heart Failure in COVID times</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={mariamAlQaseerImg} alt="Maryam AlQasser" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Maryam AlQasser</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-4">
                                                <div class="schedule-table__time">
                                                    <h3>09:40PM – 10:10PM</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-8">
                                                <div class="schedule-table__event">
                                                    <h3>Q&A</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <div class="schedule-table__speaker__name">
                                                            <p>All Faculty </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div id="tab-12" class="schedule-table">
                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>08:00PM – 08:10PM</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-7">
                                                <div class="schedule-table__event">
                                                    <h3>Welcome remarks</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        {/* <img src={stefanAnkerImg} alt="Stefan Anker" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Stefan Anker</p>

                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>08:10PM – 08:40PM </h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-7">
                                                <div class="schedule-table__event">
                                                    <h3>Challenges in management - HFrEF clinical Case study</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={michaelBohmImg} alt="Michael Böhm" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Michael Böhm</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>08:40PM – 09:10PM</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-7">
                                                <div class="schedule-table__event">
                                                    <h3>Challenges in management - HFpEF clinical Case study</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={andrewCoatsImg} alt="Andrew Coats" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Andrew Coats</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-5">
                                                <div class="schedule-table__time">
                                                    <h3>09:10PM – 09:40PM</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-7">
                                                <div class="schedule-table__event">
                                                    <h3>Challenges in management - Acute HF clinical Case study</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <img src={mehmetImg} alt="Mehmet Birhan Yilmaz" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Mehmet Birhan Yilmaz</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row wow flipInX">
                                            <div class="col-lg-3 col-sm-4">
                                                <div class="schedule-table__time">
                                                    <h3>09:40PM – 10:10PM</h3>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-8">
                                                <div class="schedule-table__event">
                                                    <h3>Q&A</h3>

                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-sm-12">
                                                <div class="schedule-table__speaker">
                                                    <div class="schedule-table__speaker__info">
                                                        <div class="schedule-table__speaker__name">
                                                            <p>All Faculty </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </section>
                            {/* <div className="agendaImage" id='neurologistAgenda' style={{ display: 'none' }}>
                                <img src={neurologistsAgendaImg} style={{ width: '100%' }} />

                            </div> */}
                        </div>
                    </div>
                </div>
                {/* AGENDA POPUP */}



                {/* INFO POPUP */}
                <div className="modal" id="info1" >
                    <div className="modal-dialog modal-dialog-centered" >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title green-text" id="staticBackdropLabel">Info</h5>

                                <button type="button" className="close black-color" data-dismiss="modal" onClick={() => this.infoButtonclose()}>&times;</button>
                            </div>
                            <div className="modal-body">

                                {
                                    (this.isMobileDevice()) ?
                                        <img src={tutorialimg2} style={{ width: '100%' }} alt='' />
                                        :
                                        <img src={tutorialimg1} style={{ width: '100%' }} alt='' />
                                }


                            </div>
                        </div>
                    </div>
                </div>
                {/* INFO POPUP */}
                <div className="mobmenu" id="Mobmenu">
                    <img src={menuImg} className="widthmenuicon" alt="" onClick={this.openbottommenu} />
                </div>
                <div className="mobmenuclose" id="Mobmenuclose">
                    <img src={closeImg} className="widthmenuicon" alt="" onClick={this.closebottommenu} />
                </div>

                <div style={{ display: "none", left: "49.4vw", bottom: "21vh", zIndex: "10", position: "absolute" }} id="breaktimezone">
                    <img src={breackPopupImg} className="widthicon1" alt="Log out" style={{ width: "17vw" }} />
                </div>
                <div id="bottommenu" className="bottomaniamtion">
                    <ul className="list-unstyled  flexicon scrolltypewhite">
                        <li className="flexcolumn grayImage" 
                        // onClick={this.goToLobby}
                        >
                            <CustomTooltip title="Reception Area" arrow style={{ padding: "0px" }} placement="top" enterTouchDelay={0}>
                                <IconButton>
                                    <img src={lobbyImg} className="widthicon" alt="Webinar" />
                                </IconButton>
                            </CustomTooltip>
                            <span className="cenetrtext bottomtext">Lobby</span>
                        </li>

                        <li className="flexcolumn grayImage" 
                        // onClick={this.goToWebinar}
                        >
                            <CustomTooltip title="Live session area" arrow style={{ padding: "0px" }} placement="top" enterTouchDelay={0}>
                                <IconButton>
                                    <img src={webinarImg} className="widthicon" alt="Webinar" />
                                </IconButton>
                            </CustomTooltip>
                            <span className="cenetrtext bottomtext">Webinar Hall</span>
                        </li>
                        <li className="flexcolumn grayImage" 
                        // onClick={this.goToMedexFaculty}
                        >
                            <CustomTooltip title="International Experts biographies" arrow style={{ padding: "0px" }} placement="top" enterTouchDelay={0}>
                                <IconButton>
                                    <img src={facultyIcon} className="widthicon" alt="Gallary" />
                                </IconButton>
                            </CustomTooltip>
                            <span className="cenetrtext bottomtext">Medex Faculty</span>
                        </li>
                        <li className="flexcolumn grayImage" 
                        // onClick={this.goToCountryRooms}
                        >
                            <CustomTooltip title="Crafted Experiences of Different Countries participating in MEDEX" arrow style={{ padding: "0px" }} placement="top" enterTouchDelay={0}>
                                <IconButton>
                                    <img src={displaygallaryImg} className="widthicon" alt="Gallary" />
                                </IconButton>
                            </CustomTooltip>
                            <span className="cenetrtext bottomtext">Country Experience</span>
                        </li>
                        <li className="flexcolumn grayImage" 
                        // onClick={this.goToDisplayGallarySection}
                        >
                            <CustomTooltip title="Educational videos and interesting scientific Media content" arrow style={{ padding: "0px" }} placement="top" enterTouchDelay={0}>
                                <IconButton>
                                    <img src={displayGallery} className="widthicon" alt="Gallary" />
                                </IconButton>
                            </CustomTooltip>
                            <span className="cenetrtext bottomtext">BI scientific Media library</span>
                        </li>

                        <li id="expohall" className="flexcolumn" 
                        onClick={this.goToArchiveGallary}
                        >
                            <CustomTooltip title="Recorded Sessions from the program you may have missed" arrow style={{ padding: "0px" }} placement="top" enterTouchDelay={0}>
                                <IconButton>
                                    <img src={ArchiveImg} className="widthicon" alt="Gallary" />
                                </IconButton>
                            </CustomTooltip>
                            <span className="cenetrtext bottomtext">On demand sessions hub</span>
                        </li>
                        <li className="flexcolumn grayImage" 
                        // onClick={this.goToBreakTimeZone}
                        >
                            <CustomTooltip title="Entertaining Activities while on the platform" arrow style={{ padding: "0px" }} placement="top" enterTouchDelay={0}>
                                <IconButton>
                                    <img src={entertainmentImg} className="widthicon" id="breakTime" data-toggle="modal" alt="Entertainment"
                                        data-target="#filterpopup" />
                                </IconButton>
                            </CustomTooltip>
                            <span className="cenetrtext bottomtext">Break Time Zone</span>
                        </li>
                        <li className="flexcolumn grayImage" 
                        // onClick={this.goToAgenda}
                         >
                            <CustomTooltip title="Detailed Flow of the event" arrow style={{ padding: "0px" }} placement="top" enterTouchDelay={0}>
                                <IconButton>
                                    <img src={agendaImg} className="widthicon" data-target="#location1" data-toggle="modal" alt="Location" />
                                </IconButton>
                            </CustomTooltip>
                            <span className="cenetrtext bottomtext">Agenda</span>

                        </li>

                        <li className="flexcolumn grayImage" 
                        // onClick={this.goToFeedback}
                         >
                            <img src={feedbackImg} className="widthicon" alt="Feedback" />
                            <span className="cenetrtext bottomtext">One Minute Feedback</span>

                        </li>
                        <li className="flexcolumn grayImage">
                            <CustomTooltip title="Tour the platform with Emma" arrow style={{ padding: "0px" }} placement="top" enterTouchDelay={0}>
                                <IconButton>
                                    <img src={emma} className="widthicon" alt="Log out" 
                                    // onClick={this.startVideo}
                                     />
                                </IconButton>
                            </CustomTooltip>
                            <span className="cenetrtext bottomtext">Guided Tour</span>

                        </li>
                        <li className="flexcolumn">
                            <img src={logout} className="widthicon" alt="Log out" 
                            onClick={this.userLogout}
                             />
                            <span className="cenetrtext bottomtext">Log Out</span>

                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}
