/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import loginFirebaseApp from '../functions/features/login-firebase-app'
import 'bootstrap/dist/css/bootstrap.min.css'
import chromeimg from '../images/aaa.png'
import walidAlHabeebImg from '../images/agenda/walid.png'
import hadiSkouriImg from '../images/agenda/skouri.png'
import shelleyImg from '../images/agenda/shelley.png'
import mariamAlQaseerImg from '../images/agenda/mariam.png'
import haniSabbourImg from '../images/agenda/sabbour.png'
import mehmetImg from '../images/agenda/mehmet.png'
import ahmedShawkyImg from '../images/agenda/shawky.png'
import michaelBohmImg from '../images/agenda/bohm.png'
import nikolausMarxImg from '../images/agenda/marx.png'
import abuHantashImg from '../images/agenda/hantash.png'
import sarahJarvisImg from '../images/agenda/sarah.png'
import bulentAltonImg from '../images/agenda/bulent.png'
import andrewCoatsImg from '../images/agenda/Andrew Coats.png'
import aliceChengImg from '../images/agenda/Alice Cheng.png'
import javedButlerImg from '../images/agenda/javed butler.png'
import chantelImg from '../images/agenda/Alice Cheng.png'
import perHenrikGroopImg from '../images/agenda/Per-henrik Groop.png'
import magdyImg from '../images/agenda/magdy.png'
import stefanAnkerImg from '../images/agenda/Stefan Anker.png'
import mcGuireImg from '../images/agenda/darren-k-mcguire.png'
import ferasBaderImg from '../images/agenda/feras.png'
import wannerImg from '../images/agenda/warner.png'
import peterLinImg from '../images/agenda/Peter Lin.png'
import ponikowskiImg from '../images/agenda/Piotr_Ponikowski_.png'
import nurseAgendaImg from '../images/agenda-chart-2.jpg'
import backVideo from '../images/medex-c8.mp4'
import backMovVideo from '../images/medex-c7.mp4'

import './login-page.css'
import firebaseOther from '../functions/features/firebase-app'
import { analyticsFunction } from '../functions/helpers/analytics-function'
import axios from 'axios'
const expoName = 'medex2022'
let uid = ''

export default class LoginPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      loginEmail: '',
      loginPassword: '',
      isLoginFormVisible: true,
      registrationObj: {
        name: '',
        email: '',
        password: '',
        country: '',
        countryName: '',
        mobileNumber: '',
        designation: '',
        speciality: ''
      }
    }
    this.loginButton = this.loginButton.bind(this)
    this.authCheck = this.authCheck.bind(this)
    this.getUserDetails = this.getUserDetails.bind(this)
    this.handleEmailKeyPress = this.handleEmailKeyPress.bind(this)
    this.handlePasswordKeyPress = this.handlePasswordKeyPress.bind(this)
    this.handleLoginEmailChange = this.handleLoginEmailChange.bind(this)
    this.handleLoginPasswordChange = this.handleLoginPasswordChange.bind(this)
    this.handleCHangeAllRegistrationField =
      this.handleCHangeAllRegistrationField.bind(this)
  }

  componentDidMount () {
    if (
      localStorage.getItem('userRole') === 'user' &&
      localStorage.getItem('uid') !== ''
    ) {
      // window.location.href = '/entrance'
      window.location.href = '/archive'
    } else if (
      localStorage.getItem('userRole') === 'speaker' &&
      localStorage.getItem('uid') !== ''
    ) {
      window.location.href = '/speaker-webinar'
    } else if (
      localStorage.getItem('userRole') === 'moderator' &&
      localStorage.getItem('uid') !== ''
    ) {
      window.location.href = '/moderator-webinar'
    }
  }

  loginButton (e) {
    e.preventDefault()
    var patternEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}$/
    if (this.state.loginEmail === '') {
      alert('Please enter Email Id')
    } else if (!patternEmail.test(this.state.loginEmail)) {
      alert('Please enter valid Email Id')
    } else if (
      this.state.loginEmail === 'ibrahim.adaro@boehringer-ingelheim.com'
    ) {
      alert('Please enter valid password')
    } else if (this.state.loginEmail === 'yaw.asare@boehringer-ingelheim.com') {
      alert('Please enter valid password')
    } else {
      this.authCheck()
    }
  }

  handleEmailKeyPress (e) {
    e = e || window.event
    if (e.keyCode === 13) {
      document.getElementById('login-password-txt').focus()
    }
  }

  handlePasswordKeyPress (e) {
    e = e || window.event
    if (e.keyCode === 13) {
      document.getElementById('login-btn').click()
    }
  }

  handleLoginEmailChange (e) {
    e.preventDefault()
    this.setState({ loginEmail: e.target.value })
  }

  handleLoginPasswordChange (e) {
    e.preventDefault()
    this.setState({ loginPassword: e.target.value })
  }

  handleCHangeAllRegistrationField (e) {
    this.setState({
      [e.target.name]: [e.target.value]
    })
  }

  authCheck () {
    let loginEmailId = this.state.loginEmail.toLowerCase()
    const loginPassword = this.state.loginPassword
    loginEmailId = loginEmailId.replace(/ /g, '')
    loginEmailId = loginEmailId.toLowerCase()
    this.getUserDetails(loginEmailId, loginPassword)
  }

  handleSignUp = () => {
    console.log('this.state.registrationObj', this.state.registrationObj)
    if (this.state.registrationObj.name === '') {
      alert('Please enter your name')
    } else if (this.state.registrationObj.email === '') {
      alert('Please enter your email')
    } else if (this.state.registrationObj.password === '') {
      alert('Please enter your password')
    } else if (this.state.registrationObj.country === '') {
      alert('Please select a country')
    } else if (this.state.registrationObj.mobileNumber === '') {
      alert('Please enter your mobile number')
    } else if (this.state.registrationObj.speciality === '') {
      alert('Please select your speciality')
    } else {
      // registeration code here

      localStorage.setItem('name', this.state.registrationObj.name)
      localStorage.setItem('email', this.state.registrationObj.email)
      localStorage.setItem('country', this.state.registrationObj.country)
      localStorage.setItem(
        'countryName',
        this.state.registrationObj.countryName
      )
      localStorage.setItem('mobile', this.state.registrationObj.mobileNumber)
      localStorage.setItem(
        'designation',
        this.state.registrationObj.designation
      )
      //   localStorage.setItem('hospital', this.state.registrationObj.)

      this.checkForExistingUserforcreateAccount(
        this.state.registrationObj.email,
        this.state.registrationObj.password
      )
    }
  }

  checkForExistingUserforcreateAccount (email, secretKey) {
    let registerData = new Promise((resolve, reject) => {
      let db = loginFirebaseApp.firestore()
      db.collection('Users')
        .where('email', '==', email)
        .where('expo', '==', expoName)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            // doc.data() is never undefined for query doc snapshots
            console.log(doc.id, ' => ', doc.data())
            resolve(doc.id)
          })
          resolve('Not Found')
        })
        .catch(function (error) {
          console.log('Error getting documents: ', error)
          reject(error)
        })
    })
    registerData
      .then(result => {
        if (result === 'Not Found') {
          this.registerNewUser()
        } else {
          alert('You are already registered')
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  registerNewUser () {
    this.makeid()
    localStorage.setItem('uid', uid)

    console.log('first', {
      name: this.state.registrationObj.name,
      email: this.state.registrationObj.email,
      secretKey: this.state.registrationObj.password,
      country: this.state.registrationObj.country,
      countryName: this.state.registrationObj.countryName,
      mobileNumber: this.state.registrationObj.mobileNumber,
      uid: uid,
      designation: this.state.registrationObj.designation,
      speciality: this.state.registrationObj.speciality,
      expo: expoName
    })

    let registerQuery = new Promise((resolve, reject) => {
      let db = loginFirebaseApp.firestore()
      db.collection('Users')
        .add({
          name: this.state.registrationObj.name,
          email: this.state.registrationObj.email,
          secretKey: this.state.registrationObj.password,
          country: this.state.registrationObj.country,
          countryName: this.state.registrationObj.countryName,
          mobileNumber: this.state.registrationObj.mobileNumber,
          uid: uid,
          designation: this.state.registrationObj.designation,
          speciality: this.state.registrationObj.speciality,
          expo: expoName
        })
        .then(function (docRef) {
          console.log('Document written with ID: ', docRef.id)
          //   analyticsFunction()

          resolve(docRef.id)
        })
        .catch(function (error) {
          console.error('Error adding document: ', error)
          reject(error)
        })
    })
    registerQuery
      .then(result => {
        console.warn('register successful', result)
        // this.sendEmail()

        this.setState({
          isLoginFormVisible: true
        })
      })
      .catch(error => {
        console.error(error)
      })
  }

  sendEmail () {
    const self = this
    const regMessage =
      'Dear Dr./Prof. ' +
      this.state.registrationObj.name +
      ',' +
      '<br/>' +
      `<p>Thank you for registering with us, for MEDEX 2022 - Heart Failure Chapter.</p>
    
    <p>To join the event please visit <a href="https://medexmeta2022.com/">https://medexmeta2022.com/</a></p>
     <p>From 21st January 2022 to 31st March 2022. <a href="https://calendar.google.com/calendar/u/0/r/eventedit?dates=20220121T160000Z/20220331T240000Z&text=MEDEX+Conference+2022&location=https://medexmeta2022.com/&details=Get+access+to+the+latest+in+T2DM+and+related+risks,+only+at+MEDEX+Conference+2022%20-%20Heart%20Failure%20Chapter&sf=true">Click Here to Add to Calender</a></p>
    <br/>
    <p>As per your registration form, your login credentials are - </p>
    <p>Login ID : ${this.state.registrationObj.email}</p>
    <p>Password : ${this.state.registrationObj.password}</p>
    <br/>
    <b>If you have any queries please reply to this mail.</b>
    <br/>
    <p>Thank you once again and have a wonderful day! </p>
    <br/>
    <p  style="font-size: 10px;">Disclaimer : This email message was sent to you because you have registered to attend MEDEX 2022 - Heart Failure Chapter.</p>
    <br/>`
    // <p style="font-size: 10px;">© 2020 Boehringer Ingelheim International GmbH. All rights reserved. All reasonable efforts have been made to include accurate and up-to-date information on this email. However, the Boehringer Ingelheim group of companies and/or its respective suppliers make no representations about the suitability of the information contained in the documents and related graphics published on this site for any purpose. All such documents and related graphics are provided "as is" without warranty of any kind. Documents on pharmaceutical products are not intended to be used as an alternative to consulting with a health care professional or other qualified professional. If you should seek advice on a specific health problem please contact health care professionals.</p>
    // <br/>`
    var tempEmail = this.state.registrationObj.email
    tempEmail = tempEmail.toLowerCase()
    // console.log("Email:: ", tempEmail);
    var obj = {
      useremail: tempEmail,
      message: regMessage,
      SiteType: 'medex2022',
      subject: 'You are registered for MEDEX 2022 - Heart Failure Chapter'
    }
    //http://localhost:5000/sendtoemail-3610d/us-central1/expoRegistration
    axios
      .post(
        'https://us-central1-sendtoemail-3610d.cloudfunctions.net/anglesSendemail',
        obj,
        {
          headers: {
            // 'x-access-token': token
          }
        }
      )
      .then(function () {
        // window.location.href = 'website.html'
        console.log('Done', tempEmail)
        // InC
        self.incrementVisitorCounter()
      })
      .catch(error => {
        console.log('Not DOne')
      })
  }

  incrementVisitorCounter () {
    var visitorCountRef = loginFirebaseApp
      .firestore()
      .collection('totalVisitor')
      .doc('YXRBwpt1iN8uSRY9e7eR')

    // Atomically increment the counter by 1.
    // visitorCountRef.update({
    //         counter: firebase.firestore.counter.increment(1)
    // });
    let getVisitor = new Promise((resolve, reject) => {
      visitorCountRef
        .get()
        .then(function (doc) {
          resolve(doc.data())
        })
        .catch(error => {
          reject(error)
        })
    })
    getVisitor
      .then(result => {
        let existingCounter = result.counter
        let newCount = existingCounter + 1
        visitorCountRef
          .update({
            counter: newCount
          })
          .then(function () {
            console.warn('Counter successfully updated!')
            // document.getElementById('registration-form').style.display = 'none';
            document.getElementById('register-successful').style.display =
              'block'
            document.getElementById('loader-signup').style.display = 'none'
            document.getElementById('signup-name').style.display = 'block'
            setTimeout(() => {
              // window.location.href = 'website.html';
              window.location.href = 'home.html'
            }, 2000)
          })
          .catch(error => {
            console.error('Increment counter error', error)
          })
      })
      .catch(error => {
        console.error('Counter get error', error)
      })
  }

  makeid () {
    var result = ''
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for (var i = 0; i < 15; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    uid = result
    return result
  }

  getUserDetails (emailId, secretKey) {
    const db = loginFirebaseApp.firestore()
    const userCollection = db.collection('Users')
    let ExistingUserQuery = new Promise((resolve, reject) => {
      userCollection
        .where('expo', '==', expoName)
        .where('email', '==', emailId)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            let myData = doc.data()
            myData.id = doc.id
            resolve(myData)
          })
          resolve('Not Found')
        })
        .catch(function (error) {
          reject(error)
        })
    })
    ExistingUserQuery.then(result => {
      if (result === 'Not Found') {
        alert('User details not found')
        document.getElementById('login-btn').removeAttribute('disabled')
      } else {
        if (result.email === emailId && result.secretKey === secretKey) {
          localStorage.setItem('name', result.name)
          localStorage.setItem('country', result.countryName)
          localStorage.setItem('uid', result.id)
          localStorage.setItem('email', result.email)
          localStorage.setItem('mobile', result.mobileNumber)
          localStorage.setItem('designation', result.designation)
          localStorage.setItem('speciality', result.speciality)
          localStorage.setItem('roomName', 'webinar')
          if (result.userRole) {
            localStorage.setItem('userRole', result.userRole)
          } else {
            localStorage.setItem('userRole', 'user')
          }
          if (result) {
            console.warn('user is ', result)
            if (localStorage.getItem('userRole') === 'speaker') {
              window.location.href = '/speaker-webinar'
            } else if (localStorage.getItem('userRole') === 'moderator') {
              window.location.href = '/moderator-webinar'
            } else if (localStorage.getItem('userRole') === 'user') {
              window.location.href = '/archive'
              // window.location.href = "/entrance";
            } else {
              // window.location.href = "/entrance";
              window.location.href = '/archive'
            }
          } else {
            console.warn('user not found')
            // User is signed out
          }
        } else {
          alert(
            'Your password is incorrect. Please enter your registered email/password combination'
          )
        }
      }
    }).catch(error => {
      console.error('Already existing user check error', error)
    })
  }
  goToAgenda = () => {
    document.getElementById('location1').style.display = 'block'
  }
  closePopup = () => {
    document.getElementById('location1').style.display = 'none'
  }

  changeAgendaDate = activeTabNum => {
    console.warn('Should open tab', activeTabNum)
    for (let i = 1; i <= 12; i++) {
      document.getElementById('tab-' + i).style.display = 'none'

      if (
        document.getElementById('tab-' + i).classList.contains('tab-current')
      ) {
        document.getElementById('tab-' + i).classList.remove('tab-current')
      }

      if (
        document.getElementById('btn-' + i).classList.contains('tab-active')
      ) {
        document.getElementById('btn-' + i).classList.remove('tab-active')
      }
    }
    document.getElementById('tab-' + activeTabNum).style.display = 'block'
    document.getElementById('tab-' + activeTabNum).classList.add('tab-current')
    document.getElementById('btn-' + activeTabNum).classList.add('tab-active')
  }

  isMobileDevice = () => {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true
    } else {
      return false
    }
  }
  render () {
    return (
      <div>
        <div className='logoposition'>
          {/* <img src={angleslogo} alt="" className="widthlogometaild" /> */}
        </div>
        <div id='content-wrapper'>
          <div className='blocks-container'>
            <div className='block type-1 scroll-to-block' data-id='register'>
              <div className='clip'>
                <div className='bg'>
                  <div class='compdisplay'>
                    <video
                      className='videocomp'
                      src={backVideo}
                      autoPlay
                      muted
                      loop
                      width='100%'
                      height='auto'
                    ></video>
                  </div>
                  <div className='mobdisplay'>
                    <video
                      className='videomob'
                      src={backMovVideo}
                      autoPlay
                      muted
                      loop
                      height='100%'
                    ></video>
                  </div>
                  <div className='ipaddisplay'>
                    <video
                      className='videopad'
                      src={backVideo}
                      autoPlay
                      muted
                      loop
                      height='100%'
                    ></video>
                  </div>
                </div>
                <div className='bg-span'></div>
              </div>
              <div
                className='container type-1-text wow flipInX '
                data-wow-delay='0.3s'
              >
                <div className='row'>
                  <div
                    className='col-md-12 col-lg-8 col-12  type-1-center'
                    style={{
                      textAlign: 'left',
                      left: '177px',
                      fontFamily: 'Gotham-rounded-900'
                    }}
                  >
                    <div className='big white table margintopsection'>
                      <h1 className='h1 marginbottomtext boldfont fontsize'>
                        MEDEX <br />
                      </h1>
                      <h1
                        className='small white fontsize'
                        style={{ fontSize: '30px' }}
                      >
                        Heart Failure{' '}
                      </h1>
                      {/* <div className="eventDate" style={{ color: 'white', fontSize: '1.4rem', marginTop: "28px" }}>From
                                                21<sup style={{ textTransform: 'lowercase' }}>st</sup> January to 31
                                                <sup style={{ textTransform: 'lowercase' }}>st</sup> March 2022</div> */}
                      {this.isMobileDevice() && (
                        <p
                          className='marginbottomtext paddingbottomzero'
                          style={{ fontSize: '15px', lineHeight: '1.9' }}
                        >
                          Register now to get access to a one-of-its-kind
                          Virtual Event, with a lineup of informative sessions
                          and Expert!
                        </p>
                      )}
                      {!this.isMobileDevice() && (
                        <p
                          className='marginbottomtext paddingbottomzero'
                          style={{ fontSize: '15px', lineHeight: '1.9' }}
                        >
                          Register now to get access to a one-of-its-kind
                          Virtual <br />
                          Event, with a lineup of informative sessions and
                          Expert!
                          <br />
                        </p>
                      )}
                      {/* <button className="buttonagenda" style={{ marginTop: "23px", border: 0, outline: 0, width: "230px", fontSize: "18px" }}
                                                onClick={this.goToAgenda}>
                                                Event Agenda
                                            </button> */}
                      <br></br>
                      <div class='browserplace'>
                        <p
                          class='marginbottomtext paddingbottomzero'
                          style={{ marginTop: '21px' }}
                        >
                          Use any of these browsers for best Experience!
                        </p>
                        <img src={chromeimg} alt='' className='chromewidth' />
                      </div>
                    </div>
                  </div>

                  {this.state.isLoginFormVisible ? (
                    <div
                      id='login-form'
                      style={{ display: 'block' }}
                      className='col-md-8 col-lg-4 col-12 type-1-center margintoplogin'
                    >
                      <div className='flex--form'>
                        <h5 className='color-green'>LOGIN</h5>
                        <input
                          className='required inputclass'
                          id='login-email-txt'
                          style={{ width: '100%' }}
                          value={this.state.loginEmail}
                          onChange={this.handleLoginEmailChange}
                          onKeyUp={this.handleEmailKeyPress}
                          type='email'
                          placeholder='*Your email'
                          name='email'
                        />
                        <input
                          className='required inputclass'
                          id='login-password-txt'
                          autoComplete='false'
                          value={this.state.loginPassword}
                          style={{ width: '100%' }}
                          onKeyUp={this.handlePasswordKeyPress}
                          onChange={this.handleLoginPasswordChange}
                          type='password'
                          placeholder='*Password'
                          name='password'
                        />

                        <div
                          className='submit-wraper'
                          style={{ marginTop: '10px' }}
                        >
                          <button
                            className='loginbutton'
                            style={{ marginBottom: '20px', outline: 0 }}
                            id='login-btn'
                            onClick={event => this.loginButton(event)}
                          >
                            <div id='login-name' style={{ display: 'block' }}>
                              Login
                            </div>
                            <div
                              className='loaderBtn'
                              id='loader-login'
                              style={{ display: 'none' }}
                            ></div>
                          </button>
                        </div>

                        {/* </form> */}
                      </div>
                      <div>
                        <button
                          className='w-200px button'
                          style={{ marginTop: '20px', outline: 0 }}
                          id='register-btn'
                          onClick={() =>
                            this.setState({
                              isLoginFormVisible: false
                            })
                          }
                        >
                          Register here
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div
                      id='registration-form'
                      className='col-md-8 col-lg-4 col-12 type-1-center margintoplogin'
                      style={{ display: 'block' }}
                    >
                      <div className='flex--form--r'>
                        <h5 className='color-green'>REGISTER HERE!</h5>

                        <input
                          className='required'
                          id='regName'
                          //   onKeyUp='regNameKeyPress()'
                          autoFocus
                          style={{ width: '100%' }}
                          type='text'
                          placeholder='*Your name'
                          value={this.state.registrationObj.name}
                          name='name'
                          onChange={e => {
                            this.setState(prevState => ({
                              registrationObj: {
                                ...prevState.registrationObj,
                                name: e.target.value
                              }
                            }))
                          }}
                        />

                        <input
                          className='required'
                          id='regEmail'
                          //   onKeyUp='regEmailKeyPress()'
                          style={{ width: '100%' }}
                          type='email'
                          placeholder='*Your email'
                          value={this.state.registrationObj.email}
                          name='email'
                          onChange={e => {
                            this.setState(prevState => ({
                              registrationObj: {
                                ...prevState.registrationObj,
                                email: e.target.value
                              }
                            }))
                          }}
                        />
                        <input
                          className='required'
                          id='regPassword'
                          //   onKeyUp='regPasswordKeyPress()'
                          style={{ width: '100%' }}
                          type='password'
                          placeholder='*Password'
                          value={this.state.registrationObj.password}
                          name='password'
                          onChange={e => {
                            this.setState(prevState => ({
                              registrationObj: {
                                ...prevState.registrationObj,
                                password: e.target.value
                              }
                            }))
                          }}
                        />

                        <div className='box' style={{ width: '100%' }}>
                          <select
                            id='select-country'
                            className='marignlefttwo'
                            style={{ width: '100%', margin: '4px 0' }}
                            value={this.state.registrationObj.country}
                            onChange={e => {
                              this.setState(prevState => ({
                                registrationObj: {
                                  ...prevState.registrationObj,
                                  country: e.target.value,
                                  countryName:
                                    e.target.selectedOptions[0].innerHTML
                                }
                              }))
                            }}
                          >
                            <option name='Country' value='0'>
                              *Country
                            </option>
                            <option name='Afghanistan(+93)' value='93'>
                              Afghanistan(+93)
                            </option>
                            <option name='Albania(+355)' value='355'>
                              Albania(+355)
                            </option>
                            <option name='Algeria(+213)' value='213'>
                              Algeria(+213)
                            </option>
                            <option name='Andorra(+376)' value='376'>
                              Andorra(+376)
                            </option>
                            <option name='Angola(+244)' value='244'>
                              Angola(+244)
                            </option>
                            <option
                              name='Antigua and Barbuda(+1-268)'
                              value='1-268'
                            >
                              Antigua and Barbuda(+1-268)
                            </option>
                            <option name='Argentina(+54)' value='54'>
                              Argentina(+54)
                            </option>
                            <option name='Armenia(+374)' value='374'>
                              Armenia(+374)
                            </option>
                            <option name='Australia(+61)' value='61'>
                              Australia(+61)
                            </option>
                            <option name='Austria(+43)' value='43'>
                              Austria(+43)
                            </option>
                            <option name='Azerbaijan(+994)' value='994'>
                              Azerbaijan(+994)
                            </option>
                            <option name='Bahamas(+1-242)' value='1-242'>
                              Bahamas(+1-242)
                            </option>
                            <option name='Bahrain(+973)' value='973'>
                              Bahrain(+973)
                            </option>
                            <option name='Bangladesh(+880)' value='880'>
                              Bangladesh(+880)
                            </option>
                            <option name='Barbados(+1-246)' value='1-246'>
                              Barbados(+1-246)
                            </option>
                            <option name='Belarus(+375)' value='375'>
                              Belarus(+375)
                            </option>
                            <option name='Belgium(+32)' value='32'>
                              Belgium(+32)
                            </option>
                            <option name='Belize(+501)' value='501'>
                              Belize(+501)
                            </option>
                            <option name='Benin(+229)' value='229'>
                              Benin(+229)
                            </option>
                            <option name='Bhutan(+975)' value='975'>
                              Bhutan(+975)
                            </option>
                            <option name='Bolivia(+591)' value='591'>
                              Bolivia(+591)
                            </option>
                            <option
                              name='Bosnia and Herzegovina(+387)'
                              value='387'
                            >
                              Bosnia and Herzegovina(+387)
                            </option>
                            <option name='Botswana(+267)' value='267'>
                              Botswana(+267)
                            </option>
                            <option name='Brazil(+55)' value='55'>
                              Brazil(+55)
                            </option>
                            <option name='Brunei(+673)' value='673'>
                              Brunei(+673)
                            </option>
                            <option name='Bulgaria(+359)' value='359'>
                              Bulgaria(+359)
                            </option>
                            <option name='Burkina Faso(+226)' value='226'>
                              Burkina Faso(+226)
                            </option>
                            <option name='Burundi(+257)' value='257'>
                              Burundi(+257)
                            </option>
                            <option name='Cambodia(+855)' value='855'>
                              Cambodia(+855)
                            </option>
                            <option name='Cameroon(+237)' value='237'>
                              Cameroon(+237)
                            </option>
                            <option name='Canada(+1)' value='1'>
                              Canada(+1)
                            </option>
                            <option
                              name='Central African Republic(+236)'
                              value='236'
                            >
                              Central African Republic(+236)
                            </option>
                            <option name='Chad(+235)' value='235'>
                              Chad(+235)
                            </option>
                            <option name='Chile(+56)' value='56'>
                              Chile(+56)
                            </option>
                            <option name='China(+86)' value='86'>
                              China(+86)
                            </option>
                            <option name='Colombia(+57)' value='57'>
                              Colombia(+57)
                            </option>
                            <option name='Comoros(+269)' value='269'>
                              Comoros(+269)
                            </option>
                            <option name='Costa Rica(+506)' value='506'>
                              Costa Rica(+506)
                            </option>
                            <option name='Croatia(+385)' value='385'>
                              Croatia(+385)
                            </option>
                            <option name='Cuba(+53)' value='53'>
                              Cuba(+53)
                            </option>
                            <option name='Cyprus(+357)' value='357'>
                              Cyprus(+357)
                            </option>
                            <option
                              name='Czechia (Czech Republic)(+420)'
                              value='420'
                            >
                              Czechia (Czech Republic)(+420)
                            </option>
                            <option
                              n17pxame='Democratic Republic of the Congo(+243)'
                              value='243'
                            >
                              Democratic Republic of the Congo(+243)
                            </option>
                            <option name='Denmark(+45)' value='45'>
                              Denmark(+45)
                            </option>
                            <option name='Djibouti(+253)' value='253'>
                              Djibouti(+253)
                            </option>
                            <option name='Dominica(+1-767)' value='1-767'>
                              Dominica(+1-767)
                            </option>
                            <option
                              name='Dominican Republic(+1-809)'
                              value='1-809'
                            >
                              Dominican Republic(+1-809)
                            </option>
                            <option name='Ecuador(+593)' value='593'>
                              Ecuador(+593)
                            </option>
                            <option name='Egypt(+20)' value='20'>
                              Egypt(+20)
                            </option>
                            <option name='El Salvador(+503)' value='503'>
                              El Salvador(+503)
                            </option>
                            <option name='Equatorial Guinea(+240)' value='240'>
                              Equatorial Guinea(+240)
                            </option>
                            <option name='Eritrea(+291)' value='291'>
                              Eritrea(+291)
                            </option>
                            <option name='Estonia(+372)' value='372'>
                              Estonia(+372)
                            </option>
                            <option name='Ethiopia(+251)' value='251'>
                              Ethiopia(+251)
                            </option>
                            <option name='Fiji(+679)' value='679'>
                              Fiji(+679)
                            </option>
                            <option name='Finland(+358)' value='358'>
                              Finland(+358)
                            </option>
                            <option name='France(+33)' value='33'>
                              France(+33)
                            </option>
                            <option name='Gabon(+241)' value='241'>
                              Gabon(+241)
                            </option>
                            <option name='Gambia(+220)' value='220'>
                              Gambia(+220)
                            </option>
                            <option name='Georgia(+995)' value='995'>
                              Georgia(+995)
                            </option>
                            <option name='Germany(+49)' value='49'>
                              Germany(+49)
                            </option>
                            <option name='Ghana(+233)' value='233'>
                              Ghana(+233)
                            </option>
                            <option name='Greece(+30)' value='30'>
                              Greece(+30)
                            </option>
                            <option name='Greenland(+299)' value='299'>
                              Greenland(+299)
                            </option>
                            <option name='Guam(+1-671)' value='1-671'>
                              Guam(+1-671)
                            </option>

                            <option name='Grenada(+1-473)' value='1-473'>
                              Grenada(+1-473)
                            </option>
                            <option name='Guatemala(+502)' value='502'>
                              Guatemala(+502)
                            </option>
                            <option name='Guinea(+224)' value='224'>
                              Guinea(+224)
                            </option>
                            <option name='Guinea-Bissau(+245)' value='245'>
                              Guinea-Bissau(+245)
                            </option>
                            <option name='Guyana(+592)' value='592'>
                              Guyana(+592)
                            </option>
                            <option name='Haiti(+509)' value='509'>
                              Haiti(+509)
                            </option>
                            <option name='Honduras(+504)' value='504'>
                              Honduras(+504)
                            </option>
                            <option name='Hungary(+852)' value='852'>
                              Hungary(+852)
                            </option>
                            <option name='Iceland(+354)' value='354'>
                              Iceland(+354)
                            </option>
                            <option name='India(+91)' value='91'>
                              India(+91)
                            </option>
                            <option name='Indonesia(+62)' value='62'>
                              Indonesia(+62)
                            </option>
                            <option name='Iran(+98)' value='98'>
                              Iran(+98)
                            </option>
                            <option name='Iraq(+964)' value='964'>
                              Iraq(+964)
                            </option>
                            <option name='Ireland(+353)' value='353'>
                              Ireland(+353)
                            </option>
                            <option name='Israel(+972)' value='972'>
                              Israel(+972)
                            </option>
                            <option name='Italy(+39)' value='39'>
                              Italy(+39)
                            </option>
                            <option name='Jamaica(+1-876)' value='1-876'>
                              Jamaica(+1-876)
                            </option>
                            <option name='Japan(+81)' value='81'>
                              Japan(+81)
                            </option>
                            <option name='Jordan(+962)' value='962'>
                              Jordan(+962)
                            </option>
                            <option name='Kazakhstan(+7)' value='878'>
                              Kazakhstan(+7)
                            </option>
                            <option name='Kenya(+254)' value='254'>
                              Kenya(+254)
                            </option>
                            <option name='Kiribati(+686)' value='686'>
                              Kiribati(+686)
                            </option>
                            <option name='Kuwait(+965)' value='965'>
                              Kuwait(+965)
                            </option>
                            <option name='Kyrgyzstan(+996)' value='996'>
                              Kyrgyzstan(+996)
                            </option>
                            <option name='Laos(+856)' value='856'>
                              Laos(+856)
                            </option>
                            <option name='Latvia(+371)' value='371'>
                              Latvia(+371)
                            </option>
                            <option name='Lebanon(+961)' value='961'>
                              Lebanon(+961)
                            </option>
                            <option name='Lesotho(+266)' value='266'>
                              Lesotho(+266)
                            </option>
                            <option name='Liberia(+231)' value='231'>
                              Liberia(+231)
                            </option>
                            <option name='Libya(+218)' value='218'>
                              Libya(+218)
                            </option>
                            <option name='Liechtenstein(+423)' value='423'>
                              Liechtenstein(+423)
                            </option>
                            <option name='Lithuania(+370)' value='370'>
                              Lithuania(+370)
                            </option>
                            <option name='Luxembourg(+352)' value='352'>
                              Luxembourg(+352)
                            </option>
                            <option name='Madagascar(+261)' value='261'>
                              Madagascar(+261)
                            </option>
                            <option name='Malawi(+265)' value='265'>
                              Malawi(+265)
                            </option>
                            <option name='Malaysia(+60)' value='60'>
                              Malaysia(+60)
                            </option>
                            <option name='Maldives(+960)' value='960'>
                              Maldives(+960)
                            </option>
                            <option name='Mali(+223)' value='223'>
                              Mali(+223)
                            </option>
                            <option name='Malta(+356)' value='356'>
                              Malta(+356)
                            </option>
                            <option name='Marshall Islands(+692)' value='692'>
                              Marshall Islands(+692)
                            </option>
                            <option name='Mauritania(+222)' value='222'>
                              Mauritania(+222)
                            </option>
                            <option name='Mauritius(+230)' value='230'>
                              Mauritius(+230)
                            </option>
                            <option name='Mexico(+52)' value='52'>
                              Mexico(+52)
                            </option>
                            <option name='Micronesia(+691)' value='691'>
                              Micronesia(+691)
                            </option>
                            <option name='Moldova(+373)' value='373'>
                              Moldova(+373)
                            </option>
                            <option name='Monaco(+377)' value='377'>
                              Monaco(+377)
                            </option>
                            <option name='Mongolia(+976)' value='976'>
                              Mongolia(+976)
                            </option>
                            <option name='Montenegro(+382)' value='382'>
                              Montenegro(+382)
                            </option>
                            <option name='Morocco(+212)' value='212'>
                              Morocco(+212)
                            </option>
                            <option name='Mozambique(+258)' value='258'>
                              Mozambique(+258)
                            </option>
                            <option
                              name='Myanmar (formerly Burma)(+95)'
                              value='95'
                            >
                              Myanmar (formerly Burma)(+95)
                            </option>
                            <option name='Namibia(+264)' value='264'>
                              Namibia(+264)
                            </option>
                            <option name='Nauru(+674)' value='674'>
                              Nauru(+674)
                            </option>
                            <option name='Nepal(+977)' value='977'>
                              Nepal(+977)
                            </option>
                            <option name='Netherlands(+31)' value='31'>
                              Netherlands(+31)
                            </option>
                            <option name='New Zealand(+64)' value='64'>
                              New Zealand(+64)
                            </option>
                            <option name='Nicaragua(+505)' value='505'>
                              Nicaragua(+505)
                            </option>
                            <option name='Niger(+227)' value='227'>
                              Niger(+227)
                            </option>
                            <option name='Nigeria(+234)' value='234'>
                              Nigeria(+234)
                            </option>
                            <option name='North Korea(+850)' value='850'>
                              North Korea(+850)
                            </option>
                            <option
                              name='North Macedonia(+1-670)'
                              value='1-670'
                            >
                              North Macedonia(+1-670)
                            </option>
                            <option name='Norway(+47)' value='47'>
                              Norway(+47)
                            </option>
                            <option name='Oman(+968)' value='968'>
                              Oman(+968)
                            </option>
                            <option name='Pakistan(+92)' value='92'>
                              Pakistan(+92)
                            </option>
                            <option name='Palau(+680)' value='680'>
                              Palau(+680)
                            </option>
                            <option name='Palestine State(+970)' value='970'>
                              Palestine State(+970)
                            </option>
                            <option name='Panama(+507)' value='507'>
                              Panama(+507)
                            </option>
                            <option name='Papua New Guinea(+675)' value='675'>
                              Papua New Guinea(+675)
                            </option>
                            <option name='Paraguay(+595)' value='595'>
                              Paraguay(+595)
                            </option>
                            <option name='Peru(+51)' value='51'>
                              Peru(+51)
                            </option>
                            <option name='Philippines(+63)' value='63'>
                              Philippines(+63)
                            </option>
                            <option name='Poland(+48)' value='48'>
                              Poland(+48)
                            </option>
                            <option name='Portugal(+351)' value='351'>
                              Portugal(+351)
                            </option>
                            <option name='Qatar(+974)' value='974'>
                              Qatar(+974)
                            </option>
                            <option name='Reunion(+262)' value='262'>
                              Reunion(+262)
                            </option>
                            <option name='Romania(+40)' value='40'>
                              Romania(+40)
                            </option>
                            <option name='Russia(+7)' value='7'>
                              Russia(+7)
                            </option>
                            <option name='Rwanda(+250)' value='250'>
                              Rwanda(+250)
                            </option>
                            <option
                              name='Saint Kitts and Nevis(+1-869)'
                              value='1-869'
                            >
                              Saint Kitts and Nevis(+1-869)
                            </option>
                            <option name='Saint Lucia(+1-758)' value='1-758'>
                              Saint Lucia(+1-758)
                            </option>
                            <option
                              name='Saint Vincent and the Grenadines(+1-784)'
                              value='1-784'
                            >
                              Saint Vincent and the Grenadines(+1-784)
                            </option>
                            <option name='Samoa(+685)' value='685'>
                              Samoa(+685)
                            </option>
                            <option name='San Marino(+378)' value='378'>
                              San Marino(+378)
                            </option>
                            <option
                              name='Sao Tome and Principe(+239)'
                              value='239'
                            >
                              Sao Tome and Principe(+239)
                            </option>
                            <option name='Saudi Arabia(+966)' value='966'>
                              Saudi Arabia(+966)
                            </option>
                            <option name='Senegal(+221)' value='221'>
                              Senegal(+221)
                            </option>
                            <option name='Serbia(+381)' value='381'>
                              Serbia(+381)
                            </option>
                            <option name='Seychelles(+248)' value='248'>
                              Seychelles(+248)
                            </option>
                            <option name='Sierra Leone(+232)' value='232'>
                              Sierra Leone(+232)
                            </option>
                            <option name='Singapore(+65)' value='65'>
                              Singapore(+65)
                            </option>
                            <option name='Slovakia(+421)' value='421'>
                              Slovakia(+421)
                            </option>
                            <option name='Slovenia(+386)' value='386'>
                              Slovenia(+386)
                            </option>
                            <option name='Solomon Islands(+677)' value='677'>
                              Solomon Islands(+677)
                            </option>
                            <option name='Somalia(+252)' value='252'>
                              Somalia(+252)
                            </option>
                            <option name='South Africa(+27)' value='27'>
                              South Africa(+27)
                            </option>
                            <option name='South Korea(+82)' value='82'>
                              South Korea(+82)
                            </option>
                            <option name='South Sudan(+211)' value='211'>
                              South Sudan(+211)
                            </option>
                            <option name='Spain(+34)' value='34'>
                              Spain(+34)
                            </option>
                            <option name='Sri Lanka(+94)' value='94'>
                              Sri Lanka(+94)
                            </option>
                            <option name='Sudan(+249)' value='249'>
                              Sudan(+249)
                            </option>
                            <option name='Suriname(+597)' value='597'>
                              Suriname(+597)
                            </option>
                            <option name='Sweden(+46)' value='46'>
                              Sweden(+46)
                            </option>
                            <option name='Switzerland(+41)' value='41'>
                              Switzerland(+41)
                            </option>
                            <option name='Syria(+963)' value='963'>
                              Syria(+963)
                            </option>
                            <option name='Tajikistan(+992)' value='992'>
                              Tajikistan(+992)
                            </option>
                            <option name='Tanzania(+255)' value='255'>
                              Tanzania(+255)
                            </option>
                            <option name='Thailand(+66)' value='66'>
                              Thailand(+66)
                            </option>

                            <option name='Togo(+228)' value='228'>
                              Togo(+228)
                            </option>
                            <option name='Tonga(+676)' value='676'>
                              Tonga(+676)
                            </option>
                            <option
                              name='Trinidad and Tobago(+1-868)'
                              value='1-868'
                            >
                              Trinidad and Tobago(+1-868)
                            </option>
                            <option name='Tunisia(+216)' value='216'>
                              Tunisia(+216)
                            </option>
                            <option name='Turkey(+90)' value='90'>
                              Turkey(+90)
                            </option>
                            <option name='Turkmenistan(+993)' value='993'>
                              Turkmenistan(+993)
                            </option>
                            <option name='Tuvalu(+688)' value='688'>
                              Tuvalu(+688)
                            </option>
                            <option name='Uganda(+256)' value='256'>
                              Uganda(+256)
                            </option>
                            <option name='Ukraine(+380)' value='380'>
                              Ukraine(+380)
                            </option>
                            <option
                              name='United Arab Emirates(+971)'
                              value='971'
                            >
                              United Arab Emirates(+971)
                            </option>
                            <option name='United Kingdom(+44)' value='44'>
                              United Kingdom(+44)
                            </option>
                            <option
                              name='United States of America(+1)'
                              value='1'
                            >
                              United States of America(+1)
                            </option>
                            <option name='Uruguay(+598)' value='598'>
                              Uruguay(+598)
                            </option>
                            <option name='Uzbekistan(+998)' value='998'>
                              Uzbekistan(+998)
                            </option>
                            <option name='Vanuatu(+678)' value='678'>
                              Vanuatu(+678)
                            </option>
                            <option name='Venezuela(+58)' value='58'>
                              Venezuela(+58)
                            </option>
                            <option name='Vietnam(+84)' value='84'>
                              Vietnam(+84)
                            </option>
                            <option name='Wallis and Futuna(+681)' value='681'>
                              Wallis and Futuna(+681)
                            </option>
                            <option name='Western Sahara(+212)' value='212'>
                              Western Sahara(+212)
                            </option>

                            <option name='Yemen(+967)' value='967'>
                              Yemen(+967)
                            </option>
                            <option name='Zambia(+260)' value='260'>
                              Zambia(+260)
                            </option>
                            <option name='Zimbabwe(+263)' value='263'>
                              Zimbabwe(+263)
                            </option>
                          </select>
                        </div>
                        <input
                          className='required '
                          //   onkeypress='return isNumber(event)'
                          //   onKeyUp='regMobileNoKeyPress()'
                          id='regNumber'
                          style={{ width: '100%' }}
                          type='text'
                          placeholder='*Phone No.'
                          value={this.state.registrationObj.mobileNumber}
                          name='mobileNumber'
                          onChange={e => {
                            this.setState(prevState => ({
                              registrationObj: {
                                ...prevState.registrationObj,
                                mobileNumber: e.target.value
                              }
                            }))
                          }}
                        />

                        <div className='box' style={{ width: '100%' }}>
                          <select
                            id='regDesignation'
                            className='marignlefttwo'
                            style={{ width: '100%', margin: '4px 0' }}
                            value={this.state.registrationObj.designation}
                            onChange={e => {
                              this.setState(prevState => ({
                                registrationObj: {
                                  ...prevState.registrationObj,
                                  designation: e.target.value
                                }
                              }))
                            }}
                          >
                            <option value=''>Designation</option>
                            <option value='Doctor'>Doctor</option>
                            <option value='HCP'>HCP</option>
                            <option value='Professor'>Professor</option>
                            <option value='Nurse'>Nurse</option>
                          </select>
                        </div>

                        <input
                          className='required '
                          //   onKeyUp='regHospitalKeyPress()'
                          id='regHospital'
                          style={{ width: '100%' }}
                          type='text'
                          placeholder='*Specialty'
                          value={this.state.registrationObj.speciality}
                          name='speciality'
                          onChange={e => {
                            this.setState(prevState => ({
                              registrationObj: {
                                ...prevState.registrationObj,
                                speciality: e.target.value
                              }
                            }))
                          }}
                        />

                        <a
                          id='register-btn'
                          className='button'
                          onClick={this.handleSignUp}
                        >
                          <div id='signup-name'>Register</div>

                          <div
                            className='loaderBtn'
                            id='loader-signup'
                            style={{ display: 'none' }}
                          ></div>
                          <span className='button-overlay'></span>
                        </a>

                        <div className='loginfrom'>
                          <span>Already registered?&nbsp;</span>
                          <a
                            className=''
                            id='register-btn'
                            onClick={() =>
                              this.setState({
                                isLoginFormVisible: true
                              })
                            }
                          >
                            Click here to login
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* AGENDA POPUP */}
        <div className='modal' id='location1' style={{ display: 'none' }}>
          <div className='modal-dialog modal-dialog-centered modal-xl'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title green-text' id='staticBackdropLabel'>
                  Agenda
                </h5>

                <button
                  type='button'
                  className='close black-color'
                  data-dismiss='modal'
                  onClick={this.closePopup}
                >
                  &times;
                </button>
              </div>
              <div
                className='agendaImage'
                id='nurseAgenda'
                style={{ display: 'none' }}
              >
                <img src={nurseAgendaImg} style={{ width: '100%' }} alt='' />
              </div>

              <section id='agenda'>
                <div class='extra-container' style={{ display: 'none' }}>
                  <div class='schedule-list__tittle'>
                    <h1>Conference Schedule</h1>
                  </div>
                </div>
                <div class='container'>
                  <div class='schedule-tab'>
                    <a
                      class='schedule-tab__item'
                      id='btn-1'
                      data-tab='tab-1'
                      href='#tab-1'
                      onClick={() => this.changeAgendaDate(1)}
                    >
                      <h2>Day 01</h2>
                      <p>21/01/2022</p>
                    </a>
                    <a
                      class='schedule-tab__item '
                      id='btn-2'
                      data-tab='tab-2'
                      href='#tab-2'
                      onClick={() => this.changeAgendaDate(2)}
                    >
                      <h2>Day 02</h2>
                      <p>28/01/2022</p>
                    </a>
                    <a
                      class='schedule-tab__item'
                      id='btn-3'
                      data-tab='tab-3'
                      href='#tab-3'
                      onClick={() => this.changeAgendaDate(3)}
                    >
                      <h2>Day 03</h2>
                      <p>04/02/2022</p>
                    </a>
                    <a
                      class='schedule-tab__item'
                      id='btn-4'
                      data-tab='tab-4'
                      href='#tab-4'
                      onClick={() => this.changeAgendaDate(4)}
                    >
                      <h2>Day 04</h2>
                      <p>11/02/2022</p>
                    </a>
                    <a
                      class='schedule-tab__item'
                      id='btn-5'
                      data-tab='tab-5'
                      href='#tab-5'
                      onClick={() => this.changeAgendaDate(5)}
                    >
                      <h2>Day 05</h2>
                      <p>18/02/2022</p>
                    </a>
                    <a
                      class='schedule-tab__item tab-active'
                      id='btn-6'
                      data-tab='tab-6'
                      href='#tab-6'
                      onClick={() => this.changeAgendaDate(6)}
                    >
                      <h2>Day 06</h2>
                      <p>24/02/2022</p>
                    </a>
                    <a
                      class='schedule-tab__item'
                      id='btn-7'
                      data-tab='tab-7'
                      href='#tab-7'
                      onClick={() => this.changeAgendaDate(7)}
                    >
                      <h2>Day 07</h2>
                      <p>04/03/2022</p>
                    </a>
                    <a
                      class='schedule-tab__item'
                      id='btn-8'
                      data-tab='tab-8'
                      href='#tab-8'
                      onClick={() => this.changeAgendaDate(8)}
                    >
                      <h2>Day 08</h2>
                      <p>11/03/2022</p>
                    </a>
                    <a
                      class='schedule-tab__item'
                      id='btn-9'
                      data-tab='tab-9'
                      href='#tab-9'
                      onClick={() => this.changeAgendaDate(9)}
                    >
                      <h2>Day 09</h2>
                      <p>16/03/2022</p>
                    </a>
                    <a
                      class='schedule-tab__item'
                      id='btn-10'
                      data-tab='tab-10'
                      href='#tab-10'
                      onClick={() => this.changeAgendaDate(10)}
                    >
                      <h2>Day 10</h2>
                      <p>17/03/2022</p>
                    </a>
                    <a
                      class='schedule-tab__item'
                      id='btn-11'
                      data-tab='tab-11'
                      href='#tab-11'
                      onClick={() => this.changeAgendaDate(11)}
                    >
                      <h2>Day 11</h2>
                      <p>23/03/2022</p>
                    </a>
                    <a
                      class='schedule-tab__item'
                      id='btn-12'
                      data-tab='tab-12'
                      href='#tab-12'
                      onClick={() => this.changeAgendaDate(12)}
                    >
                      <h2>Day 12</h2>
                      <p>31/03/2022</p>
                    </a>
                  </div>

                  <div id='tab-1' class='schedule-table '>
                    <div class='row wow flipInX'>
                      <div class='col-lg-3 col-sm-5'>
                        <div class='schedule-table__time'>
                          <h3>08:00PM – 08:10PM</h3>
                        </div>
                      </div>
                      <div class='col-lg-6 col-sm-7'>
                        <div class='schedule-table__event'>
                          <h3>Welcome remarks </h3>
                        </div>
                      </div>
                      <div class='col-lg-3 col-sm-12'>
                        <div class='schedule-table__speaker'>
                          <div class='schedule-table__speaker__info'></div>
                        </div>
                      </div>
                    </div>

                    <div class='row wow flipInX'>
                      <div class='col-lg-3 col-sm-5'>
                        <div class='schedule-table__time'>
                          <h3>08:10PM – 08:40PM</h3>
                        </div>
                      </div>
                      <div class='col-lg-6 col-sm-7'>
                        <div class='schedule-table__event'>
                          <h3>HFrEF; Defining the global Challenges</h3>
                        </div>
                      </div>
                      <div class='col-lg-3 col-sm-12'>
                        <div class='schedule-table__speaker'>
                          <div class='schedule-table__speaker__info'>
                            <img src={stefanAnkerImg} alt='Stefan Anker' />
                            <div class='schedule-table__speaker__name'>
                              <p>Stefan Anker </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='row wow flipInX'>
                      <div class='col-lg-3 col-sm-5'>
                        <div class='schedule-table__time'>
                          <h3>08:40PM – 09:10PM </h3>
                        </div>
                      </div>
                      <div class='col-lg-6 col-sm-7'>
                        <div class='schedule-table__event'>
                          <h3>HFpEF; Defining the global Challenges</h3>
                        </div>
                      </div>
                      <div class='col-lg-3 col-sm-12'>
                        <div class='schedule-table__speaker'>
                          <div class='schedule-table__speaker__info'>
                            <img src={javedButlerImg} alt='Javed Butler' />
                            <div class='schedule-table__speaker__name'>
                              <p>Javed Butler </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class='row wow flipInX'>
                      <div class='col-lg-3 col-sm-4'>
                        <div class='schedule-table__time'>
                          <h3>09:10PM – 09:40PM</h3>
                        </div>
                      </div>
                      <div class='col-lg-6 col-sm-8'>
                        <div class='schedule-table__event'>
                          <h3>HF, The Burden in META Region</h3>
                        </div>
                      </div>
                      <div class='col-lg-3 col-sm-12'>
                        <div class='schedule-table__speaker'>
                          <div class='schedule-table__speaker__info'>
                            <img src={walidAlHabeebImg} alt='Waleed AlHabeeb' />
                            <div class='schedule-table__speaker__name'>
                              <p>Waleed AlHabeeb</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class='row wow flipInX'>
                      <div class='col-lg-3 col-sm-4'>
                        <div class='schedule-table__time'>
                          <h3>09:40PM – 10:10PM</h3>
                        </div>
                      </div>
                      <div class='col-lg-6 col-sm-8'>
                        <div class='schedule-table__event'>
                          <h3>Q&A</h3>
                        </div>
                      </div>
                      <div class='col-lg-3 col-sm-12'>
                        <div class='schedule-table__speaker'>
                          <div class='schedule-table__speaker__info'>
                            <div class='schedule-table__speaker__name'>
                              <p>All Faculty</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id='tab-2' class='schedule-table'>
                    <div class='row wow flipInX'>
                      <div class='col-lg-3 col-sm-5'>
                        <div class='schedule-table__time'>
                          <h3>08:00PM – 08:10PM</h3>
                        </div>
                      </div>
                      <div class='col-lg-6 col-sm-7'>
                        <div class='schedule-table__event'>
                          <h3>Welcome remarks </h3>
                        </div>
                      </div>
                      <div class='col-lg-3 col-sm-12'>
                        <div class='schedule-table__speaker'>
                          <div class='schedule-table__speaker__info'>
                            {/* <img src={javedButlerImg} alt="Javed Butler" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Javed Butler</p>
                                                        </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='row wow flipInX'>
                      <div class='col-lg-3 col-sm-5'>
                        <div class='schedule-table__time'>
                          <h3>08:10PM – 09:40PM</h3>
                        </div>
                      </div>
                      <div class='col-lg-6 col-sm-7'>
                        <div class='schedule-table__event'>
                          <h3>
                            Meet the Experts- Prevention of HF in High-Risk
                            groups, how can we intervene earlier?
                          </h3>
                        </div>
                      </div>
                      <div class='col-lg-3 col-sm-12'>
                        <div class='schedule-table__speaker'>
                          <div class='schedule-table__speaker__info'>
                            <img src={shelleyImg} alt='Shelley Zieroth' />
                            <div class='schedule-table__speaker__name'>
                              <p>Shelley Zieroth</p>
                            </div>
                          </div>
                          <div class='schedule-table__speaker__info'>
                            <img src={aliceChengImg} alt='Alice Cheng' />
                            <div class='schedule-table__speaker__name'>
                              <p>Alice Cheng</p>
                            </div>
                          </div>
                          <div class='schedule-table__speaker__info'>
                            <img src={wannerImg} alt='Christoph Wanner' />
                            <div class='schedule-table__speaker__name'>
                              <p>Christoph Wanner</p>
                            </div>
                          </div>
                          <div class='schedule-table__speaker__info'>
                            <img src={haniSabbourImg} alt='Hani Sabbour' />
                            <div class='schedule-table__speaker__name'>
                              <p>Hani Sabbour</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id='tab-3' class='schedule-table'>
                    <div class='row '>
                      <div class='col-lg-3 col-sm-5'>
                        <div class='schedule-table__time'>
                          <h3>08:00PM – 08:10PM</h3>
                        </div>
                      </div>
                      <div class='col-lg-6 col-sm-7'>
                        <div class='schedule-table__event'>
                          <h3>Welcome remarks</h3>
                        </div>
                      </div>
                      <div class='col-lg-3 col-sm-12'>
                        <div class='schedule-table__speaker'>
                          <div class='schedule-table__speaker__info'>
                            {/* <img src={javedButlerImg} alt="Javed Butler" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Javed Butler</p>
                                                        </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='row wow flipInX'>
                      <div class='col-lg-3 col-sm-5'>
                        <div class='schedule-table__time'>
                          <h3>08:10PM – 08:40PM</h3>
                        </div>
                      </div>
                      <div class='col-lg-6 col-sm-7'>
                        <div class='schedule-table__event'>
                          <h3>
                            HFrEF Vs. HFmrEF Vs. HFpEF - Solving the puzzle
                          </h3>
                        </div>
                      </div>
                      <div class='col-lg-3 col-sm-12'>
                        <div class='schedule-table__speaker'>
                          <div class='schedule-table__speaker__info'>
                            <img src={ponikowskiImg} alt='Piotr Ponikowski' />
                            <div class='schedule-table__speaker__name'>
                              <p>Piotr Ponikowski</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='row wow flipInX'>
                      <div class='col-lg-3 col-sm-5'>
                        <div class='schedule-table__time'>
                          <h3>08:40PM – 09:10PM</h3>
                        </div>
                      </div>
                      <div class='col-lg-6 col-sm-7'>
                        <div class='schedule-table__event'>
                          <h3>Excellence in diagnosis of HF</h3>
                        </div>
                      </div>
                      <div class='col-lg-3 col-sm-12'>
                        <div class='schedule-table__speaker'>
                          <div class='schedule-table__speaker__info'>
                            <img src={mcGuireImg} alt='Darren McGuire' />
                            <div class='schedule-table__speaker__name'>
                              <p>Darren McGuire</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='row wow flipInX'>
                      <div class='col-lg-3 col-sm-5'>
                        <div class='schedule-table__time'>
                          <h3>09:10PM – 09:40PM</h3>
                        </div>
                      </div>
                      <div class='col-lg-6 col-sm-7'>
                        <div class='schedule-table__event'>
                          <h3>Optimization of Diagnostic means in META</h3>
                        </div>
                      </div>
                      <div class='col-lg-3 col-sm-12'>
                        <div class='schedule-table__speaker'>
                          <div class='schedule-table__speaker__info'>
                            <img src={hadiSkouriImg} alt='Hadi Skouri' />
                            <div class='schedule-table__speaker__name'>
                              <p>Hadi Skouri</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='row wow flipInX'>
                      <div class='col-lg-3 col-sm-5'>
                        <div class='schedule-table__time'>
                          <h3>09:40PM – 10:10PM</h3>
                        </div>
                      </div>
                      <div class='col-lg-6 col-sm-7'>
                        <div class='schedule-table__event'>
                          <h3>Q&A</h3>
                        </div>
                      </div>
                      <div class='col-lg-3 col-sm-12'>
                        <div class='schedule-table__speaker'>
                          <div class='schedule-table__speaker__info'>
                            <div class='schedule-table__speaker__name'>
                              <p>All Faculty</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id='tab-4' class='schedule-table'>
                    <div class='row wow flipInX'>
                      <div class='col-lg-3 col-sm-5'>
                        <div class='schedule-table__time'>
                          <h3>08:00PM – 08:10PM</h3>
                        </div>
                      </div>
                      <div class='col-lg-6 col-sm-7'>
                        <div class='schedule-table__event'>
                          <h3>Welcome remarks</h3>
                        </div>
                      </div>
                      <div class='col-lg-3 col-sm-12'>
                        <div class='schedule-table__speaker'>
                          <div class='schedule-table__speaker__info'>
                            {/* <img src={mcGuireImg} alt="Darren Maguire" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Darren McGuire</p>
                                                        </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='row wow flipInX'>
                      <div class='col-lg-3 col-sm-5'>
                        <div class='schedule-table__time'>
                          <h3>08:10PM –10:10PM</h3>
                        </div>
                      </div>
                      <div class='col-lg-6 col-sm-7'>
                        <div class='schedule-table__event'>
                          <h3>
                            Meet the Experts-Guidelines for management are
                            evolving rapidly, approach the change in clinical
                            practice
                          </h3>
                        </div>
                      </div>
                      <div class='col-lg-3 col-sm-12'>
                        <div class='schedule-table__speaker'>
                          <div class='schedule-table__speaker__info'>
                            <img src={chantelImg} alt='Alice Cheng' />
                            <div class='schedule-table__speaker__name'>
                              <p>Alice Cheng</p>
                            </div>
                          </div>
                          <div class='schedule-table__speaker__info'>
                            <img
                              src={perHenrikGroopImg}
                              alt='Groop Per-Henrik'
                            />
                            <div class='schedule-table__speaker__name'>
                              <p>Groop Per-Henrik</p>
                            </div>
                          </div>
                          <div class='schedule-table__speaker__info'>
                            <img src={javedButlerImg} alt='Javed Butler' />
                            <div class='schedule-table__speaker__name'>
                              <p>Javed Butler</p>
                            </div>
                          </div>
                          <div class='schedule-table__speaker__info'>
                            <img src={ferasBaderImg} alt='Feras Bader' />
                            <div class='schedule-table__speaker__name'>
                              <p>Feras Bader</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id='tab-5' class='schedule-table'>
                    <div class='row wow flipInX'>
                      <div class='col-lg-3 col-sm-5'>
                        <div class='schedule-table__time'>
                          <h3>08:00PM – 08:10PM</h3>
                        </div>
                      </div>
                      <div class='col-lg-6 col-sm-7'>
                        <div class='schedule-table__event'>
                          <h3>Welcome remarks</h3>
                        </div>
                      </div>
                      <div class='col-lg-3 col-sm-12'>
                        <div class='schedule-table__speaker'>
                          <div class='schedule-table__speaker__info'>
                            {/* <img src={zannadImg} alt="Faiez Zannad" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Faiez Zannad</p>
                                                        </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='row wow flipInX'>
                      <div class='col-lg-3 col-sm-5'>
                        <div class='schedule-table__time'>
                          <h3>08:10PM – 08:40PM</h3>
                        </div>
                      </div>
                      <div class='col-lg-6 col-sm-7'>
                        <div class='schedule-table__event'>
                          <h3>SGLT2 Inhibitors in HFrEF - Overview</h3>
                        </div>
                      </div>
                      <div class='col-lg-3 col-sm-12'>
                        <div class='schedule-table__speaker'>
                          <div class='schedule-table__speaker__info'>
                            <img src={mcGuireImg} alt='Darren McGuire' />
                            <div class='schedule-table__speaker__name'>
                              <p>Darren McGuire</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='row wow flipInX'>
                      <div class='col-lg-3 col-sm-5'>
                        <div class='schedule-table__time'>
                          <h3>08:40PM – 09:10PM</h3>
                        </div>
                      </div>
                      <div class='col-lg-6 col-sm-7'>
                        <div class='schedule-table__event'>
                          <h3>Treatment of HFpEF- New hope</h3>
                        </div>
                      </div>
                      <div class='col-lg-3 col-sm-12'>
                        <div class='schedule-table__speaker'>
                          <div class='schedule-table__speaker__info'>
                            <img src={javedButlerImg} alt='Javed Butler' />
                            <div class='schedule-table__speaker__name'>
                              <p>Javed Butler</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='row wow flipInX'>
                      <div class='col-lg-3 col-sm-4'>
                        <div class='schedule-table__time'>
                          <h3>09:10PM – 09:40PM</h3>
                        </div>
                      </div>
                      <div class='col-lg-6 col-sm-8'>
                        <div class='schedule-table__event'>
                          <h3>
                            Management of Heart Failure in low resources setup
                          </h3>
                        </div>
                      </div>
                      <div class='col-lg-3 col-sm-12'>
                        <div class='schedule-table__speaker'>
                          <div class='schedule-table__speaker__info'>
                            <img src={magdyImg} alt='Magdy Abdelhamid'></img>
                            <div class='schedule-table__speaker__name'>
                              <p>Magdy Abdelhamid </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='row wow flipInX'>
                      <div class='col-lg-3 col-sm-4'>
                        <div class='schedule-table__time'>
                          <h3>09:40PM – 10:10PM</h3>
                        </div>
                      </div>
                      <div class='col-lg-6 col-sm-8'>
                        <div class='schedule-table__event'>
                          <h3>Q&A</h3>
                        </div>
                      </div>
                      <div class='col-lg-3 col-sm-12'>
                        <div class='schedule-table__speaker'>
                          <div class='schedule-table__speaker__info'>
                            <div class='schedule-table__speaker__name'>
                              <p>All Faculty </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id='tab-6' class='schedule-table tab-current'>
                    <div class='row '>
                      <div class='col-lg-3 col-sm-5'>
                        <div class='schedule-table__time'>
                          <h3>08:00PM – 08:10PM </h3>
                        </div>
                      </div>
                      <div class='col-lg-6 col-sm-7'>
                        <div class='schedule-table__event'>
                          <h3>Welcome remarks</h3>
                        </div>
                      </div>
                      <div class='col-lg-3 col-sm-12'>
                        <div class='schedule-table__speaker'>
                          <div class='schedule-table__speaker__info'>
                            {/* <img src={melanieImg} alt="Melanie Davis" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Melanie Davies
                                    </p>
                                                        </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='row wow flipInX'>
                      <div class='col-lg-3 col-sm-4'>
                        <div class='schedule-table__time'>
                          <h3>08:10PM – 09:40PM</h3>
                        </div>
                      </div>
                      <div class='col-lg-6 col-sm-8'>
                        <div class='schedule-table__event'>
                          <h3>
                            Meet the Experts- New horizon in management of HF-
                            The multidisciplinary Approach
                          </h3>
                        </div>
                      </div>
                      <div class='col-lg-3 col-sm-12'>
                        <div class='schedule-table__speaker'>
                          <div class='schedule-table__speaker__info'>
                            <img src={javedButlerImg} alt='Javed Butler'></img>
                            <div class='schedule-table__speaker__name'>
                              <p>Javed Butler </p>
                            </div>
                          </div>
                          <div class='schedule-table__speaker__info'>
                            <img src={peterLinImg} alt='Peter Lin'></img>
                            <div class='schedule-table__speaker__name'>
                              <p>Peter Lin </p>
                            </div>
                          </div>
                          <div class='schedule-table__speaker__info'>
                            <img src={wannerImg} alt='Christoph Wanner'></img>
                            <div class='schedule-table__speaker__name'>
                              <p>Christoph Wanner </p>
                            </div>
                          </div>
                          <div class='schedule-table__speaker__info'>
                            <img src={ahmedShawkyImg} alt='Ahmed Shawky'></img>
                            <div class='schedule-table__speaker__name'>
                              <p>Ahmed Shawky </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id='tab-7' class='schedule-table'>
                    <div class='row wow flipInX'>
                      <div class='col-lg-3 col-sm-5'>
                        <div class='schedule-table__time'>
                          <h3>08:00PM – 08:10PM</h3>
                        </div>
                      </div>
                      <div class='col-lg-6 col-sm-7'>
                        <div class='schedule-table__event'>
                          <h3>Welcome remarks</h3>
                        </div>
                      </div>
                      <div class='col-lg-3 col-sm-12'>
                        <div class='schedule-table__speaker'>
                          <div class='schedule-table__speaker__info'>
                            {/* <img src={sanjayKaulImg} alt="Sanjay Kaul" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Sanjay Kaul</p>
                                                        </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='row wow flipInX'>
                      <div class='col-lg-3 col-sm-5'>
                        <div class='schedule-table__time'>
                          <h3>08:10PM – 08:40PM</h3>
                        </div>
                      </div>
                      <div class='col-lg-6 col-sm-7'>
                        <div class='schedule-table__event'>
                          <h3>
                            EMPEROR Reduced in Depth - sub cohort analyses
                          </h3>
                        </div>
                      </div>
                      <div class='col-lg-3 col-sm-12'>
                        <div class='schedule-table__speaker'>
                          <div class='schedule-table__speaker__info'>
                            <img src={nikolausMarxImg} alt='Nikolaus Marx' />
                            <div class='schedule-table__speaker__name'>
                              <p>Nikolaus Marx </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='row wow flipInX'>
                      <div class='col-lg-3 col-sm-5'>
                        <div class='schedule-table__time'>
                          <h3>08:40PM – 09:10PM</h3>
                        </div>
                      </div>
                      <div class='col-lg-6 col-sm-7'>
                        <div class='schedule-table__event'>
                          <h3>EMPEROR Preserved in Depth - clinical utility</h3>
                        </div>
                      </div>
                      <div class='col-lg-3 col-sm-12'>
                        <div class='schedule-table__speaker'>
                          <div class='schedule-table__speaker__info'>
                            <img src={shelleyImg} alt='Shelley Zieroth' />
                            <div class='schedule-table__speaker__name'>
                              <p>Shelley Zieroth</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='row wow flipInX'>
                      <div class='col-lg-3 col-sm-5'>
                        <div class='schedule-table__time'>
                          <h3>09:10PM – 09:40PM</h3>
                        </div>
                      </div>
                      <div class='col-lg-6 col-sm-7'>
                        <div class='schedule-table__event'>
                          <h3>
                            EMPULSE study, Initiation in hospital - urgent need
                          </h3>
                        </div>
                      </div>
                      <div class='col-lg-3 col-sm-12'>
                        <div class='schedule-table__speaker'>
                          <div class='schedule-table__speaker__info'>
                            <img src={abuHantashImg} alt='Hadi Abu-Hantash' />
                            <div class='schedule-table__speaker__name'>
                              <p>Hadi Abu-Hantash</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='row wow flipInX'>
                      <div class='col-lg-3 col-sm-4'>
                        <div class='schedule-table__time'>
                          <h3>09:40PM – 10:10PM</h3>
                        </div>
                      </div>
                      <div class='col-lg-6 col-sm-8'>
                        <div class='schedule-table__event'>
                          <h3>Q&A</h3>
                        </div>
                      </div>
                      <div class='col-lg-3 col-sm-12'>
                        <div class='schedule-table__speaker'>
                          <div class='schedule-table__speaker__info'>
                            <div class='schedule-table__speaker__name'>
                              <p>All Faculty </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id='tab-8' class='schedule-table'>
                    <div class='row wow flipInX'>
                      <div class='col-lg-3 col-sm-5'>
                        <div class='schedule-table__time'>
                          <h3>08:00PM – 08:10PM</h3>
                        </div>
                      </div>
                      <div class='col-lg-6 col-sm-7'>
                        <div class='schedule-table__event'>
                          <h3>Welcome remarks</h3>
                        </div>
                      </div>
                      <div class='col-lg-3 col-sm-12'>
                        <div class='schedule-table__speaker'>
                          <div class='schedule-table__speaker__info'>
                            {/* <img src={stefanAnkerImg} alt="Stefan Anker" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Stefan Anker</p>

                                                        </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='row wow flipInX'>
                      <div class='col-lg-3 col-sm-5'>
                        <div class='schedule-table__time'>
                          <h3>08:10PM – 09:40PM</h3>
                        </div>
                      </div>
                      <div class='col-lg-6 col-sm-7'>
                        <div class='schedule-table__event'>
                          <h3>
                            Meet the Experts- Early intervention to Prevent
                            Heart Failure complications in High-risk patients
                          </h3>
                        </div>
                      </div>
                      <div class='col-lg-3 col-sm-12'>
                        <div class='schedule-table__speaker'>
                          <div class='schedule-table__speaker__info'>
                            <img src={shelleyImg} alt='Shelley Zieroth' />
                            <div class='schedule-table__speaker__name'>
                              <p>Shelley Zieroth</p>
                            </div>
                          </div>
                          <div class='schedule-table__speaker__info'>
                            <img src={aliceChengImg} alt='Alice Cheng' />
                            <div class='schedule-table__speaker__name'>
                              <p>Alice Cheng </p>
                            </div>
                          </div>
                          <div class='schedule-table__speaker__info'>
                            <img src={sarahJarvisImg} alt='Sarah Jarvis' />
                            <div class='schedule-table__speaker__name'>
                              <p>Sarah Jarvis</p>
                            </div>
                          </div>
                          <div class='schedule-table__speaker__info'>
                            <img src={bulentAltonImg} alt='Bulent Altun' />
                            <div class='schedule-table__speaker__name'>
                              <p>Bulent Altun</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id='tab-9' class='schedule-table'>
                    <div class='row wow flipInX'>
                      <div class='col-lg-3 col-sm-5'>
                        <div class='schedule-table__time'>
                          <h3>08:00PM – 08:10PM</h3>
                        </div>
                      </div>
                      <div class='col-lg-6 col-sm-7'>
                        <div class='schedule-table__event'>
                          <h3>Welcome remarks</h3>
                        </div>
                      </div>
                      <div class='col-lg-3 col-sm-12'>
                        <div class='schedule-table__speaker'>
                          <div class='schedule-table__speaker__info'>
                            {/* <img src={stefanAnkerImg} alt="Stefan Anker" />
                                                            <div class="schedule-table__speaker__name">
                                                                <p>Stefan Anker</p>

                                                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class='row '>
                      <div class='col-lg-3 col-sm-5'>
                        <div class='schedule-table__time'>
                          <h3>08:10PM – 10:10PM</h3>
                        </div>
                      </div>
                      <div class='col-lg-6 col-sm-7'>
                        <div class='schedule-table__event'>
                          <h3>
                            Session 1: Establishing Center of Excellence in
                            Heart Failure - Virtual Preceptorship
                          </h3>
                        </div>
                      </div>
                      <div class='col-lg-3 col-sm-12'>
                        <div class='schedule-table__speaker'>
                          <div class='schedule-table__speaker__info'>
                            <div class='schedule-table__speaker__name'>
                              <p>Broadcast from center of Excellence in US</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id='tab-10' class='schedule-table'>
                    <div class='row wow flipInX'>
                      <div class='col-lg-3 col-sm-5'>
                        <div class='schedule-table__time'>
                          <h3>08:00PM – 08:10PM</h3>
                        </div>
                      </div>
                      <div class='col-lg-6 col-sm-7'>
                        <div class='schedule-table__event'>
                          <h3>Welcome remarks</h3>
                        </div>
                      </div>
                      <div class='col-lg-3 col-sm-12'>
                        <div class='schedule-table__speaker'>
                          <div class='schedule-table__speaker__info'>
                            {/* <img src={stefanAnkerImg} alt="Stefan Anker" />
                                                        <div class="schedule-table__speaker__name">
                                                            <p>Stefan Anker</p>

                                                        </div> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class='row wow flipInX'>
                      <div class='col-lg-3 col-sm-5'>
                        <div class='schedule-table__time'>
                          <h3>08:10PM – 10:10PM</h3>
                        </div>
                      </div>
                      <div class='col-lg-6 col-sm-7'>
                        <div class='schedule-table__event'>
                          <h3>
                            Session 2 - Establishing Heart Failure Clinic and
                            center of Excellence -- Virtual Preceptorship
                          </h3>
                        </div>
                      </div>
                      <div class='col-lg-3 col-sm-12'>
                        <div class='schedule-table__speaker'>
                          <div class='schedule-table__speaker__info'>
                            <div class='schedule-table__speaker__name'>
                              <p>Broadcast from center of Excellence in US</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id='tab-11' className='schedule-table'>
                    <div className='row wow flipInX'>
                      <div className='col-lg-3 col-sm-5'>
                        <div className='schedule-table__time'>
                          <h3>08:00PM – 08:10PM</h3>
                        </div>
                      </div>
                      <div className='col-lg-6 col-sm-7'>
                        <div className='schedule-table__event'>
                          <h3>Welcome remarks</h3>
                        </div>
                      </div>
                      <div className='col-lg-3 col-sm-12'>
                        <div className='schedule-table__speaker'>
                          <div className='schedule-table__speaker__info'></div>
                        </div>
                      </div>
                    </div>

                    <div className='row wow flipInX'>
                      <div className='col-lg-3 col-sm-5'>
                        <div className='schedule-table__time'>
                          <h3>08:10PM – 08:40PM</h3>
                        </div>
                      </div>
                      <div className='col-lg-6 col-sm-7'>
                        <div className='schedule-table__event'>
                          <h3>
                            SGLT2 inhibitors - Cardio Renal Link & outcomes
                          </h3>
                        </div>
                      </div>
                      <div className='col-lg-3 col-sm-12'>
                        <div className='schedule-table__speaker'>
                          <div className='schedule-table__speaker__info'>
                            <img src={mcGuireImg} alt='Darren McGuire' />
                            <div className='schedule-table__speaker__name'>
                              <p>Darren McGuire</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row wow flipInX'>
                      <div className='col-lg-3 col-sm-5'>
                        <div className='schedule-table__time'>
                          <h3>008:40PM – 09:10PM</h3>
                        </div>
                      </div>
                      <div className='col-lg-6 col-sm-7'>
                        <div className='schedule-table__event'>
                          <h3>
                            The foundational approach - How should we start?
                          </h3>
                        </div>
                      </div>
                      <div className='col-lg-3 col-sm-12'>
                        <div className='schedule-table__speaker'>
                          <div className='schedule-table__speaker__info'>
                            <img src={javedButlerImg} alt='Steven Kahn ' />
                            <div className='schedule-table__speaker__name'>
                              <p>Javed Butler</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row wow flipInX'>
                      <div className='col-lg-3 col-sm-5'>
                        <div className='schedule-table__time'>
                          <h3>09:10PM – 09:40PM </h3>
                        </div>
                      </div>
                      <div className='col-lg-6 col-sm-7'>
                        <div className='schedule-table__event'>
                          <h3>Managing Heart Failure in COVID times</h3>
                        </div>
                      </div>
                      <div className='col-lg-3 col-sm-12'>
                        <div className='schedule-table__speaker'>
                          <div className='schedule-table__speaker__info'>
                            <img
                              src={mariamAlQaseerImg}
                              alt='Maryam AlQasser'
                            />
                            <div className='schedule-table__speaker__name'>
                              <p>Maryam AlQasser</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row wow flipInX'>
                      <div className='col-lg-3 col-sm-4'>
                        <div className='schedule-table__time'>
                          <h3>09:40PM – 10:10PM</h3>
                        </div>
                      </div>
                      <div className='col-lg-6 col-sm-8'>
                        <div className='schedule-table__event'>
                          <h3>Q&A</h3>
                        </div>
                      </div>
                      <div className='col-lg-3 col-sm-12'>
                        <div className='schedule-table__speaker'>
                          <div className='schedule-table__speaker__info'>
                            <div className='schedule-table__speaker__name'>
                              <p>All Faculty </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id='tab-12' className='schedule-table'>
                    <div className='row wow flipInX'>
                      <div className='col-lg-3 col-sm-5'>
                        <div className='schedule-table__time'>
                          <h3>08:00PM – 08:10PM</h3>
                        </div>
                      </div>
                      <div className='col-lg-6 col-sm-7'>
                        <div className='schedule-table__event'>
                          <h3>Welcome remarks</h3>
                        </div>
                      </div>
                      <div className='col-lg-3 col-sm-12'>
                        <div className='schedule-table__speaker'>
                          <div className='schedule-table__speaker__info'></div>
                        </div>
                      </div>
                    </div>
                    <div className='row wow flipInX'>
                      <div className='col-lg-3 col-sm-5'>
                        <div className='schedule-table__time'>
                          <h3>08:10PM – 08:40PM </h3>
                        </div>
                      </div>
                      <div className='col-lg-6 col-sm-7'>
                        <div className='schedule-table__event'>
                          <h3>
                            Challenges in management - HFrEF clinical Case study
                          </h3>
                        </div>
                      </div>
                      <div className='col-lg-3 col-sm-12'>
                        <div className='schedule-table__speaker'>
                          <div className='schedule-table__speaker__info'>
                            <img src={michaelBohmImg} alt='Michael Böhm' />
                            <div className='schedule-table__speaker__name'>
                              <p>Michael Böhm</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row wow flipInX'>
                      <div className='col-lg-3 col-sm-5'>
                        <div className='schedule-table__time'>
                          <h3>08:40PM – 09:10PM</h3>
                        </div>
                      </div>
                      <div className='col-lg-6 col-sm-7'>
                        <div className='schedule-table__event'>
                          <h3>
                            Challenges in management - HFpEF clinical Case study
                          </h3>
                        </div>
                      </div>
                      <div className='col-lg-3 col-sm-12'>
                        <div className='schedule-table__speaker'>
                          <div className='schedule-table__speaker__info'>
                            <img src={andrewCoatsImg} alt='Andrew Coats' />
                            <div className='schedule-table__speaker__name'>
                              <p>Andrew Coats</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row wow flipInX'>
                      <div className='col-lg-3 col-sm-5'>
                        <div className='schedule-table__time'>
                          <h3>09:10PM – 09:40PM</h3>
                        </div>
                      </div>
                      <div className='col-lg-6 col-sm-7'>
                        <div className='schedule-table__event'>
                          <h3>
                            Challenges in management - Acute HF clinical Case
                            study
                          </h3>
                        </div>
                      </div>
                      <div className='col-lg-3 col-sm-12'>
                        <div className='schedule-table__speaker'>
                          <div className='schedule-table__speaker__info'>
                            <img src={mehmetImg} alt='Mehmet Birhan Yilmaz' />
                            <div className='schedule-table__speaker__name'>
                              <p>Mehmet Birhan Yilmaz</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row wow flipInX'>
                      <div className='col-lg-3 col-sm-4'>
                        <div className='schedule-table__time'>
                          <h3>09:40PM – 10:10PM</h3>
                        </div>
                      </div>
                      <div className='col-lg-6 col-sm-8'>
                        <div className='schedule-table__event'>
                          <h3>Q&A</h3>
                        </div>
                      </div>
                      <div className='col-lg-3 col-sm-12'>
                        <div className='schedule-table__speaker'>
                          <div className='schedule-table__speaker__info'>
                            <div className='schedule-table__speaker__name'>
                              <p>All Faculty </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        {/* AGENDA POPUP */}
      </div>
    )
  }
}
