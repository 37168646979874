/* eslint-disable jsx-a11y/no-distracting-elements */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import Footer from "../footer/footer-page";
import videojs from "video.js";
// import "./webinar-hall-page.css";
import LoadingPageBtn from "../loading/loading-page-btn";
import ChatPanel from "../chatpanel/ChatPanel";
import fullscreenButton from "../images/fullscreenicon.png";
import dbFirebaseApp from "../functions/features/db-firebase-app";
import "../../videojsfiletest.css";
import 'video.js/dist/video-js.css';
import PortraitModeWarning from "../PortraitModeWarning";
import { updateLastSceneEndTime } from "../functions/helpers/analytics-function";

const roomName = "webinarHall";
const expoName = "medex2022";

let iOS = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);

const liveFormat = "application/x-mpegURL";

const webinarVideo =
  "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/medex-2022/medex-session.mp4";


export default class lighter2dWebinar extends Component {
  state = {
    isloading: true,
    startAudio: true,
    shouldShowNotification: false,
    notificationMsg: "",
    isBtnShow: true,
    isLighterBtn: false,
    isMarqueeShow: false,
    isLive: false,
  };

  componentDidMount() {
    if (
      localStorage.getItem("userRole") === "user" &&
      localStorage.getItem("uid") !== ""
    ) {
      console.warn("welcome");
    } else {
      window.location.href = "/";
    }

    updateLastSceneEndTime(roomName);
    localStorage.setItem("Location", "webinar-hall");
    this.getUserDetails();
  }

  changeStateForLoader = (value) => {
    if (document.getElementById("webinarLighterVideoCenter")) {
      document.getElementById("webinarLighterVideoCenter").play();
    }
    this.setState({ isloading: value });
  };

  endVideo = (e) => {
    let videoPlayer = document.getElementById("webinarHall");
    videoPlayer.pause();
    videoPlayer.style.display = "none";

  };

  openFullscreen = () => {
    let elem = document.getElementById("video-player1");
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitEnterFullscreen) {
      elem.webkitEnterFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
    videojs("video-player1").volume(1);
  };

  getUserDetails() {
    let self = this;
    const db = dbFirebaseApp.firestore();
    const userCollection = db.collection('MedexLive');
    let profilePicArray = [];
    userCollection.where('expo', '==', expoName)
      .onSnapshot(function (querySnapshot) {
        querySnapshot.docChanges().forEach(function (change) {

          if (change.doc.data().poster === 'true') {
            document.getElementById('webinarLighterVideoCenter').style.display = "block"

            if (change.doc.data().marquee === 'true') {
              self.setState({ isMarqueeShow: true })
              console.warn('marquee state is ::', self.state.isMarqueeShow)
            } else if (change.doc.data().marquee === 'false') {
              self.setState({ isMarqueeShow: false })
              console.warn('marquee state is ::', self.state.isMarqueeShow)
            }
          } else if (change.doc.data().poster === 'false') {
            document.getElementById('webinarLighterVideoCenter').style.display = "none"
            if (change.doc.data().marquee === 'true') {
              self.setState({ isMarqueeShow: true })
              console.warn('marquee state is ::', self.state.isMarqueeShow)
            } else if (change.doc.data().marquee === 'false') {
              self.setState({ isMarqueeShow: false })
              console.warn('marquee state is ::', self.state.isMarqueeShow)
            }
          }
          if (change.type === "modified") {
            console.log("Modified city: ", change.doc.data());
            console.log("New city: ", change.doc.data());
            if (change.doc.data().poster === 'true') {
              document.getElementById('webinarLighterVideoCenter').style.display = "block"
              if (change.doc.data().marquee === 'true') {
                self.setState({ isMarqueeShow: true })
              } else if (change.doc.data().marquee === 'false') {
                self.setState({ isMarqueeShow: false })
              }
            } else if (change.doc.data().poster === 'false') {
              document.getElementById('webinarLighterVideoCenter').style.display = "none"
              if (change.doc.data().marquee === 'true') {
                self.setState({ isMarqueeShow: true })
              } else if (change.doc.data().marquee === 'false') {
                self.setState({ isMarqueeShow: false })
              }
            }
          }
        });
      });

  }

  render() {
    return (
      <div>
        <PortraitModeWarning />
        <div>
          {this.state.isMarqueeShow && (
            <marquee className="marquee-bottom">
              You can ask your questions in the ASK YOUR QUESTIONS box
            </marquee>
          )}
          {this.state.isloading && (
            <LoadingPageBtn
              updateStateForLoader={this.changeStateForLoader}
              btnStatus={this.state.isBtnShow}
              lighterBtn={this.state.isLighterBtn}
            />
          )}

          {/* <Header headerType="user" /> */}
          <ChatPanel roomName="webinarMEDEX" />
          <div id="lighterVideo">

          {/* <video
            id="video-player1"
            className="video-js vjs-default-skin video2dHtmlEl"
            crossOrigin="anonymous"
            loop
            webkit-playsinline="true"
            playsInline
            controls={iOS}
            src=""
            style={{ position: "absolute", width: "100vw", maxHeight: "100vh", top: "0", left: "0" }}
          ></video> */}
          </div>
         
          <video
            id="webinarLighterVideoCenter"
            webkit-playsinline="true"
            playsInline
            loop
            preload="auto"
            src={webinarVideo}
            alt="entrancevideo"
          />
          {/* <div id="fullscreenBtn">
            <img className="fullscreenBtn" src={fullscreenButton} onClick={this.openFullscreen} alt="fullscreen btn" />
          </div> */}
          {/* <div className="fullScreen">
            <img
              className="webinarHall2d"
              src={webinarImg}
              alt="2dwebinar image"
            />
          </div> */}
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}
