import React, { Component } from 'react'
import Footer from '../footer/footer-page'
import zoomInBtn from '../images/zoom in.png';
import zoomOutBtn from '../images/zoom out.png';
import backArrow from '../images/button arrow-1.png';
import dbFirebaseApp from '../functions/features/db-firebase-app';
import authFirebaseApp from '../functions/features/auth-firebase-app';
import '../functions/helpers/c-zoom-in-out'
import "aframe";
import "aframe-html-shader";
import ChatPanel from '../chatpanel/ChatPanel'
import startCameraImg from '../images/medexIcon/camera-med.png';
import stopCameraImg from '../images/medexIcon/off-cam-med.png';
import startMicImg from '../images/medexIcon/unmute-med.png';
import stopMicImg from '../images/medexIcon/mute-med.png';
import screenshareImg from '../images/medexIcon/SCREEN-SHARE-MED.png';
import stopScreenshareImg from '../images/medexIcon/STOP-SS-MED.png';
import webinarPoster from '../images/webinar-poster.jpg';
import raiseHandImg from '../images/hand.png';
import cancelRaiseHandImg from '../images/lower hand1.png';
import { isAlreadyExistInArray, removeEntry, SetAllImages } from '../functions/helpers/extra-functions';
import Header from '../header/header';
import LoadingPage from '../loading/loading-page';
import { analyticsFunction } from '../functions/helpers/analytics-function';
import firebaseOther from '../functions/features/firebase-app';
import CustomNotification from '../helpers/notification/notification';
import PortraitModeWarning from '../PortraitModeWarning';

let isFirstTimeLoweredHand = true;
let isScreenshareStarted = false;
let raiseHandStatus = false;

const sceneName = 'breakout1';
const BreakoutSky = 'https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image360/angels/kv-breakout1.jpg'
const roomName = 'breakout'
export default class BreakoutPage extends Component {

    state = {
        commingUid: '',
        isloading: true,
        shouldShowNotification: false,
        notificationMsg: '',
    }

    componentDidMount() {
        let self = this;
        window.AFRAME.registerComponent("sky-breakout1-component", {
            init: function () {
                let el = this.el;
                el.addEventListener("loaded", (e) => {
                    console.log('sky showed')
                    self.setState({
                        isloading: false
                    })
                });
            },
        });
        authFirebaseApp.auth().onAuthStateChanged((user) => {
            if (user) {
                console.warn('user is ', user)
                var uid = user.uid;
                this.setState({ commingUid: uid });
                console.warn('state uid is ::', this.state.commingUid)
                // ...
            }
            else {
                console.warn('user not found')
                window.location.href = '/'
            }
        });
        localStorage.setItem('Location', 'breakout-page')
        localStorage.setItem('sceneName', sceneName)


        if (localStorage.getItem('userRole') === 'speaker') {
            document.getElementById('screenshareBtn').setAttribute('visible', true);
            document.getElementById('screenshareBtn').setAttribute('position', '1.2 -0.9 -10');
            document.getElementById('raiseHandIcon').setAttribute('visible', false);
            document.getElementById('raiseHandIcon').setAttribute('position', '0 20 0');
        } else {
            if (localStorage.getItem('docRef') && localStorage.getItem('docRef') !== '') {
                this.updateRaiseHandDoc(false);
            } else {
                this.createRaiseHandDoc();
            }
        }
        analyticsFunction(roomName)
    }
    goBackToLobby = () => {
        window.location.href = '/entrance'
    }


    toggleCameraView = () => {
        if (document.querySelector('#myCameraBreakout1Room').getAttribute('fov') === '50') {
            document.querySelector('#myCameraBreakout1Room').setAttribute('fov', '32.5');
            document.getElementById("Zoom-In").setAttribute('visible', 'false')
            document.getElementById("Zoom-Out").setAttribute('visible', 'true')
        } else {
            document.querySelector('#myCameraBreakout1Room').setAttribute('fov', '50');
            document.getElementById("Zoom-Out").setAttribute('visible', 'false')
            document.getElementById("Zoom-In").setAttribute('visible', 'true')
        }
    }

    getUserDetails(sceneName) {
        const db = dbFirebaseApp.firestore();
        const userCollection = db.collection('AngelsKnowledgeVillage');
        let profilePicArray = [];
        userCollection.where('sceneName', '==', sceneName)
            .onSnapshot(function (querySnapshot) {
                querySnapshot.docChanges().forEach(function (change) {
                    if (change.type === "added" && change.type !== "modified") {
                        console.log("New city: ", change.doc.data());
                        let myData = change.doc.data();
                        myData.id = change.doc.id;
                        if (!isAlreadyExistInArray(profilePicArray, myData.uid)) {
                            profilePicArray.push(myData)
                        }

                        setTimeout(() => {
                            let imageElement = document.createElement('img')
                            let aimageElement = document.createElement('a-image')
                            let atextElement = document.createElement('a-text')
                            let aentityElement = document.createElement('a-entity')
                            imageElement.setAttribute('id', '1img-' + myData.uid)
                            aimageElement.setAttribute('id', '1aimg-' + myData.uid)
                            atextElement.setAttribute('id', '1atext-' + myData.uid)
                            aentityElement.setAttribute('id', '1aentity-' + myData.uid)
                            atextElement.setAttribute('value', myData.name)
                            atextElement.setAttribute('color', "black")
                            imageElement.setAttribute('crossOrigin', 'anonymous')
                            imageElement.setAttribute('src', myData.profilePicUrl)
                            document.getElementById('breakoutAssets').appendChild(imageElement)
                            aentityElement.appendChild(aimageElement)
                            aentityElement.appendChild(atextElement)
                            atextElement.setAttribute('position', '-0.5 -0.5 0');
                            aimageElement.setAttribute('scale', '0.85 0.85 0.85');
                            atextElement.setAttribute('scale', '0.75 0.75 0.75');

                            document.getElementById('asceneBreakout').appendChild(aentityElement)
                            document.getElementById('1aimg-' + myData.uid).setAttribute('src', '#1img-' + myData.uid)
                            SetAllImages(profilePicArray, '1aentity-');
                        }, 1000);
                    }
                    if (change.type === "modified") {
                        console.log("Modified city: ", change.doc.data());
                    }
                    if (change.type === "removed" && change.type !== "modified") {
                        console.log("Removed city: ", change.doc.data());
                        let updatedArrray = removeEntry(profilePicArray, change.doc.data().uid)
                        console.warn('updated array is ;:', updatedArrray)
                        console.warn('remove 1', document.getElementById('1img-' + change.doc.data().uid))
                        if (document.getElementById('1img-' + change.doc.data().uid) &&
                            document.getElementById('1aimg-' + change.doc.data().uid) &&
                            document.getElementById('1aimg-' + change.doc.data().uid) &&
                            document.getElementById('1aimg-' + change.doc.data().uid) &&
                            document.getElementById('1aimg-' + change.doc.data().uid) &&
                            document.getElementById('1aimg-' + change.doc.data().uid) &&
                            document.getElementById("asceneBreakout")) {
                            document.getElementById('1img-' + change.doc.data().uid).remove();
                            var aImage = document.getElementById('1aentity-' + change.doc.data().uid);
                            document.getElementById("asceneBreakout").removeChild(aImage);
                        }
                    }

                });

            });

    }
    toggleMicMute = () => {
        let audioBtn = document.querySelector('.join-audio-container__btn');
        console.warn('area label is', audioBtn.getAttribute('aria-label'))

        if (document.querySelector('.join-audio-container__btn')) {
            document.querySelector('.join-audio-container__btn').click();
        }
    }

    dynamicAudioIconChange = () => {
        setInterval(() => {
            let audioBtn = document.querySelector('.join-audio-container__btn');
            if (audioBtn) {
                if (audioBtn.getAttribute('aria-label') === 'unmute my microphone') {
                    document.getElementById('audioBtn').setAttribute('src', '#micMuteImg')
                }
                else if (audioBtn.getAttribute('aria-label') === 'mute my microphone') {
                    document.getElementById('audioBtn').setAttribute('src', '#micUnmuteImg')
                }
            } else {
                console.warn('audio btn not found')
            }
        }, 1000);
    }

    dynamicCameraIconChange = () => {
        setInterval(() => {
            let videoBtn = document.querySelector('.send-video-container__btn')

            if (videoBtn.getAttribute('aria-label') === 'start sending my video') {
                document.getElementById('videoBtn').setAttribute('src', '#cameraUnmuteImg')
            }
            else if (videoBtn.getAttribute('aria-label') === 'stop sending my video') {
                document.getElementById('videoBtn').setAttribute('src', '#cameraMuteImg')
            }
        }, 1000);
    }

    toggleCameraMute = () => {
        let videoBtn = document.querySelector('.send-video-container__btn')
        console.warn('area label of camera is ::', videoBtn.getAttribute('aria-label'))
        if (document.querySelector('.send-video-container__btn')) {
            document.querySelector('.send-video-container__btn').click();
        }
        if (videoBtn.getAttribute('aria-label') === 'start sending my video') {
            document.getElementById('videoBtn').setAttribute('src', '#cameraUnmuteImg')
        }
        else if (videoBtn.getAttribute('aria-label') === 'stop sending my video') {
            document.getElementById('videoBtn').setAttribute('src', '#cameraMuteImg')
        }
    }

    toggleScreenshare = () => {
        if (isScreenshareStarted) {
            this.stopScreenshare();
        } else {
            this.startScreenshare();
        }
    }

    startScreenshare = () => {
        if (document.getElementsByClassName('footer-button__button')[1]) {
            document.getElementsByClassName('footer-button__button')[1].click();
            setTimeout(() => {
                this.toggleCameraMute();
            }, 15000);
        }
        isScreenshareStarted = !isScreenshareStarted;

        document.getElementById('screenshareBtn').setAttribute('src', isScreenshareStarted ? '#stopScreenshareImg' : '#screenshareImg');
    }

    stopScreenshare = () => {
        if (document.querySelector('.sharer-button--pattern1')) {
            document.querySelector('.sharer-button--pattern1').click();
        }
        isScreenshareStarted = !isScreenshareStarted;
        document.getElementById('screenshareBtn').setAttribute('src', isScreenshareStarted ? '#stopScreenshareImg' : '#screenshareImg');

    }

    toggleRaiseHand = () => {
        if (raiseHandStatus === true) {
            this.updateRaiseHandDoc(false);
        } else {
            this.updateRaiseHandDoc(true);
        }
    }


    createRaiseHandDoc = () => {
        firebaseOther.firestore().collection("MetaILDLiveUser").add({
            name: localStorage.getItem('name'),
            sceneName: localStorage.getItem('Location'),
            uid: localStorage.getItem('uid'),
            isRaisedHand: 'false'
        })
            .then(function (docRef) {
                console.log("Document written with ID: ", docRef.id);
                localStorage.setItem('raiseHandDocID', docRef.id);
            })
            .catch(function (error) {
                console.error("Error adding document: ", error);
            });
    }

    updateRaiseHandDoc = (myRaiseHandStatus) => {
        let self = this;
        let raiseHandDocRef = firebaseOther.firestore().collection("MetaILDLiveUser").doc(localStorage.getItem('docRef'));

        raiseHandDocRef.update({
            isRaisedHand: myRaiseHandStatus.toString()
        })
            .then(function () {
                raiseHandStatus = myRaiseHandStatus;
                console.log("Document successfully updated!");
                if (myRaiseHandStatus === true) {
                    document.getElementById('raiseHandIcon').setAttribute('src', '#raiseHand');
                    self.setState({ notificationMsg: 'You have raised your hand', shouldShowNotification: true });
                    setTimeout(() => {
                        self.setState({ shouldShowNotification: false });
                    }, 5000);
                } else {
                    document.getElementById('raiseHandIcon').setAttribute('src', '#cancelRaiseHand');
                    if (isFirstTimeLoweredHand === false) {
                        self.setState({ notificationMsg: 'You have lowered your hand', shouldShowNotification: true });
                        setTimeout(() => {
                            self.setState({ shouldShowNotification: false });
                        }, 5000);
                    } else {
                        isFirstTimeLoweredHand = false;
                    }
                }
            })
            .catch(function (error) {
                console.error("Error updating document: ", error);
            });
    }


    joinAudio = () => {
        if (document.getElementsByClassName('join-audio-by-voip__join-btn')[0]) {
            document.getElementsByClassName('join-audio-by-voip__join-btn')[0].click();
        }
        if (document.querySelector('.join-audio-by-voip__join-btn')) {
            document.querySelector('.join-audio-by-voip__join-btn').click();
        }
        document.getElementById('joinAudioPopup').style.display = 'none';
        document.getElementsByClassName('sharee-container__viewport')[0].removeAttribute('style');
        setTimeout(() => {
            document.getElementById('display-box').removeAttribute('src');
            document.getElementById('display-box').setAttribute('color', 'white');
            document.getElementById('display-box').setAttribute('material', 'shader: html; target: #zmmtg-root; fps: 10');
            this.dynamicAudioIconChange();
        }, 1000);
    }

    joinUsingZoomLink = () => {
        window.open('https://zoom.us/j/98635820417?pwd=cUdNS09kSTR2V3pLTkg1UDA5R3d2dz09', '_blank');
    }

    render() {
        return (
            <div>
                <PortraitModeWarning />
                {this.state.isloading && <LoadingPage />}
                {this.state.shouldShowNotification && <CustomNotification msg={this.state.notificationMsg} />}
                <Header headerType='user' />
                <div id="joinAudioPopup" className='AudioJoinPopup' style={{ display: 'none' }}>
                    <h5>Please join webinar using your device audio</h5>
                    <br />
                    <div id="preparationText" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>Preparing to join webinar &nbsp;&nbsp;
                        <div class="loaderBtn"></div>
                    </div>
                    <button onClick={this.joinAudio} className="joinBtn" id="myJoinWebinarBtn" style={{ display: 'none' }}>Join Webinar</button>
                </div>
                <a-scene id="asceneBreakout" loading-screen="enabled: false" vr-mode-ui="enabled: false" style={{ zIndex: 2 }}>
                    <a-assets id="breakoutAssets">
                        <img id="sky" crossOrigin="anonymous" src={BreakoutSky} alt="background" />
                        <img id="backArrowBreakoutNurse" src={backArrow} alt='back' />
                        <img id="zoomInBtn" src={zoomInBtn} alt="Zoom In Button" />
                        <img id="zoomOutBtn" src={zoomOutBtn} alt="Zoom Out Button" />
                        <video
                            id="video-player-nurses"
                            className="video-js vjs-default-skin video2dHtmlEl"
                            crossOrigin="anonymous"
                            loop
                            webkit-playsinline="true"
                            playsInline
                            src="">
                        </video>
                        <img id="micMuteImg" src={stopMicImg} alt="Stop Mic" />
                        <img id="micUnmuteImg" src={startMicImg} alt="Start Mic" />
                        <img id="cameraMuteImg" src={stopCameraImg} alt="Stop Camera" />
                        <img id="cameraUnmuteImg" src={startCameraImg} alt="Start Camera" />
                        <img id="screenshareImg" src={screenshareImg} alt="ss" />
                        <img id="raiseHand" src={raiseHandImg} alt="raiseHandIcon" />
                        <img id="cancelRaiseHand" src={cancelRaiseHandImg} alt="CancelRaiseHandIcon" />
                        <img id="stopScreenshareImg" src={stopScreenshareImg} alt="sss" />
                        <img id="webinarPoster" src={webinarPoster} alt="Event Poster" />
                    </a-assets>
                    <a-camera look-controls="disableVerticalMotion:true; magicWindowTrackingEnabled: false; gyroModeEnabled: false; reverseTouchDrag: true;" id="myCameraBreakout1Room" c-zoom-in-out="defaultFov: 65" fov="65" wasd-controls="enabled:false">
                        <a-entity
                            cursor="rayOrigin: mouse; fuse: false; disableDragClick: true"
                            raycaster="objects: .raycaster-solid; recursive: false;"
                        />
                    </a-camera>

                    <a-sky src="#sky" rotation="0 0 0" sky-breakout1-component></a-sky>
                    <a-image src="#backArrowBreakoutNurse" id="entertainment-back" position="-15.5 2.4 9" scale="1.5 1.5 1.5" width="2" height='1' look-at="#myCameraBreakout1Room" class="raycaster-solid clickable" onClick={this.goBackToLobby}></a-image>
                    <a-image src="#zoomInBtn" id="Zoom-In" position="5 0.7 -8" rotation="0 -5 0" height="1" width="1" class="raycaster-solid clickable" onClick={this.toggleCameraView} />
                    <a-image src="#zoomOutBtn" id="Zoom-Out" position="5 0.7 -8" rotation="0 -5 0" height="1" width="1" class="raycaster-solid clickable" onClick={this.toggleCameraView} visible={false} />
                    <a-image src="#micMuteImg" id="audioBtn" position="-1.2 -0.9 -10" rotation="0 -5 0" scale="1 1 1" height="1" width="1" class="raycaster-solid clickable" onClick={this.toggleMicMute} />
                    <a-image src="#cameraMuteImg" id="videoBtn" position="0 -0.9 -10" rotation="0 -5 0" scale="1 1 1" height="1" width="1" class="raycaster-solid clickable" onClick={this.toggleCameraMute} />
                    <a-image src="#cancelRaiseHand" id="raiseHandIcon" position="1.2 -0.9 -10" rotation="0 -5 0" scale="1 1 1" height="1" width="1" class="raycaster-solid clickable" onClick={this.toggleRaiseHand} />
                    <a-image src="#screenshareImg" id="screenshareBtn" visible='false' position="0 20 0" rotation="0 -5 0" scale="1 1 1" height="1" width="1" class="raycaster-solid clickable" onClick={this.toggleScreenshare} />
                    <a-image src="#webinarPoster" id="display-box" visible={true} position="-0.1 2.3 -10" scale="11.15 5.4 1" class="raycaster-solid clickable" onClick={this.toggleCameraView} />
                </a-scene>
                <ChatPanel roomName="knowledgeVillageRoom1" />
                <Footer />

            </div>
        )
    }
}
