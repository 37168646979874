/* eslint-disable jsx-a11y/no-distracting-elements */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import Footer from "../footer/footer-page";
import videojs from "video.js";
// import "./webinar-hall-page.css";
import Header from "../header/header";
// import webinarImg from "../images/webinar 2022.png";
import webinarImg from "../images/webinar 2022_1.png";
import LoadingPageBtn from "../loading/loading-page-btn";
import ChatPanel from "../chatpanel/ChatPanel";
import fullscreenButton from "../images/fullscreenicon.png";
import dbFirebaseApp from "../functions/features/db-firebase-app";
import "../../videojsfiletest.css";
import 'video.js/dist/video-js.css';
import PortraitModeWarning from "../PortraitModeWarning";
import { updateLastSceneEndTime } from "../functions/helpers/analytics-function";

const roomName = "webinarHall";
const expoName = "medex2022";

let iOS = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);

const webinarVideo =
  "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/medex-2022/medex-session.mp4";


export default class webinar2dHall extends Component {
  state = {
    isloading: true,
    startAudio: true,
    shouldShowNotification: false,
    notificationMsg: "",
    isBtnShow: false,
    isLighterBtn: false,
    isMarqueeShow: false,
    isLive: false,
  };

  componentDidMount() {
    if (
      localStorage.getItem("userRole") === "user" &&
      localStorage.getItem("uid") !== ""
    ) {
      console.warn("welcome");
      document
        .getElementById("webinarHall")
        .addEventListener("ended", this.endVideo, false);

      document
        .getElementById("webinarHall").addEventListener('canplaythrough', (event) => {
          console.log("Called in webinar")
          this.setState({
            isBtnShow: true,
          });
        });
    } else {
      window.location.href = "/";
    }

    updateLastSceneEndTime(roomName);

    
    localStorage.setItem("Location", "webinar-hall");


    this.getUserDetails();
  }

  changeStateForLoader = (value) => {
    if (document.getElementById("webinarVideoCenter")) {
      document.getElementById("webinarVideoCenter").play();
    }

    this.setState({ isloading: value });

    let videoPlayer = document.getElementById("webinarHall");
    videoPlayer.style.display = "block";
    videoPlayer.volume = 1.0;
    videoPlayer.play();

  };

  endVideo = (e) => {
    let videoPlayer = document.getElementById("webinarHall");
    videoPlayer.pause();
    videoPlayer.style.display = "none";
  };

  openFullscreen = () => {
    let elem = document.getElementById("video-player1");
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitEnterFullscreen) {
      elem.webkitEnterFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
    videojs("video-player1").volume(1);
  };

  getUserDetails() {
    let self = this;
    const db = dbFirebaseApp.firestore();
    const userCollection = db.collection('MedexLive');
    // let ExistingUserQuery = new Promise((resolve, reject) => {
    let profilePicArray = [];
    userCollection.where('expo', '==', expoName)
      .onSnapshot(function (querySnapshot) {
        querySnapshot.docChanges().forEach(function (change) {

          if (change.doc.data().poster === 'true') {
            document.getElementById('webinarVideoCenter').style.display = "block"
            if(!iOS){
              document.getElementById('fullscreenBtn').style.display = "none"
            }

            if (change.doc.data().marquee === 'true') {
              self.setState({ isMarqueeShow: true })
              console.warn('marquee state is ::', self.state.isMarqueeShow)
            } else if (change.doc.data().marquee === 'false') {
              self.setState({ isMarqueeShow: false })
              console.warn('marquee state is ::', self.state.isMarqueeShow)
            }
            if (change.doc.data().lighterBtn === 'true') {
              self.setState({ isLighterBtn: true })
            } else if (change.doc.data().lighterBtn === 'false') {
              self.setState({ isLighterBtn: false })
            }
          } else if (change.doc.data().poster === 'false') {
            document.getElementById('webinarVideoCenter').style.display = "none"
            if(!iOS){
              document.getElementById('fullscreenBtn').style.display = "block"
            }
            if (change.doc.data().marquee === 'true') {
              self.setState({ isMarqueeShow: true })
              console.warn('marquee state is ::', self.state.isMarqueeShow)
            } else if (change.doc.data().marquee === 'false') {
              self.setState({ isMarqueeShow: false })
              console.warn('marquee state is ::', self.state.isMarqueeShow)
            }
            if (change.doc.data().lighterBtn === 'true') {
              self.setState({ isLighterBtn: true })
            } else if (change.doc.data().lighterBtn === 'false') {
              self.setState({ isLighterBtn: false })
            }
          }
          if (change.type === "modified") {
            console.log("Modified city: ", change.doc.data());
            console.log("New city: ", change.doc.data());
            if (change.doc.data().poster === 'true') {
              document.getElementById('webinarVideoCenter').style.display = "block"
              if(!iOS){
                document.getElementById('fullscreenBtn').style.display = "none"
              }
              if (change.doc.data().marquee === 'true') {
                self.setState({ isMarqueeShow: true })
              } else if (change.doc.data().marquee === 'false') {
                self.setState({ isMarqueeShow: false })
              }
              if (change.doc.data().lighterBtn === 'true') {
                self.setState({ isLighterBtn: true })
              } else if (change.doc.data().lighterBtn === 'false') {
                self.setState({ isLighterBtn: false })
              }
            } else if (change.doc.data().poster === 'false') {
              document.getElementById('webinarVideoCenter').style.display = "none"
              if(!iOS){
                document.getElementById('fullscreenBtn').style.display = "block"
              }
              if (change.doc.data().marquee === 'true') {
                self.setState({ isMarqueeShow: true })
              } else if (change.doc.data().marquee === 'false') {
                self.setState({ isMarqueeShow: false })
              }
              if (change.doc.data().lighterBtn === 'true') {
                self.setState({ isLighterBtn: true })
              } else if (change.doc.data().lighterBtn === 'false') {
                self.setState({ isLighterBtn: false })
              }
            }
          }
        });
      });

  }

  render() {
    return (
      <div>
        <PortraitModeWarning />
        <div>
          <video
            style={{
              display: "none",
              position: "fixed",
              width: "100vw",
              height: "100vh",
              right: "0",
              bottom: "0",
              objectFit: "fill",
              background: "black",
              zIndex: 2100,
            }}
            id="webinarHall"
            src={"https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/medex-2022/webinarHall.mp4"}
            type="video/mp4"
            className="mainvideoEle"
            playsInline
            //   loop
            //   muted
            //   autoPlay
            controlsList="nodownload"
            onContextMenu={(e) => {
              e.preventDefault();
              return false;
            }}
          />


          {this.state.isMarqueeShow && (
            <marquee className="marquee-bottom">
              You can ask your questions in the ASK YOUR QUESTIONS box
            </marquee>
          )}
          {this.state.isloading && (
            <LoadingPageBtn
              updateStateForLoader={this.changeStateForLoader}
              btnStatus={this.state.isBtnShow}
              lighterBtn={this.state.isLighterBtn}
            />
          )}

          <Header headerType="user" />
          <ChatPanel roomName="webinarMEDEX" />         
          <video
            id="webinarVideoCenter"
            webkit-playsinline="true"
            playsInline
            loop
            preload="auto"
            src={webinarVideo}
            alt="entrancevideo"
            // controls="true"
            muted
            autoPlay
          />
          {!iOS && 
          <div id="fullscreenBtn">
            <img className="fullscreenBtn" src={fullscreenButton} onClick={this.openFullscreen} alt="fullscreen btn" />
          </div>}
          <div className="fullScreen">
            <img
              className="webinarHall2d"
              src={webinarImg}
              alt="2dwebinar image"
            />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
