
import React from 'react';
import './notAuthorized.css'
class NotAuthorized extends React.Component {


    constructor() {
        super();
        this.state = {
        };
    
      }
    componentDidMount(){
    }
    render() {

        return (
                <div id="not__authorized">
                    <p id="error">You are not Allowed</p>
                </div>
        );
    }
}
export default NotAuthorized;