import firebaseApp from '../firebase-app';
import approveTickImg from '../../../images/done-simple.png';

// Template for messages.
var MESSAGE_TEMPLATE =
    '<div class="single-message">' +
    '<div class="spacing"></div>' +
    '<div class="name"></div>' +
    '<div class="message"></div>' +
    '</div>';

// Loads chat messages history and listens for upcoming ones.
export function loadMessages(roomName) {
    // Create the query to load the last 12 messages and listen for new ones.
    // let currentActiveRoom = localStorage.getItem('roomName');
    console.warn('RoomName', roomName);
    var query = firebaseApp.firestore()
        .collection('Medex2022').doc('Chat').collection('Messages')
        .where('roomName', '==', roomName)
        .where('status', '==', 'normal')
        .orderBy('timestamp', 'desc');
    // .limit(15);

    // Start listening to the query.
    query.onSnapshot(function (snapshot) {
        snapshot.docChanges().forEach(function (change) {
            if (change.type === 'removed') {
                // console.warn('Removing', change.doc.data().text)
                deleteMessage(change.doc.id);
            } else if (change.type === 'added') {
                var message = change.doc.data();
                // console.warn('Adding', message.message)
                displayMessage(change.doc.id, message.timestamp, message.name, message.message, roomName);
            } else {
                // console.warn('Other event', change.type);
            }
        });
    });
}


// Loads chat messages history and listens for upcoming ones.
export function loadOnlyApprovedMessages(roomName) {
    // Create the query to load the last 12 messages and listen for new ones.
    // let currentActiveRoom = localStorage.getItem('roomName');
    console.warn('RoomName', roomName);
    var query = firebaseApp.firestore()
        .collection('Medex2022').doc('Chat').collection('Messages')
        .where('roomName', '==', roomName)
        .where('status', '==', 'approved')
        .orderBy('timestamp', 'desc');
    // .limit(15);

    // Start listening to the query.
    query.onSnapshot(function (snapshot) {
        snapshot.docChanges().forEach(function (change) {
            if (change.type === 'removed') {
                // console.warn('Removing', change.doc.data().text)
                deleteMessage(change.doc.id);
            } else if (change.type === 'added') {
                var message = change.doc.data();
                // console.warn('Adding', message.message)
                displayMessage(change.doc.id, message.timestamp, message.name, message.message, roomName);
            } else {
                // console.warn('Other event', change.type);
            }
        });
    });
}

export function loadAttendeeMessages(roomName) {
    // Create the query to load the last 12 messages and listen for new ones.
    // let currentActiveRoom = localStorage.getItem('roomName');
    console.warn('RoomName', roomName);
    var query = firebaseApp.firestore()
        .collection('Medex2022').doc('Chat').collection('Messages')
        .where('roomName', '==', roomName)
        .where('status', '!=', 'pinned')
        .orderBy('status', 'asc')
        .orderBy('timestamp', 'desc');
    // .limit(15);

    // Start listening to the query.
    query.onSnapshot(function (snapshot) {
        snapshot.docChanges().forEach(function (change) {
            if (change.type === 'removed') {
                // console.warn('Removing', change.doc.data().text)
                deleteMessage(change.doc.id);
            } else if (change.type === 'added') {
                var message = change.doc.data();
                // console.warn('Adding', message.message)
                displayMessage(change.doc.id, message.timestamp, message.name, message.message, roomName, message.status);
            } else {
                // console.warn('Other event', change.type);
            }
        });
    });
}

// Loads chat messages history and listens for upcoming ones.
export function loadModeratorMessages(roomName) {
    // Create the query to load the last 12 messages and listen for new ones.
    // let currentActiveRoom = localStorage.getItem('roomName');
    var query = firebaseApp.firestore()
        .collection('Medex2022').doc('Chat').collection('Messages')
        .where('roomName', '==', roomName)
        .where('status', '==', 'normal')
        .orderBy('timestamp', 'desc');
    // .limit(15);

    // Start listening to the query.
    query.onSnapshot(function (snapshot) {
        snapshot.docChanges().forEach(function (change) {
            if (change.type === 'removed') {
                // console.warn('Removing', change.doc.data().text)
                deleteMessage(change.doc.id);
            } else if (change.type === 'added') {
                var message = change.doc.data();
                // console.warn('Adding', message.message)
                displayModeratorMessage(change.doc.id, message.timestamp, message.name, message.message);
            } else {
                // console.warn('Other event', change.type);
            }
        });
    });
}

// Delete a Message from the UI.
function deleteMessage(id) {
    var div = document.getElementById(id);
    // If an element for that message exists we delete it.
    if (div) {
        div.parentNode.removeChild(div);
    }
}

function displayMessage(id, timestamp, name, text, roomName, status = 'normal') {
    let div = document.getElementById(id) || createAndInsertMessage(id, timestamp);
    div.querySelector('.name').textContent = name;
    var messageElement = div.querySelector('.message');

    let myScroll = document.getElementById('myScrollingChat');
    if (myScroll) {
        myScroll.scrollTop = myScroll.scrollHeight;
    }

    if (localStorage.getItem('userRole') !== 'user') {
        if (document.getElementById('moderator')) {
            if (!document.getElementById('moderator').classList.contains('bottomtotop')) {
                document.getElementById('moderator').classList.add('bottomtotop');
                document.getElementById("CloseChat").classList.remove("hidebtn");
                document.getElementById("OpenChat").classList.add("hidebtn");
            }
        }
        if (document.getElementById('moderator1')) {
            if (!document.getElementById('moderator1').classList.contains('bottomtotop')) {
                document.getElementById('moderator1').classList.add('bottomtotop');
                document.getElementById("message-form").classList.add("bottomtotop");
            }
        }
    }

    if (text) { // If the message is text.
        messageElement.textContent = text;
        // Replace all line breaks by <br>.
        messageElement.innerHTML = messageElement.innerHTML.replace(/\n/g, '<br>');
    }

    if (localStorage.getItem('userRole') === 'moderator') {
        let pinElement = document.createElement('input');
        pinElement.classList.add('pinicon');
        pinElement.setAttribute('type', 'radio');
        // let pinIcon = document.createElement('img');
        // pinIcon.src = 'pinImage';
        // pinElement.textContent = 'pin'
        pinElement.addEventListener('click', () => pinSelectedChat(id, name, text, roomName));
        let parentDiv = document.createElement('div');
        parentDiv.setAttribute('class', 'attendee-controls');
        parentDiv.insertBefore(pinElement, parentDiv.childNodes[0]);

        let approveImg = document.createElement('img');
        approveImg.setAttribute('src', approveTickImg);
        approveImg.setAttribute('class', 'approve-icon');
        approveImg.setAttribute('id', 'approveIcon_' + id);
        approveImg.addEventListener('click', () => approveSelectedChat(id));
        parentDiv.insertBefore(approveImg, parentDiv.childNodes[1]);
        let approveText = document.createElement('div');
        approveText.setAttribute('class', 'approve-text');
        approveText.setAttribute('id', 'approveText_' + id);
        approveText.innerHTML = "Approved";
        parentDiv.insertBefore(approveText, parentDiv.childNodes[2]);
        approveText.style.display = 'none';
        div.insertBefore(parentDiv, div.childNodes[0]);
        if (status === 'approved') {
            approveText.style.display = 'block';
            approveImg.style.display = 'none';
        }
    }
}

function displayModeratorMessage(id, timestamp, name, text) {
    let div = document.getElementById(id) || createAndInsertMessageModerator(id, timestamp);
    div.querySelector('.name').textContent = name;
    var messageElement = div.querySelector('.message');
    let myScroll = document.getElementById('myScrollingChat2');
    if (myScroll) {
        myScroll.scrollTop = myScroll.scrollHeight;
    }
    if (text) { // If the message is text.
        messageElement.textContent = text;
        // Replace all line breaks by <br>.
        messageElement.innerHTML = messageElement.innerHTML.replace(/\n/g, '<br>');
    }

    if (localStorage.getItem('userRole') !== 'user') {
        if (document.getElementById('moderator')) {
            if (!document.getElementById('moderator').classList.contains('bottomtotop')) {
                document.getElementById('moderator').classList.add('bottomtotop');
            }
        }
        if (document.getElementById('moderator1')) {
            if (!document.getElementById('moderator1').classList.contains('bottomtotop')) {
                document.getElementById('moderator1').classList.add('bottomtotop');
                document.getElementById("message-form").classList.add("bottomtotop");
                document.getElementById("CloseChat").classList.remove("hidebtn");
                document.getElementById("OpenChat").classList.add("hidebtn");
            }
        }
    }

    // if (localStorage.getItem('userRole') === 'moderator') {
    //     let pinElement = document.createElement('div');
    //     pinElement.textContent = 'pin'
    //     pinElement.addEventListener('click', () => pinSelectedChat(id));
    //     div.appendChild(pinElement);

    // }
}

function createAndInsertMessageModerator(id, timestamp) {
    const container = document.createElement('div');
    container.innerHTML = MESSAGE_TEMPLATE;
    const div = container.firstChild;
    div.setAttribute('id', id);

    // If timestamp is null, assume we've gotten a brand new message.
    // https://stackoverflow.com/a/47781432/4816918
    timestamp = timestamp ? timestamp : Date.now();
    div.setAttribute('timestamp', timestamp);

    // figure out where to insert new message
    var messageListElement = document.getElementById('messages2');
    if (messageListElement) {
        const existingMessages = messageListElement.children;
        if (existingMessages.length === 0) {
            messageListElement.appendChild(div);
        } else {
            let messageListNode = existingMessages[0];

            while (messageListNode) {
                const messageListNodeTime = messageListNode.getAttribute('timestamp');

                if (!messageListNodeTime) {
                    throw new Error(
                        `Child ${messageListNode.id} has no 'timestamp' attribute`
                    );
                }

                if (messageListNodeTime > timestamp) {
                    break;
                }

                messageListNode = messageListNode.nextSibling;
            }

            messageListElement.insertBefore(div, messageListNode);
        }
    }

    return div;
}

function createAndInsertMessage(id, timestamp) {
    const container = document.createElement('div');
    container.innerHTML = MESSAGE_TEMPLATE;
    const div = container.firstChild;
    div.setAttribute('id', id);

    // If timestamp is null, assume we've gotten a brand new message.
    // https://stackoverflow.com/a/47781432/4816918
    timestamp = timestamp ? timestamp : Date.now();
    div.setAttribute('timestamp', timestamp);

    // figure out where to insert new message
    var messageListElement = document.getElementById('messages');
    if (messageListElement) {
        const existingMessages = messageListElement.children;
        if (existingMessages.length === 0) {
            messageListElement.appendChild(div);
        } else {
            let messageListNode = existingMessages[0];

            while (messageListNode) {
                const messageListNodeTime = messageListNode.getAttribute('timestamp');

                if (!messageListNodeTime) {
                    throw new Error(
                        `Child ${messageListNode.id} has no 'timestamp' attribute`
                    );
                }

                if (messageListNodeTime > timestamp) {
                    break;
                }

                messageListNode = messageListNode.nextSibling;
            }

            messageListElement.insertBefore(div, messageListNode);
        }
    }

    return div;
}

// Saves a new message on the Cloud Firestore.
export function saveMessageToDatabase(messageText, RoomName) {
    // Add a new message entry to the Firebase database.
    document.getElementById('message-txt').value = '';
    firebaseApp.firestore().collection('Medex2022').doc('Chat').collection('Messages').add({
        name: localStorage.getItem('name'),
        message: messageText,
        timestamp: new Date().getTime(),
        roomName: RoomName,
        userId: localStorage.getItem('uid'),
        userRole: localStorage.getItem('userRole'),
        status: 'normal'
    }).then((doc) => {
        // console.warn('Sent new message', doc.id)
    }).catch(function (error) {
        console.error('Error writing new message to Firebase Database', error);
    });
}

// Saves a new message on the Cloud Firestore.
export function saveMessageToDatabaseforModerator(messageText, roomName) {
    // Add a new message entry to the Firebase database.
    if (document.getElementById('message-txt')) {
        document.getElementById('message-txt').value = '';
    }
    firebaseApp.firestore().collection('Medex2022').doc('Chat').collection('Messages').add({
        name: localStorage.getItem('name'),
        message: messageText,
        timestamp: new Date().getTime(),
        roomName: roomName,
        userId: localStorage.getItem('uid'),
        userRole: localStorage.getItem('userRole'),
        status: 'normal'
    }).then((doc) => {
        // console.warn('Sent new message', doc.id)
    }).catch(function (error) {
        console.error('Error writing new message to Firebase Database', error);
    });
}

function pinSelectedChat(chatItemId, userName, userMessage, myRoomName) {
    console.warn('Pin', chatItemId);
    let newRoomName = myRoomName.replace('webinar', 'moderatorRoom');
    let myPinPromise = new Promise((resolve, reject) => {
        firebaseApp.firestore().collection('Medex2022').doc('Chat').collection('Messages').doc(chatItemId).update({
            status: 'pinned'
        }).then((doc) => {
            // console.warn('Sent new message', doc.id)
            resolve('Done')
        }).catch(function (error) {
            console.error('Error writing new message to Firebase Database', error);
            reject(error);
        });
    });

    myPinPromise.then(result => {
        let finalMessage = userName + ': ' + userMessage;
        console.warn('Message', finalMessage);
        saveMessageToDatabaseforModerator(finalMessage, newRoomName);
    }).catch(error => {
        console.error('DB Message Pinned Error', error);
    })
}

function approveSelectedChat(chatItemId) {
    console.warn('Approve', chatItemId);
    let myPinPromise = new Promise((resolve, reject) => {
        firebaseApp.firestore().collection('Medex2022').doc('Chat').collection('Messages').doc(chatItemId).update({
            status: 'approved'
        }).then((doc) => {
            // console.warn('Sent new message', doc.id)
            resolve('Done')
        }).catch(function (error) {
            console.error('Error writing new message to Firebase Database', error);
            reject(error);
        });
    });

    myPinPromise.then(result => {
        console.warn('Attendee message approved', chatItemId);
        if (document.getElementById('approveIcon_' + chatItemId)) {
            document.getElementById('approveIcon_' + chatItemId).style.display = 'none';
        }
        if (document.getElementById('approveText_' + chatItemId)) {
            document.getElementById('approveText_' + chatItemId).style.display = 'block';
        }
    }).catch(error => {
        console.error('DB Message Pinned Error', error);
    })
}

export function generateNewUserId() {
    return randomString(16, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
}

function randomString(length, chars) {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
    return result;
}